import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useMakeFindOne, } from "../../../../../../Hooks/TT_ELD/Makes-Models";
import {makesList} from "../../../../../../API/TT_ELD/Users/make_moddel";
import {Button, Card, Form as FormAnt, Input, Spin, Tabs} from "antd";
import {Watermark} from 'antd';
import Notfound from "../../../../../../Utils/Notfound";


interface Params {
    id: string | undefined;
    [key: string]: string | undefined;
}

type Data = {
    data?: {
        data: Array<any>;
        count: number;
    };
    isLoading?: boolean,
    refetch?: any,
    isFetching?: any,
    status?: boolean | string

};
const MakeEdit = () => {

    const {id} = useParams<Params>();
    const {data, refetch, isFetching, isLoading, status}: Data = useMakeFindOne(id)
    const [loadings, setLoadings] = useState<boolean[]>([]);
    let navigate = useNavigate();

    const onSubmit = async (value: any) => {
        await makesList.makePatch(id, value)
        navigate(-1)
        refetch()
    };

    const enterLoading = (index: number) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });

        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 6000);
    };

    return (
        <div className='mainPage'>
            <Card>
                <Watermark style={{height: '100%'}}>
                    {status === 'loading' ? <Spin size="large" spinning={!data}/> : data ?
                        (<FormAnt
                            name="basic"
                            layout="vertical"
                            wrapperCol={{span: 16}}
                            initialValues={{...data}}
                            onFinish={onSubmit}
                            autoComplete="off"
                        >
                            <FormAnt.Item
                                wrapperCol={{span: 6}}
                                label="Name"
                                name="name"
                                style={{width:'30%'}}
                                rules={[{required: true, message: 'Please input your name!'}]}
                            >
                                <Input/>
                            </FormAnt.Item>

                            <FormAnt.Item>
                                <Button onClick={() => enterLoading(1)}
                                        loading={loadings[1]} type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </FormAnt.Item>
                        </FormAnt>) : <Notfound/>
                    }
                </Watermark>
            </Card>

        </div>

    );
};

export default MakeEdit;
