import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useMakeSelect, useModelFindOne} from "../../../../../../Hooks/TT_ELD/Makes-Models";
import {Button, Col, Input, Row, Select, Space, Spin, Tabs, Form as FormAnt, Watermark, Card} from "antd";
import {AppleOutlined} from "@ant-design/icons";
import {modelList} from "../../../../../../API/TT_ELD/Users/make_moddel";
import Notfound from "../../../../../../Utils/Notfound";

type MyObjectType = {
    [key: string | number]: any; // Индексная подпись с параметром типа 'string'
};
const TabPane = Tabs.TabPane;


type params = {
    readonly id: any;
};

type Data = {
    data?: {
        data: Array<any>;
        count: number;
    };
    isLoading?: boolean,
    refetch?: any,
    isFetching?: any

};

const ModelEdit = () => {
    const {id} = useParams<params>();

    const {data, refetch, status}: MyObjectType = useModelFindOne(id);
    const make: MyObjectType = useMakeSelect()

    let navigate = useNavigate();
    const onSubmit = async (values: any) => {
        const body: MyObjectType = {};
        let key: string | number;
        let key2: string | number;
        for (key in values) {
            if (
                typeof data[key] === "object" &&
                !Array.isArray(data[key]) &&
                data[key] != null
            ) {
                for (key2 in values[key]) {
                    if (
                        typeof values[key][key2] === "object" &&
                        !Array.isArray(values[key][key2]) &&
                        values[key][key2] != null
                    ) {
                        if (
                            JSON.stringify(values[key][key2]) !=
                            JSON.stringify(data[key][key2])
                        ) {
                            body[key] = values[key];
                            break;
                        }
                    } else if (data[key][key2] != values[key][key2]) {
                        body[key] = values[key];
                        break;
                    }
                }
            } else if (data[key] != values[key]) {
                body[key] = values[key];
            }
        }

        await modelList.modelPatch(id, body);
        navigate(-1);
        refetch()
    };

    return (
        <div className='mainPage'>
            <Card>
                <Watermark style={{height: '100%'}}>
                    {status === 'loading' ? <Spin size="large" spinning={!data}/> : data ? (
                        <Spin size="large" spinning={!data}>
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{display: "flex"}}
                            >
                                <Tabs defaultActiveKey="1">
                                    <TabPane
                                        tab={
                                            <span>
                          MAIN FIELDS
                        </span>
                                        }
                                        icon={<AppleOutlined rev={undefined}/>}
                                        key="1"
                                    >
                                        <Space
                                            direction="vertical"
                                            size="middle"
                                            style={{display: "flex"}}
                                        >
                                            <FormAnt
                                                name="basic"
                                                layout="vertical"
                                                wrapperCol={{span: 16}}
                                                initialValues={{...data}}
                                                onFinish={onSubmit}
                                                autoComplete="off"
                                            >

                                                <Row gutter={[16, 10]}>
                                                    <Col span={6}>
                                                        <FormAnt.Item
                                                            wrapperCol={{span: '100%'}}
                                                            label="Name"
                                                            name="name"
                                                            rules={[{
                                                                required: true,
                                                                message: 'Please input your name!'
                                                            }]}
                                                        >
                                                            <Input/>
                                                        </FormAnt.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <FormAnt.Item
                                                            wrapperCol={{span: '100%'}}
                                                            label="Port"
                                                            name="port"
                                                            rules={[{
                                                                required: true,
                                                                message: 'Please input your port!'
                                                            }]}
                                                        >
                                                            <Input/>
                                                        </FormAnt.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <FormAnt.Item
                                                            wrapperCol={{span: '100%'}}
                                                            label="Make"
                                                            name="makeId"
                                                            rules={[{
                                                                required: true,
                                                                message: 'Please input your make!'
                                                            }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                placeholder="Select a person"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option: any) =>
                                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                }
                                                                options={make?.data?.data?.map((option: any) => ({
                                                                    value: option.id,
                                                                    label: option.name,
                                                                }))}
                                                            />
                                                        </FormAnt.Item>
                                                    </Col>

                                                </Row>

                                                <FormAnt.Item>
                                                    <Button type="primary" htmlType="submit">
                                                        Submit
                                                    </Button>
                                                </FormAnt.Item>
                                            </FormAnt>
                                        </Space>
                                    </TabPane>
                                </Tabs>
                            </Space>
                        </Spin>

                    ) : <Notfound/>}


                </Watermark>
            </Card>
        </div>

    );
};

export default ModelEdit;
