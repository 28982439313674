import { Button } from 'antd';
import React from 'react';
import { useParams} from "react-router-dom";
import { companies } from '../../../../../../../API/TT_ELD/Users/company';
type params = {
    readonly id: any
}

const CheckStripe = () => {
    const { id } = useParams<params>();
    
    const toggle = () => {
        companies.companyPatchWhichservice(id)
    }
    return (
        <div className='mainPage'>
   <Button type="primary"  onClick={toggle}>Change Whichservice</Button>
        </div>
    );
};

export default CheckStripe;