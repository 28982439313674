import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form as FormAnt, Input, InputNumber, Row, Select, Space, Spin} from "antd";
import {Field} from "react-final-form";
import {useParams} from "react-router-dom";
import {useCompanyData, useGetPrice} from "../../../../../../../Hooks/TT_ELD/Companies";
import {sendEvent} from "../../../../../../../Utils/analytics";
import {companies} from "../../../../../../../API/TT_ELD/Users/company";
import {Form} from "react-final-form";

const {Option} = Select;
type params = {
    readonly id: any
}
type MyObjectType = {
    [key: string | number]: any; // Индексная подпись с параметром типа 'string'
};
const stripeType = [
    {label: "stripe1", value: "stripe1"},
    {label: "stripe2", value: "stripe2"},
    {label: "stripe_evo", value: "stripe_evo"},
    {label: "stripe_zippy", value: "stripe_zippy"},
];
const Stripe = () => {

    const {id} = useParams<params>();
    const {data, refetch, isLoading, isFetching}: MyObjectType = useCompanyData(id);
    const getPrices = useGetPrice(id)

    const onSubmit = async (values: any) => {
        values = {
            ...values,
            stripe: {
                ...values?.stripe,
                paymentMethodId: values.paymentMethodId,
                price: values.price,
                subscription: {
                    ...values?.stripe?.subscription,
                    id: values?.subscriptionId,
                    quantity: values.quantity
                }
            }
        }
        delete values.paymentMethodId;
        delete values.price;
        delete values.subscriptionId;
        delete values.quantity;
        const body: MyObjectType = {}
        let key: string | number
        let key2: string | number
        for (key in values) {
            if (typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] != null) {
                for (key2 in values[key]) {
                    if (typeof values[key][key2] === 'object' && !Array.isArray(values[key][key2]) && values[key][key2] != null) {
                        if (JSON.stringify(values[key][key2]) != JSON.stringify(data[key][key2])) {
                            body[key] = values[key]
                            break
                        }
                    } else if (data[key][key2] != values[key][key2]) {
                        body[key] = values[key]
                        break
                    }
                }
            } else if (data[key] != values[key]) {
                body[key] = values[key]
            }
        }
        sendEvent(`Company page`, `Company  Changed `, `Company  page Submit`, 1);
        companies.companyPatchData(body, id);
        refetch()
    };
    const required = (value: any) => (value ? undefined : "Required");
    return (
        <Card>
            <Spin size="large" spinning={!data}>
                {/*{data && <Form*/}
                {/*    onSubmit={onSubmit}*/}
                {/*    initialValues={{*/}
                {/*        ...companyData,*/}
                {/*        paymentMethodId: companyData?.stripe.paymentMethodId,*/}
                {/*        price: companyData?.stripe.price,*/}
                {/*        subscriptionId: companyData?.stripe.subscription?.id,*/}
                {/*        quantity: companyData?.stripe.subscription?.quantity*/}
                {/*    }}*/}
                {/*    render={({handleSubmit, values}) => (*/}
                {/*        <form onSubmit={handleSubmit}>*/}
                {/*            <Space*/}
                {/*                direction="vertical"*/}
                {/*                size="middle"*/}
                {/*                style={{display: "flex", width: "50%"}}*/}
                {/*            >*/}
                {/*                <div>*/}
                {/*                    <label>stripeCustomerId</label>*/}
                {/*                    <Field*/}
                {/*                        name="stripeCustomerId"*/}
                {/*                        render={({input}: { input: any }) => (*/}
                {/*                            <Input placeholder="stripeCustomerId" type="text" {...input} />*/}
                {/*                        )}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div>*/}
                {/*                    <label>paymentMethodId</label>*/}
                {/*                    <Field*/}
                {/*                        name="paymentMethodId"*/}
                {/*                        render={({input}: { input: any }) => (*/}
                {/*                            <Input placeholder="paymentMethodId" type="text" {...input} />*/}
                {/*                        )}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                /!*<div>*!/*/}
                {/*                /!*  <label>price</label>*!/*/}
                {/*                /!*  <Field*!/*/}
                {/*                /!*    name="price"*!/*/}
                {/*                /!*    render={({ input }: { input: any }) => (*!/*/}
                {/*                /!*      <Input placeholder="price" type="text" {...input} />*!/*/}
                {/*                /!*    )}*!/*/}
                {/*                /!*  />*!/*/}
                {/*                /!*</div>*!/*/}

                {/*                <div>*/}
                {/*                    <label>price</label>*/}
                {/*                    <Field*/}
                {/*                        name="price"*/}
                {/*                        initialValue={data?.stripe.price}*/}
                {/*                        render={({input}: { input: any }) => (*/}
                {/*                            <Select*/}
                {/*                                defaultValue={input.value}*/}
                {/*                                placeholder='price'*/}
                {/*                                onChange={(value, option) => {*/}
                {/*                                    input.onChange(value);*/}
                {/*                                }}*/}
                {/*                                style={{width: "100%"}}*/}
                {/*                            >*/}
                {/*                                {getPrices?.data?.prices.map((data: any) => (*/}
                {/*                                    <Option key={data.id}>{data.unit_amount}</Option>*/}
                {/*                                ))}*/}
                {/*                            </Select>*/}
                {/*                        )}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div>*/}
                {/*                    <label>subscriptionId</label>*/}
                {/*                    <Field*/}
                {/*                        name="subscriptionId"*/}
                {/*                        render={({input}: { input: any }) => (*/}
                {/*                            <Input placeholder="subscriptionId" type="text" {...input} />*/}
                {/*                        )}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div>*/}
                {/*                    <label>quantity</label>*/}
                {/*                    <Field*/}
                {/*                        name="quantity"*/}
                {/*                        render={({input, meta}: { input: any; meta: any }) => (*/}
                {/*                            <div>*/}
                {/*                                <Input disabled={true} placeholder="quantity"*/}
                {/*                                       type="number" {...input} />*/}
                {/*                                /!*{meta.touched && meta.error && (*!/*/}
                {/*                                /!*  <div style={{ color: "red" }}>{meta.error}</div>*!/*/}
                {/*                                /!*)}*!/*/}
                {/*                            </div>*/}
                {/*                        )}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div>*/}
                {/*                    <label>stripeType</label>*/}
                {/*                    <Field*/}
                {/*                        name="stripeType"*/}
                {/*                        initialValue={data?.stripeType}*/}
                {/*                        render={({input}: { input: any }) => (*/}
                {/*                            <Select*/}
                {/*                                defaultValue={input.value}*/}
                {/*                                onChange={(value, option) => {*/}
                {/*                                    input.onChange(value);*/}
                {/*                                }}*/}
                {/*                                style={{width: "100%"}}*/}
                {/*                            >*/}
                {/*                                {stripeType.map((data, index) => (*/}
                {/*                                    <Option key={data.value}>{data.label}</Option>*/}
                {/*                                ))}*/}
                {/*                            </Select>*/}
                {/*                        )}*/}
                {/*                    />*/}
                {/*                </div>*/}

                {/*                <div className="buttons">*/}
                {/*                    <Button type="primary" htmlType="submit">*/}
                {/*                        Submit*/}
                {/*                    </Button>*/}
                {/*                </div>*/}
                {/*            </Space>*/}
                {/*        </form>*/}
                {/*    )}/>}*/}

                {data && <FormAnt
                    name="basic"
                    layout="vertical"
                    initialValues={{
                        ...data,
                        paymentMethodId: data?.stripe.paymentMethodId,
                        price: data?.stripe.price,
                        subscriptionId: data?.stripe.subscription?.id,
                        quantity: data?.stripe.subscription?.quantity
                    }}
                    onFinish={onSubmit}
                    autoComplete="off"
                >
                    <Space
                        direction="vertical"
                        style={{ width: "50%"}}
                    >
                        <FormAnt.Item
                            wrapperCol={{span: '100%'}}
                            label="Stripe Customer ID"
                            name="stripeCustomerId"
                        >
                            <Input/>
                        </FormAnt.Item>
                        <FormAnt.Item
                            wrapperCol={{span: '100%'}}
                            label="Payment Method ID"
                            name="paymentMethodId"
                        >
                            <Input placeholder='payment method'/>
                        </FormAnt.Item>
                        <FormAnt.Item
                            wrapperCol={{span: '100%'}}
                            label="Price"
                            name="price"
                        >
                            <Select
                                placeholder='price'
                                style={{width: "100%"}}
                            >
                                {getPrices?.data?.prices.map((data: any) => (
                                    <Option key={data.id}>{data.unit_amount}</Option>
                                ))}
                            </Select>
                        </FormAnt.Item>
                        <FormAnt.Item
                            wrapperCol={{span: '100%'}}
                            label="Subscription ID"
                            name="subscriptionId"
                        >
                            <Input placeholder='subscription id'/>
                        </FormAnt.Item>
                        <FormAnt.Item
                            wrapperCol={{span: '100%'}}
                            label="Quantity"
                            name="quantity"
                            rules={[ { type: 'number', warningOnly: true }, { type: 'number', min: 0 ,max:0, message:'Please enter a number 0' }]}
                        >
                            <InputNumber placeholder="quantity" style={{ width: '100%' }}  />
                        </FormAnt.Item>
                        <FormAnt.Item
                            wrapperCol={{span: '100%'}}
                            label="Stripe Type"
                            name="stripeType"
                        >
                            <Select
                                style={{width: "100%"}}
                            >
                                {stripeType.map((data, index) => (
                                    <Option key={data.value}>{data.label}</Option>
                                ))}
                            </Select>
                        </FormAnt.Item>

                    <FormAnt.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </FormAnt.Item>
                </Space>
                </FormAnt>
                }
            </Spin>
        </Card>
    );
};

export default Stripe;
