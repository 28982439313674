import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {Button, Card, Col, Input, Row, Space, Spin, Switch, Tabs} from "antd";
import {Field, Form} from "react-final-form";
import {AppleOutlined} from "@ant-design/icons";
import {useDeviceOneData} from "../../../../../Hooks/cleverTms/Device";
import {deviceController} from "../../../../../API/Tms/device";

const TabPane = Tabs.TabPane;

type Data = {
    data?: {
        data: Array<any>,
        count: number
    }
}
type params = {
    readonly id: any
}
type numStr = number | string;

interface Fields {
    updatedAt: numStr,
    createdAt: numStr,
    isActive: boolean,
    model: numStr,
    androidVersion: numStr,
    ios: numStr,
    deviceToken: numStr,
    fcmToken: numStr,
    oneSignalUserId: numStr,
    appVersion: numStr,
    versionCode: numStr,
    deviceInfo: numStr,
    driverId: numStr,
    companyId: numStr,
    config: numStr,
    mediaDeviceId: numStr,
    dispatcherId: numStr

}

const DeviseEdit = () => {

    const [companyData, setCompanyData] = useState<{ data: Array<Fields>, count: number }>();
    const {id} = useParams<params>();
    const {data}: Data = useDeviceOneData(id)
    let navigate = useNavigate();


    const deviceInputs = [
        {label: "App version", name:"appVersion"},
        {label: "Device token", name:"deviceToken"},
        {label: "Model", name:"model"},
        {label: "Android version", name:"androidVersion"},
        {label: "IOS", name:"ios"},
        {label: "FCM token", name:"fcmToken"},
        {label: "One signal user ID", name:"oneSignalUserId"},
        {label: "Media device ID", name:"mediaDeviceId"},
    ]
    const deviceNumber = [
        {label: "Version code", name:"versionCode"},
        {label: "Driver ID", name:"driverId"},
        {label: "Company ID", name:"companyId"},
        {label: "Dispatcher ID", name:"dispatcherId"},
    ]
    useEffect(() => {
        if (data) {
            setCompanyData(data)
        }
    }, [data])

    const onSubmit = async (value: any) => {
        // const valueInArray = Object.keys(value).map((key) => [key, value[key]]).filter(el => el[1])
        // const obj = Object.fromEntries(new Map(valueInArray))

        await deviceController.devicePatch(value)
        navigate(-1)
    };
    return (
        <div className='mainPage'>
            <Card>
                <Spin size="large" spinning={!data}>
                    {companyData && <Form onSubmit={onSubmit}
                                          initialValues={{...companyData,}}
                                          render={({handleSubmit, values}) => (
                                              <form onSubmit={handleSubmit}>
                                                  <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                                      <Tabs defaultActiveKey="1">
                                                          <TabPane
                                                              tab={<span>MAIN FIELDS</span>}
                                                              key="1"
                                                              icon={<AppleOutlined rev={undefined}/>}
                                                          >
                                                              <Space direction="vertical" size="middle"
                                                                     style={{display: 'flex',}}>
                                                                  <Row gutter={[16, 20]}>

                                                                      {deviceInputs.map(u => {
                                                                          return <Col className="gutter-row" span={6}>
                                                                              <label>{u.label}: </label>
                                                                              <Field
                                                                                  name={u.name}
                                                                                  render={({input}: { input: any }) => (
                                                                                      <Input placeholder={u.label}
                                                                                             type="text" {...input} />
                                                                                  )}
                                                                              />
                                                                          </Col>
                                                                      })
                                                                      }
                                                                      {deviceNumber.map(u => {
                                                                          return <Col className="gutter-row" span={6}>
                                                                              <label>{u.label}: </label>
                                                                              <Field
                                                                                  name={u.name}
                                                                                  render={({input}: { input: any }) => (
                                                                                      <Input placeholder={u.label}
                                                                                             type="number" {...input} />
                                                                                  )}
                                                                              />
                                                                          </Col>
                                                                      })
                                                                      }

                                                                  </Row>
                                                                  <div>
                                                                      <label>Is active: </label>
                                                                      <Field
                                                                          name="isActive"
                                                                          render={({input}: { input: any }) => (
                                                                              <Switch defaultChecked={input.value}
                                                                                      onChange={input.onChange}/>
                                                                          )}
                                                                      />
                                                                  </div>
                                                                  <div className="buttons">
                                                                      <Button type="primary" htmlType="submit">
                                                                          Submit
                                                                      </Button>
                                                                  </div>
                                                              </Space>
                                                          </TabPane>
                                                      </Tabs>
                                                  </Space>
                                              </form>
                                          )
                                          }
                    />
                    }
                </Spin>
            </Card>
        </div>
    )
}

export default DeviseEdit
