import React, {useState} from 'react';
import {Button, DatePicker, Space, Spin, Table, Tag} from "antd";
import type {DatePickerProps, GetProps} from 'antd';
import {useTrackingRang} from "../../../../../../Hooks/TT_ELD/Users";
import {useParams} from "react-router-dom";
import {calcOrder} from "../../../../../components/core/calcOrder";
import TrackingDebugInfo from "./TrackingDebugInfo";

const {RangePicker} = DatePicker;

type Data = {
    data?: {
        data: Array<any>,
        count: number
    },
    isLoading?: boolean,

}
type numStr = string | number

interface reportSource {
    no: numStr
    address: string,
    date: string
    eld_connection: numStr
    is_live: string
    source: string
    speed: string
    debug_info: string
    action: { id: numStr };
    key: React.Key;
}

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;


interface Params {
    id: string | undefined;
    [key: string]: string | undefined;
}



const TrackingByRang = () => {

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [getOneData, setGetOneData] = useState<string>();
    const handleCancel = (record: any) => {
        setGetOneData(record);
    };

    const columns: object[] = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            sorter: {
                compare: (a: any, b: any) => a.no - b.no,
                multiple: 1,
            },
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },

        {
            title: 'Eld connection',
            dataIndex: 'eld_connection',
            key: 'eld_connection',
            render: (tag: boolean) => (
                <Tag color={tag ? 'geekblue' : 'red'}>
                    {tag ? 'True' : 'False'}
                </Tag>
            ),
        },
        {
            title: 'Is live',
            dataIndex: 'is_live',
            key: 'is_live',
            render: (tag: boolean) => (
                <Tag color={tag ? 'geekblue' : 'red'}>
                    {tag ? 'True' : 'False'}
                </Tag>
            ),
        },
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
        },
        {
            title: 'Speed',
            dataIndex: 'speed',
            key: 'speed',
        },

        {
            title: "Actions",
            dataIndex: "action",
            key: "action",
            render: ({ id }: { id: string | number }) => {
                return (
                    <Space>
                            <Button onClick={() => setIsModalVisible(true)}>View</Button>
                    </Space>
                );
            },
        },

    ]

    const {id} = useParams<Params>();
    const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
    };
    const [state, setState] = useState({
        startDate: '',
        endDate: '',
    });
    const [skip, setSkip] = useState(1);

    const {data, isLoading}: Data = useTrackingRang(skip, state, id);

    const onChange = (query: any) => {
        setSkip(1 * (parseInt(query.current)));
    }

    const handleDateChange = (value: any, dateString: [string, string]) => {
        if (value) {
            const startDate = value[0].toISOString();
            const endDate = value[1].toISOString();
            setState({
                startDate,
                endDate,
            });
        }
    };
    return (
        <div>
            <RangePicker
                showTime={{format: 'HH:mm'}}
                format="YYYY-MM-DD HH:mm"
                onChange={handleDateChange}
                onOk={onOk}
            />
            <Spin size='large' spinning={isLoading}>
                <TrackingDebugInfo isModalVisible={isModalVisible}
                               setIsModalVisible={setIsModalVisible}
                               data={getOneData}/>
                <Table onRow={(record, id) => {
                    return {
                        onClick: () => {
                            handleCancel(record);
                        },
                    };
                }} style={{margin: '15px 0 0 0'}} onChange={onChange}
                       dataSource={data?.data?.map((u: any, i: number): reportSource => {
                           const obj: reportSource = {
                               no: calcOrder(skip, 10 , i),
                               address: u?.address,
                               date: u?.date,
                               eld_connection: u?.eld_connection,
                               is_live: u?.is_live,
                               source: u?.source,
                               speed: u?.speed,
                               debug_info: u?.debug_info,
                               action: { id: u.id },
                               key: i
                           }
                           return obj
                       })}
                       columns={columns}  pagination={false}
                />
            </Spin>
        </div>
    );
};

export default TrackingByRang;