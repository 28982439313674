import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {
    useCompanyHistory,
    useFindDriver,
    useGetAllLogs,
    useGetLogsById,
    useGetMalfunctions
} from "../../../../../../Hooks/TT_ELD/Supports";
import {Button, DatePicker, Spin, Table, Tag} from "antd";
import {
    compareDriverWithCoDriver,
    filterOffLogsForErrors,
    getErrors,
    getStatus
} from "./SupportErrors/SupportErrors";
import moment from "moment/moment";
import times from 'moment-timezone';
import dayjs from "dayjs";

type params = {
    readonly id: any;
};
const {RangePicker} = DatePicker;

interface supportSource {
    no: number
    first_name: string
    status: any,
    errors: { text: any, level: any, time: any }
    start_date: any
    key: React.Key;

}

const columns: object[] = [
    {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
    },
    {
        title: 'Username',
        dataIndex: 'first_name',
        key: 'first_name',
    },
    {
        title: 'Errors',
        dataIndex: 'errors',
        key: 'errors',
        render: (val: { text: any, level: any, time: any }) => {
            return (
                <Tag color={val.level === 'danger' ? 'red' : 'geekblue'}>
                    {val.text} {val.time}
                </Tag>
            )
        }
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Time',
        dataIndex: 'start_date',
        key: 'start_date',
    },
]

const SupportError = () => {
    const {id} = useParams<params>();
    const [getTz, setGetTz] = useState<any>([]);
    const [logs, setLogs] = useState<any | undefined>([]);
    const [todayDate, setTodayDate] = useState<string>(moment.utc(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').add(6, 'hour').toISOString());
    const [tomorrowDate, setTomorrowDate] = useState<string | undefined>(moment.utc(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').add(29.9999, 'hour').toISOString());
    const [skip, setSkip] = useState<string | number>(1);
    const [cursor, setCursor] = useState<number | string | any>(1);
    const tz: string = getTz?.data ? getTz?.data[0]?.company?.tz?.value : ''
    const [startDate, setStartDate] = useState(moment.tz(tz));
    const [rangeDate, setRangeDate] = useState<any>([
        {
            startDate: dayjs().startOf('day').format('YYYY-MM-DD[T]06:00:00.000Z'),
            endDate: dayjs().endOf('day').format('YYYY-MM-DD[T]05:59:59.640Z'),
            key: "selection"
        }
    ]);

    const changeDate = useCallback(
        (date: string) => {
            setTodayDate(date)
        },
        [],
    );
    const changeTomorDate = useCallback(
        (date: string) => {
            setTomorrowDate(date)
        },
        [],
    );

    const getTzs: any = useCompanyHistory(id)
    const actDr2: any = useFindDriver(id, skip)
    const dateObject: Date = new Date(todayDate);

    const day: number = (dateObject as any).getUTCDate();
    const month: number = (dateObject as any).getUTCMonth() + 1;
    const year: number = (dateObject as any).getUTCFullYear();


    const formattedDate: string = `${day}-${month < 10 ? '0' : ''}${month}-${year}`;


    const getLogs: any = useGetAllLogs(id, formattedDate, cursor)

    // if (getLogs.isSuccess) {
    //     const totalLogs = getLogs.data.total;
    //     const totalPages = Math.ceil(totalLogs / 50);
    //     let dataObj: Array<any> = [];
    //     getLogs?.data && getLogs?.data?.data?.map((data :any)=> dataObj.push(data));
    //     if (cursor + 1 <= totalPages) {
    //         setCursor(cursor + 1);
    //         setLogsData(dataObj)
    //
    //     } else {
    //         // console.log("No more pages");
    //     }
    //
    // } else if (getLogs.isError) {
    //     console.error("Error fetching logs", getLogs.error);
    // }
    // const getAllLogs: any = useGetAllLogs()

    const {data, refetch, isLoading, isFetching}: any = useGetLogsById(id, todayDate, tomorrowDate)
    const getMulfunctions: any = useGetMalfunctions(todayDate, id, tz)
    const malfunctions = getMulfunctions.data
    const datePicker = (date: any, dateString: any) => {
        console.log(dateString,'dateString')
        setRangeDate([])
        const dateObject = new Date(dateString);
        let formattedDate = moment(dateObject)
        setStartDate(formattedDate)
        changeDate(dateString ? moment.utc(dateString, 'YYYY-MM-DD').add(6, 'hour',).toISOString() : moment.utc(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').add(6, 'hour').toISOString())
        changeTomorDate(dateString ? moment.utc(dateString, 'YYYY-MM-DD').add(29.9999, 'hour').toISOString() : moment.utc(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').add(29.9999, 'hour').toISOString())

    };
    useEffect(() => {
        setLogs(data)
        setGetTz(getTzs);
    }, [data]);

    const onChange = (query: any) => {
        setSkip(1 * (parseInt(query.current)));
    }

    const LogsItems: React.FC = () => {
        if (data?.logs && actDr2?.data?.data) {
            let sortErrsByLevel = (errs: any) => {
                let newErrs = [...errs];
                let order = ['danger', 'warning'];
                newErrs.sort((a, b) => (
                    order.indexOf(a.level) - order.indexOf(b.level)
                ));
                return newErrs;
            }
            let log: any = data?.logs
            let sortByDriver: any = log?.sort((a: any, b: any) => a?.driverId - b?.driverId);
            let driversLogs = [];
            let DRS_LOGS_OBJ: any = {};
            let DRS_LOGS: any = [];
            let filt_errs: any = [];
            let filt_drs: any = [];
            let lastDrId: any = sortByDriver ? sortByDriver[0]?.driverId : undefined
            let startI = 0;

            sortByDriver?.forEach((log: any, i: any) => {
                if (log.driverId !== lastDrId) {
                    driversLogs.push(sortByDriver.slice(startI, i));
                    lastDrId = log.driverId;
                    startI = i;
                }
            });

            driversLogs?.push(sortByDriver?.slice(startI, sortByDriver?.length - 1));
            driversLogs?.forEach((logs: any) => {
                if (logs[0] && logs[0]?.driverId) {
                    let drId = logs[0]?.driverId;
                    let actDr: any = actDr2?.data?.data.find((dr: any) => dr.id === drId)
                    if (actDr) {
                        const drLog = {
                            driver: {
                                ...actDr,
                                name: `${actDr.first_name} ${actDr.second_name}`,
                            },
                            logs: logs
                                .sort(
                                    ({a, b}: { a: any, b: any }) => (
                                        new Date(a?.start_date)?.getTime() -
                                        new Date(b?.start_date)?.getTime()
                                    )
                                )
                                .map((l: any) => ({
                                    id: l.id,
                                    vehicleId: l.vehicleId,
                                    start_date: l?.start_date, tz,
                                    end_date: l.end_date,
                                    status: getStatus(l),
                                    odometr: l.odometr,
                                    engine_hours: l.engine_hours,
                                    document: l.document,
                                    trailer: l.trailer,
                                    note: l.note,
                                    address: l.address,
                                    coordinates: l.coordinates,
                                    record_status: l.record_status,
                                    certify_date: l.certify_date,
                                    event_code: l.event_code,
                                    event_type: l.event_type,
                                    codriverId: l.codriverId,
                                    driverId: l.driverId,
                                    vin_number: l.vin_number,

                                }))
                        }
                        DRS_LOGS.push(drLog);
                        DRS_LOGS_OBJ[drLog.driver.id] = drLog;
                    }
                }
            });

            let ERRORS: any = [];
            let conflictWithCodriver: any = [];
            DRS_LOGS.forEach((dr_logs: any, i: string) => {
                let errs = getErrors(dr_logs?.logs, DRS_LOGS_OBJ[dr_logs?.driver?.codriverId]?.logs, malfunctions, dr_logs?.driver, startDate, tz);
                if (errs && errs.length > 0) {
                    ERRORS = [...ERRORS, ...errs];
                }
                if (dr_logs?.driver?.codriverId) {
                    let coDriver = DRS_LOGS.find((u: any) => u.driver.id === dr_logs?.driver?.codriverId);
                    let date = startDate.format('MM-DD-YYYY');
                    if (coDriver && coDriver.logs) {

                        dr_logs.logs = filterOffLogsForErrors(dr_logs.logs, date, tz);
                        coDriver.logs = filterOffLogsForErrors(coDriver.logs, date, tz);

                        const checker = compareDriverWithCoDriver(dr_logs.logs, coDriver.logs)
                        let check = checker.find((u: any) => u === true);
                        if (check) {
                            dr_logs.date = date;
                            conflictWithCodriver.push(dr_logs);
                        }
                    }
                }
                let drIndex = filt_drs.findIndex((name: any) => name === dr_logs.driver.name);
                if (drIndex === -1) {
                    filt_drs.push(dr_logs.driver.name)
                }

                ERRORS.forEach((ERR: any) => {
                    let errIndex = filt_errs.findIndex((err: any) => (ERR.log.status === 'malfunction') ? err === ERR.log.status : err === ERR.text);
                    if (errIndex === -1) {
                        filt_errs.push((ERR.log.status === 'malfunction') ? 'malfunction' : ERR.text)
                    }
                })
                // setConflicts(conflictWithCodriver)
            });
            let sortedERRS = sortErrsByLevel(ERRORS)
            let errs: any = sortedERRS;
            if (filt_errs) {
                errs = errs?.filter((er: any) => filt_errs.includes(er.text))
            }
            if (filt_drs) {
                errs = errs.filter((er: any) => filt_drs.includes(er.driver.name));
            }


            const Logs: Array<any> = [];
            getLogs?.data?.data?.forEach((log: any) => {
                if (log.violations[0]) {
                    Logs.push(log)
                }
            })
            return (
                <Spin size="large" spinning={actDr2?.isLoading || actDr2?.isFetching}>
                    <Table
                        style={{margin:'15px 0 0 0'}}
                        onChange={onChange}
                        dataSource={[
                            ...errs?.map((u: any, i: number): supportSource => ({
                                no: i + 1,
                                first_name: `${u?.driver?.first_name} ${u?.driver?.second_name}`,
                                status: u?.log?.status,
                                errors: {text: u?.text, level: u?.level, time: u?.time},
                                start_date: u?.log && times.tz(u?.log?.start_date, tz).format("YYYY-MM-DD / hh:mm:ss A"),
                                key: u?.log.id + i
                            })) || [],
                            ...Logs?.map((u: any, i: number): supportSource => ({
                                no: i + errs?.length + 1,
                                first_name: `${u.driver} `,
                                status: u?.status,
                                errors: {text: u.violations[0], level: 'danger', time: ''},
                                start_date: u?.log && times.tz(u?.log?.start_date, tz).format("YYYY-MM-DD / hh:mm:ss A"),
                                key: u.id + i
                            })) || [],
                            ...conflictWithCodriver?.map((u: any, i: number): supportSource => ({
                                no: i + errs?.length + 1 + Logs.length,
                                first_name: `${u.driver?.name} `,
                                status: '',
                                errors: {text: 'Conflict with Codriver', level: 'danger', time: ''},
                                start_date: u?.data && times.tz(u?.date, tz).format("YYYY-MM-DD / hh:mm:ss A"),
                                key: i + errs?.length + 1 + Logs?.length
                            })) || [],

                        ]}
                        columns={columns}
                        pagination={false}
                    />
                </Spin>
            )

        } else {
            return (
                <Table
                    style={{margin:'15px 0 0 0'}}
                    onChange={onChange} dataSource={logs?.logs?.map((u: any, i: number): supportSource => {
                    const obj: supportSource = {
                        no: i + 1,
                        first_name: `${u.driver?.first_name} ${u.driver?.second_name}`,
                        status: u.log?.status,
                        errors: {text: u.text, level: u.level, time: u.time},
                        start_date: u?.log && times.tz(u?.log?.start_date, tz).format("hh:mm:ss A"),
                        key: i + 1
                    }
                    return obj
                })} columns={columns} pagination={{showSizeChanger: false,}}/>
            )
        }
    }


    // useEffect(() => {
    //     let errs:any = originalErrs || [];
    //     let logsD = logsData?.length ? [...logsData] : [];
    //     if(filtDriver) {
    //         errs = errs.filter((er:any )=> er.driver.name === filtDriver);
    //         setLogsData(logsD.filter((er :any)=> er.driver.name === filtDriver))
    //     } else {
    //         setLogsData(logsData || [])
    //     }
    //     if(filtErr) {
    //         errs = filtErr !== 'malfunction' ? errs.filter((er :any)=> er.text === filtErr) : errs.filter((er:any) => er.log.status === filtErr);
    //     }
    //     setErrors(errs);
    //
    //
    //     let dataObj:Array<any> = [];
    //     getAllLogs && getAllLogs?.data?.data?.map((data:any) => dataObj.push(...data.data));
    //     // setLogsData(dataObj);
    // }, [filtDriver, filtErr, originalErrs, logsData])

    useEffect(() => {
        if (rangeDate?.length >= 2) {
            setTodayDate(moment.utc(rangeDate[0], 'YYYY-MM-DD').add(6, 'hour',).toISOString())
            setTomorrowDate(moment.utc(rangeDate[1], 'YYYY-MM-DD').add(29.9999, 'hour').toISOString())
        }

    }, [rangeDate?.length >= 2])

    const defaultValue: any = dayjs();
    return (
        <div>

            <DatePicker  style={{margin:'0px 10px 0 0'}} defaultValue={defaultValue} format={'YYYY-MM-DD'} disabledDate={(current) => {
                let customDate = moment().add(1, 'd').format("YYYY-MM-DD");
                return current && current > moment(customDate, "YYYY-MM-DD");
            }} onChange={datePicker} />
            <RangePicker
                onChange={(value: any) => {
                    if (value) {
                        setRangeDate(value?.map((item: any) => {
                            const formattedDate = dayjs(item).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
                            return formattedDate;
                        }))
                    } else {
                        setRangeDate([])
                        setTomorrowDate(tomorrowDate)
                        setTodayDate(todayDate)
                    }

                }}
                disabledDate={(current) => {
                    return current && current > moment(moment.utc(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').add(29.9999, 'hour').toISOString(), 'YYYY-MM-DD');
                }}
            />
            <Spin size="large" spinning={!data}>
                <div><LogsItems/></div>
            </Spin>
        </div>
    );
};

export default SupportError;