import {useQuery} from "react-query";
import {appDrivers} from "../../../API/Tms/Driver/activeLoads";
import {dailies} from "../../../API/TT_ELD/daily";


type Data = {
    data?: {
        data: Array<any>,
        count: number
    }
    refetch(): void
}
export const useActiveLoads = (driverId: string | number, companyId:string | number, skip:string | number): object => {
    return useQuery([
            `active-loads /${ driverId || 'all'}`,driverId,  companyId ,skip],
        () => appDrivers.read(driverId,  companyId, skip),
        { refetchOnWindowFocus: false, refetchInterval: 5 * 60 * 1000 })
}

export const useLoadHistories = (driverId: string | number, companyId:string | number,date:{startDate: Date, endDate: number, key: string}[],sort:string,status:string ,skip:string | number): object => {
    return useQuery([
            `load-histories/${ driverId || 'all'}`,driverId,  companyId ,date,sort,status,skip],
        () => appDrivers.loadHistories(driverId,  companyId,date,sort,status, skip),
        { refetchOnWindowFocus: false, refetchInterval: 5 * 60 * 1000 })

}
export const useLoadFindOne = (id: number | string | undefined): Data => {
    return useQuery(
        [`loadFindOne/${id || "all"}`, id],
        () => appDrivers.loadFindOne(id),
        {refetchOnWindowFocus: false}
    );
};

export const useLoadFiles = (driverId: string | number, companyId:string | number, skip:string | number): object => {
    return useQuery([
            `load-files/${ driverId || 'all'}`,driverId,  companyId ,skip],
        () => appDrivers.loadFiles(driverId,  companyId, skip),
        { refetchOnWindowFocus: false, refetchInterval: 5 * 60 * 1000 })
}

export const useNote = (driverId: string | number, companyId:string | number, skip:string | number): object => {
    return useQuery([
            `load-mote/${ driverId || 'all'}`,driverId,  companyId ,skip],
        () => appDrivers.driverNote(driverId,  companyId, skip),
        { refetchOnWindowFocus: false, refetchInterval: 5 * 60 * 1000 })
}

export const useSalaryList = (driverId: string | number, companyId:string | number, skip:string | number): object => {
    return useQuery([
            `salary-list /${ driverId || 'all'}`,driverId,  companyId ,skip],
        () => appDrivers.salaryList(driverId,  companyId, skip),
        { refetchOnWindowFocus: false, refetchInterval: 5 * 60 * 1000 })
}

export const useFindDriverForTMS =  (companyId: string | number ,driverId:string | number) => {
    return useQuery( [
        `actice-loads`,companyId, driverId],
        () => dailies.driverFinderForTMS(companyId, driverId),
    { refetchOnWindowFocus: false, refetchInterval: 5 * 60 * 1000 })

}