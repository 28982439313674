import React, {useState} from 'react';
import {Button, Card, Col, Input, Row, Select, Space, Switch, Tabs} from "antd";
import {
    billingStatus,
    confirmedByDriver, LoadControllerInputs, LoadControllerNumber, LoadControllerSwitch, status,
} from "./LoadsAllInfo";
import {Field, Form} from "react-final-form";
import {loadController} from "../../../../../API/Tms/loadController";
import {useNavigate} from "react-router-dom";
const {Option} = Select;
type Field ={
    "status": string | null,
    "applied": string | null,
    "confirmedByDriver": string | null,
    "billingStatus": string | null,
    "orderNumber": string | null,
    "brokerEmails": Array<string> | null,
    "notes": string |null,
    "brokerCompanyId": string | null,
    "brokerEmail": string | null,
    "reason": string | null,
    "deliveryAddress": string | null,
    "stopStatus": string | null,
    "origin": string | null,
    "completed": string | null,
    "puDateTo": string | null,
    "puDateFrom": string | null,
    "delDateFrom": string | null,
    "delDateTo": string | null,
    "currentStop": number | null,
    "unitId": number | null,
    "statusCode": number | null,
    "dispatcherId": number | null,
    "driverId": number | null,
    "coDriverId": number | null,
    "rate": number | null,
    "mile": number | null,
    "cancelPrice": number | null,
    "price": number | null,
    "emptyMile": number | null,
    "calculatedPrice": number | null,
    "driverLoadCompleted": boolean | null,
    "paid": boolean | null,
    "roundTrip": boolean | null,
    "isPaidSalary": boolean | null,
    "isDispatched": boolean | null,
    "isActive": boolean | null,
    "calculatedData": object | null,
    "id": string | null
}

const LoadControllerAdd = () => {
    const [companyId, setCompanyId] = useState<any>();
    let navigate = useNavigate();

    const onSubmit = async (value: Field) => {
        setCompanyId(value)
       await loadController.addLoadController(value)
        navigate(-1)
    };

    const required = (value:any) => (value ? undefined : 'Required')

    return (
        <div className='mainPage'>
            <Card>
                <Form onSubmit={onSubmit}
                      render={({handleSubmit, submitting}) => (
                          <form onSubmit={handleSubmit}>
                              <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                  <h1>Add Load</h1>
                                  <Space direction="vertical" size="middle"
                                         style={{display: 'flex',}}>
                                      <Row gutter={[16, 20]}>
                                          {LoadControllerInputs.map(u => {
                                              return <Col className="gutter-row" span={6}>
                                                  <label>{u.label}: </label>
                                                  <Field validate={required}
                                                         name={u.name}
                                                         render={({input, meta}: { input: any , meta:any}) => (
                                                             <div>
                                                                 <Input {...input} type='text' placeholder={u.label}/>
                                                                 {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                             </div>
                                                         )}
                                                  ></Field>
                                              </Col>
                                          })
                                          }
                                      </Row>
                                      <Row gutter={[16, 20]}>
                                          {LoadControllerNumber.map(u => {
                                              return <Col className="gutter-row" span={6}>
                                                  <label>{u.label}: </label>
                                                  <Field validate={required}
                                                         name={u.name}
                                                         render={({input , meta}: { input: any , meta:any}) => (
                                                             <div>
                                                                 <Input {...input} type='number' placeholder={u.label}/>
                                                                 {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                             </div>
                                                         )}
                                                  ></Field>
                                              </Col>
                                          })
                                          }
                                      </Row>
                                      <Row gutter={16}>
                                          <Col className="gutter-row" span={6}>
                                              <label>Confirmed by driver:</label>
                                              <Field validate={required}
                                                     name="confirmedByDriver"
                                                     render={({input, meta}: { input: any, meta:any }) => (
                                                         <>
                                                             <Select defaultValue={input.value}
                                                                     onChange={(value, option) => {
                                                                         input.onChange(value)
                                                                     }} style={{width: '100%'}}>
                                                                 {confirmedByDriver.map(data => <Option
                                                                     key={data.id}>{data.name}</Option>)}
                                                             </Select>
                                                             {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}

                                                         </>
                                                     )}
                                              ></Field></Col>

                                          <Col className="gutter-row" span={6}>
                                              <label>Billing status:</label>
                                              <Field validate={required}
                                                     name="billingStatus"
                                                     render={({input, meta}: { input: any, meta:any }) => (
                                                         <>
                                                             <Select defaultValue={input.value}
                                                                     onChange={(value, option) => {
                                                                         input.onChange(value)
                                                                     }} style={{width: '100%'}}>
                                                                 {billingStatus.map(data => <Option
                                                                     key={data.id}>{data.name}</Option>)}
                                                             </Select>
                                                             {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                         </>

                                                     )}
                                              ></Field></Col>
                                          <Col className="gutter-row" span={6}>
                                              <label>Status:</label>
                                              <Field
                                                  name="status"
                                                  render={({input, meta}: { input: any, meta:any }) => (
                                                      <>
                                                          <Select defaultValue={input.value}
                                                                  onChange={(value, option) => {
                                                                      input.onChange(value)
                                                                  }} style={{width: '100%'}}>
                                                              {status.map(data => <Option
                                                                  key={data.id}>{data.name}</Option>)}
                                                          </Select>
                                                          {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                      </>
                                                  )}
                                              ></Field></Col>
                                      </Row>
                                      {LoadControllerSwitch.map(u => {
                                          return <div>
                                              <Field
                                                  name={u.name}
                                                  render={({input}: { input: any }) => (
                                                      <Switch defaultChecked={input.value}
                                                              onChange={input.onChange}/>
                                                  )}
                                              ></Field>
                                              <label
                                                  style={{marginLeft: '15px'}}>{u.label}</label>
                                          </div>
                                      })}
                                      <div className="buttons">
                                          <Button disabled={submitting}  type="primary" htmlType="submit" >
                                              Submit
                                          </Button>
                                      </div>
                                  </Space>

                              </Space>
                          </form>
                      )
                      }
                />
            </Card>
        </div>
    );
};

export default LoadControllerAdd;