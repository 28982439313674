import {useQuery} from "react-query";
import {zipCodeController} from "../../../API/Tms/zipCodes";


export const useZipCodeData = (text: string | number | undefined): object => {
    return useQuery(
        [
            `admin/zip-code${text}`, text
        ],
        () => zipCodeController.read(text),
        {refetchOnWindowFocus: false}
    );
};

export const useZipCodeOneData = (companyId: number | string | undefined): any => {
    return useQuery(
        [`admin/zip-codeOne/${companyId || "all"}`, companyId],
        () => zipCodeController.zipCodeCompanyOne(companyId),
        {refetchOnWindowFocus: false}
    );
};

export const useFindZip = async (zip :any) => {
    return await zipCodeController.zipFinderId(zip)
}