import instance from "../apiClevertms";
import {message} from "antd";


interface Field {
    id: boolean,
    text: boolean,
    isActive: boolean, 
    usersCount: boolean,
    unitsCount:boolean,
    updatedAt: boolean,
    createAt: boolean,
    companyId: boolean
}

export const tagController ={
    async read(text: string | number | undefined,companyId:undefined | number | string) {
        let query: any = {
            // "limit": 100,
            "where": {
              "and":[
                  // {"companyId":{"ilike":`${companyId}`}},
                {"text":{"ilike":`%${text}%`}},
                  companyId?{
                      companyId
                  }:{}
              ]
            }
          }
        const {data}: { data: object } = await instance(`admin/tags?filter=${encodeURIComponent(JSON.stringify({...query}))}`);
        const getCount = async () => {
            return 0;
        }
        const count = await getCount();

        return {data, count: count};
    },

    async tagCompanyOne(companyId: string | number | undefined) {
        const {data}: { data: any } = await instance(`admin/tags/${companyId}`);
        return data;
    },

    async tagPatch(companyData: Field) {
        const {id, ...rest} = companyData
        const key = 'updatable';
        message.loading({content: 'Loading...', key});
        const {data}: { data: any } = await instance(`admin/tags/${id}`, {
            method: 'PATCH',
            data: rest
        }).then(u => {
            setTimeout(() => {
                message.success({content: 'Loaded!', key, duration: 2});
            }, 1000);
            return u;
        });
        return data;
    },

    async addTagController(companyId: any ) {
        message.loading({content: 'Loading...', key:companyId});
        const { data } = await instance("/admin/tags/", {
            method: 'POST',
            data: {
                ...companyId,
            },
        }).then(u => {
            setTimeout(() => {
                message.success({content: 'Loaded!', key:companyId, duration: 2});
            }, 1000);
            return u;
        });
        return data;
    },
    async deleteTagController(id: number | string) {
        message.loading({ content: 'Loading...', key: id });
        let res;
        let error = "";
        try {
            const { data } = await instance(`/admin/tags/${id}`, {
                method: 'DELETE',
            }).then(u => {
                setTimeout(() => {
                    message.success({ content: 'Deleted!', key: id, duration: 2 });
                }, 1000);
                return u;
            });
            res = data;
        }
        catch (err) {
            error = "Oops something went wrong!";
        }
        return { data: res, error };
    },
    async tagFinderId(text :any) {

        let query: any = {
            // "limit": 100,
            "where": {
                "and":[
                    {"text":{"ilike":`%${text}%`}}
                ]
            }
        }
        const {data}: { data: Array<any> } = await instance(`admin/tags?filter=${encodeURIComponent(JSON.stringify({...query}))}`);
        return data;
      },
}