export const zhCN_title = {
  'title.login': '登录',
  'title.dashboard': '首页',
  'title.documentation': '文档',
  'title.guide': '引导页',
  'title.permission.route': '路由权限',
  'title.permission.button': '按钮权限',
  'title.permission.config': '权限配置',
  'title.account': '个人设置',
  'title.notFount': '404',

  'title.approval': '赞同',
  'title.rejected-users': '拒绝用户',
  'title.fmcsa': '首页',
  'title.logs': '日志',
  'title.logsEdit': '日志',
  'title.userCompanies': '用户公司',
  'title.supports': '支持',
  'title.users': '用户',
  'title.user/:id': '用户',
  'title.logs/:id': '日志',
  'title.companies': '公司',
  'title.disabledCompany': '殘障公司',
  'title.companyEdit': '公司',
  'title.vehicles': '汽车',
  'title.vehicleEdit': '汽车',
  'title.makes': '制作',
  'title.makeEdit': '制作',
  'title.models': '模型',
  'title.modelEdit': '模型',
  'title.company-errors': '支持',
  'title.topErrors': '用户',
  'title.topErrors/:id': '用户',
  'title.top-errors': '用户',
  'title.serverConfiguration': '服务器配置',
  'title.devicesEld': '设备领域',

  'title.load': '加载',
  'title.loadEdit': '加载',
  'title.addLoad': '加载',
  'title.broker': '经纪人',
  'title.brokerEdit': '经纪人',
  'title.addBroker': '经纪人',
  'title.city': '城市',
  'title.cityEdit': '城市',
  'title.addCity': '城市',
  'title.location': '地点',
  'title.locationEdit': '地点',
  'title.addLocation': '地点',
  'title.tag': '标签',
  'title.tagEdit': '标签',
  'title.addTag': '标签',
  'title.zipCode': '邮政编码',
  'title.zipCodeEdit': '邮政编码',
  'title.addZipCode': '邮政编码',
  'title.category': '类别',
  'title.categoryEdit': '类别',
  'title.addCategory': '类别',
  'title.contact': '接触',
  'title.contactEdit': '接触',
  'title.addContact': '接触',
  'title.device': '设备',
  'title.addDevice': '设备',
  'title.deviceEdit': '设备',
  'title.report': '报告',
};
