import instance from "../../api";
import {message, notification} from "antd";
import {ErrorNotification} from "../../../Utils/data";

export const truckInfo = {
    async read(driverId: number) {
        const {data}: any = await instance(`users/truck-info/${driverId}`);
        return {
            data: data.data,
            count: data.count
        };
    },

    async getOneTruckInfo(truckInfoId: number | undefined) {
        const {data}: { data: object } = await instance(`users/truck-info/${truckInfoId}`);
        return data;
    },

    async getDocuments(id: string | undefined, userId: undefined | number | string, companyId: number | string, type: string | number, skip: number) {
        let url = id ?
            `documents/list?page=${skip}&perPage=10&userId=${id}` : `documents/list?page=${skip}&perPage=10&userId=${userId}&companyId=${companyId}&type=${type}`
        try {
            const {data}: any = await instance(url);
            return {
                data: data.data,
                count: data.count
            };
        } catch (error: any) {
            if (error.response && error.response.status ) {
                ErrorNotification({ status: error.response.status , message: error.response.data.error.message });
            }
        }
    },

    async commandsPost(body: any) {
        const key = 'updatable';
        message.loading({content: 'Loading...', key});
        try {
            const {data}: { data: any } = await instance(`commands/v2/message`, {
                method: 'POST',
                data: body
            }).then(u => {
                setTimeout(() => {
                    message.success({content: 'Loaded!', key, duration: 2});
                }, 1000);
                return u;
            });
            return data;
        } catch (error: any) {
            if (error.response && error.response.status ) {
                ErrorNotification({ status: error.response.status , message: error.response.data.error.message });
            }
        }
    },

}


