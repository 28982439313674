import type { FC } from 'react';

import {Breadcrumb, Tabs} from 'antd';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { addTag, removeTag, setActiveTag } from '../../../stores/tags-view.store';

import TagsViewAction from './tagViewAction';
import ReactGA from "react-ga4";

const TagsView: FC = () => {
  const { tags, activeTagId } = useSelector((state:any) => state.tagsView);
  const { menuList, locale } = useSelector((state:any) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // onClick tag
  const onChange = (key: string) => {
    const tag = tags.find((tag:any) => tag.path === key);
    if (tag) {
      setCurrentTag(tag.path);
        navigate(tag.path);
    }
  };

  // onRemove tag
  const onClose = (targetKey: string) => {
    dispatch(removeTag(targetKey));
  };

  const setCurrentTag = useCallback(
    (id?: string) => {
      const tag = tags.find((item:any) => {
        if (id) {
          return item.path === id;
        } else {
          return item.path === location.pathname;
        }
      });

      if (tag) {
        dispatch(setActiveTag(tag.path));
      }
    },
    [dispatch, location.pathname, tags],
  );
  const handleBreadcrumbClick = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    if (menuList.length) {
      const menu = menuList.find((m:any) => m.path === location.pathname);
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
      if (menu) {
        dispatch(
          addTag({
            ...menu,
            closable: menu.code !== 'dashboard',
          }),
        );
      }
    }
  }, [dispatch, location.pathname, menuList]);

  return (
    <div id="pageTabs" style={{ padding: '0px 0px 6px 0px' }}>
      <Tabs
        tabBarStyle={{ margin: 0 }}
        onChange={onChange}
        activeKey={activeTagId}
        type="editable-card"
        hideAdd
        onEdit={(targetKey, action) => action === 'remove' && onClose(targetKey as string)}
        tabBarExtraContent={<TagsViewAction />}
        items={tags.map((tag:any) => {
          return {
            key: tag.path,
            closable: tag.closable,
            label: tag.label[locale],
          };
        })}
      />
      {/*<Breadcrumb     separator="/">*/}
      {/*  {tags.map((tag: any) => (*/}
      {/*      <Breadcrumb.Item key={tag.path} onClick={() => handleBreadcrumbClick(tag.path)}>*/}
      {/*        {tag.label[locale]}*/}
      {/*      </Breadcrumb.Item>*/}
      {/*  ))}*/}
      {/*</Breadcrumb>*/}
    </div>
  );
};

export default TagsView;
