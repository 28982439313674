import React, {useState} from "react";
import {Col, Input, Modal, Select, Space} from "antd";
import SearchOptions from "../../../../Utils/SearchOptions";
import {
    SearchForUserCompanyDRAdd,
    SearchForUserCompanyEM,
    SearchResultForCompany,
} from "../../../../Utils/SearchResults";
import {userCompany} from "../../../../API/TT_ELD/userCompany";
import {sendEvent} from "../../../../Utils/analytics";

const {Option} = Select;
const AddMultiUserModal = ({
                               isModalVisible,
                               setIsModalVisible, refetch,
                               id,
                               getCMPname,
                           }: {
    isModalVisible: boolean;
    setIsModalVisible(val: boolean): void;
    userId: number | string | undefined;
    companyId: number | undefined | string;
    refetch: any;
    id: number | string;
    getCMPname: any;
}) => {
    const [driverId, setDriverId] = useState<undefined | number | string>(undefined);
    const [role, setRole] = useState<any>("support-user");
    const [currentCompanyId, setCurrentCompanyId] = useState<string | number | undefined>(id);

    const handleOk = async () => {
        try {
            if (currentCompanyId && driverId) {
                await userCompany.addMultiUser(currentCompanyId, driverId, role);
                sendEvent(`Add Multi User UserCompany`, `Add Multi User UserCompany page `, `Modal Add Multi User ${currentCompanyId + ' ' + driverId + ' ' + role}`, driverId);
                refetch()
            }
            setIsModalVisible(false);
        } catch (erray) {

        }

    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const onSelectDriver = (
        value: any,
        {valId}: { valId: number | string | undefined }
    ) => {
        if (valId) {
            setDriverId(valId);
        }
    };

    const ROLELIST = [
        {
            label: "Support User",
            value: 'support-user'
        },

        {
            label: "Service Account ",
            value: "service-account",
        },
        {
            label: "Owner",
            is_owner: true,
            value: 'owner',
            title: "The owner of the resource has full control over the resource, including the ability to read, write, edit, or delete it. They can also grant permission to other users to access or modify the resource."
        }
    ];

    return (
        <>
            <Modal title="Add User" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                   okButtonProps={{disabled: !(driverId && currentCompanyId && role)}}>
                <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                    <Select defaultValue='support-user' onChange={(value, option) => {
                        setRole(value === 'all' ? undefined : value)
                    }} style={{width: '300px'}}>
                        {/*<Option key={'all'}>All Users</Option>*/}
                        {ROLELIST.map((u: any, index: any) => <Option key={index} value={u.value}
                                                                      title={u.title}>{u.label}</Option>)}
                    </Select>

                    {!id && <SearchOptions SearchResult={SearchResultForCompany}
                                           onSelect={(value: any, {valId}: { valId: number | undefined }) => {
                                               setCurrentCompanyId(valId);
                                           }} placeholder={'Search by company'} value={''}/>}
                    {id && <Col className="" span={15}>
                        <Input style={{width: '300px'}} disabled={true} defaultValue={getCMPname?.label} type='number'
                               placeholder={getCMPname?.label}/>
                    </Col>}

                    <SearchOptions SearchResult={(query: string) => SearchForUserCompanyDRAdd(query, '')}
                                   onSelect={onSelectDriver} placeholder="username" value={''}/>
                    <SearchOptions SearchResult={(query: string) => SearchForUserCompanyEM(query, '')}
                                   onSelect={onSelectDriver} placeholder="email" value={''}/>
                </Space>
            </Modal>
        </>
    );
};

export default AddMultiUserModal;
