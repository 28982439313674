import React, {useEffect, useState} from 'react';
import TruckInfoTable from "./TruckInfoTable/TruckInfoTable";
import {useTruckInfo} from "../../../../../../Hooks/TT_ELD/TruckInfo";
import {useParams} from "react-router-dom";
import TruckAllInfo from "./TruckInfoTable/TruckInfo/TruckAllInof";
import {Empty} from "antd";
import {sendEvent} from "../../../../../../Utils/analytics";

interface Data {
    data?: {
        data: Array<any>,
        count: number
    };
    isLoading?: boolean
}

type params = {
    readonly id: any;
};
const TruckInfo = () => {
    const {id} = useParams<params>();

    // useEffect(() => {
    //     sendEvent('Truck Info Tab', 'Open Truck Info Tab', `Open Truck Info tab `, 1)
    // }, [])
    const {data}: Data = useTruckInfo(id)



    return  (
        <>
            {data?.data ?
                <div>
                    <TruckAllInfo data={data?.data}/>
                </div> : <Empty style={{margin:'200px  0'}}/>}

        </>

    )
};

export default TruckInfo;


