import instance from "../apiClevertms";

import {message} from "antd";

type numStr = string | number

interface Field {
    id: numStr,
    updatedAt: numStr,
    createdAt: numStr,
    isActive: boolean,
    model: numStr,
    androidVersion: numStr,
    ios: numStr,
    deviceToken: numStr,
    fcmToken: numStr,
    oneSignalUserId: numStr,
    appVersion: numStr,
    versionCode: numStr,
    deviceInfo: numStr,
    driverId: numStr,
    companyId: numStr,
    config: numStr,
    mediaDeviceId: numStr,
    dispatcherId: numStr
}

export const deviceController = {
    async read(driverId: string | number | undefined,companyId:undefined | number| string) {
        let query: any = {
            // "limit": 100,
            "where": {
                "and":[
                    {"id":{"ilike":`%${driverId}%`}},
                    companyId?{
                        companyId
                    }:{}
                ]
            }
        }
        const {data}: { data: object } = await instance(`admin/devices?filter=${encodeURIComponent(JSON.stringify({...query}))}`);
        const getCount = async () => {
            return 0;
        }
        const count = await getCount();

        return {data, count: count};
    },

    async deviceCompanyOne(Id: string | number | undefined) {
        const {data}: { data: any } = await instance(`admin/devices/${Id}`);
        const {
            createdAt,
            updatedAt,
            androidVersion,
            deviceInfo,
            config,

            ...rest
        } = data
        return {...rest};
    },

    async devicePatch(companyData: any) {
        const {id, createdAt, updatedAt, ...rest} = companyData
        const key = 'updatable';
        message.loading({content: 'Loading...', key});
        const {data}: { data: any } = await instance(`admin/devices/${id}`, {
            method: 'PATCH',
            data: rest
        }).then(u => {
            setTimeout(() => {
                message.success({content: 'Loaded!', key, duration: 2});
            }, 1000);
            return u;
        });
        return data;
    },

    async addDeviceController(companyId: any) {
        message.loading({content: 'Loading...', key: companyId});
        const {data} = await instance("/admin/devices/", {
            method: 'POST',
            data: {...companyId},
        }).then(u => {
            setTimeout(() => {
                message.success({content: 'Loaded!', key: companyId, duration: 2});
            }, 1000);
            return u;
        });
        return data;
    },
    async deleteDevice(id: number | string) {
        message.loading({content: 'Loading...', key: id});
        let res;
        let error = "";
        try {
            const {data} = await instance(`/admin/devices/${id}`, {
                method: 'DELETE',
            }).then(u => {
                setTimeout(() => {
                    message.success({content: 'Deleted!', key: id, duration: 2});
                }, 1000);
                return u;
            });
            res = data;
        } catch (err) {
            error = "Oops something went wrong!";
        }
        return {data: res, error};
    },
    async deviceFinderId(id :any) {
        let query: any = {
            "limit": 100,
            "where": {
                "and":[
                    {"driverId":{"ilike":`%${id}%`}}
                ]
            }
        }
        const {data}: { data: Array<any> } = await instance(`admin/devices?filter=${encodeURIComponent(JSON.stringify({...query}))}`);
        return data;
    },
}