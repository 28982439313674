import {useQuery} from "react-query";
import {devicesEld} from "../../../../API/TT_ELD/Team/devices";

export const useDevicesEld = (userId:string|number, model:string|number, appVersion:string |number,versionCode:string|number  ,device:string |undefined,  sort: boolean|undefined |string, skip: any): object => {
    return useQuery([
            `devices/${userId}`, userId,model,appVersion,versionCode,device, sort, skip], () => devicesEld.read(userId,model, appVersion, versionCode, device, sort, skip),
        { refetchOnWindowFocus: false })
}


export const useFindDeviceName = async (query:string) => {
    return await devicesEld.deviceFinderName(query)
}