import React from 'react';
import {Field, Form} from "react-final-form";
import {Button, Card, Col, Input, Row, Space, Switch} from "antd";
import {useNavigate} from "react-router-dom";
import {zipCodeController} from "../../../../../API/Tms/zipCodes";

type numStr = number | string

interface Fields {
    zipCode: numStr,
    city: numStr,
    state: string,
    geopoint: numStr,
    lat: number,
    lng: number,
    isActive: boolean,
    timezone: numStr,
    updatedAt: numStr,
    createdAt: numStr
    action: string;
}
const AddZipCode = () => {
    const cityInputs = [
        {label:"ZIP code", name:'zipCode'},
        {label:"City", name:'city'},
        {label:"State", name:'state'},
        {label:"Geopoint", name:'geopoint'},
    ]

    const cityNumber = [
        {label:"Timezone", name:'timezone'},
        {label:"Lat", name:'lat'},
        {label:"Lng", name:'lng'},
    ]
    let navigate = useNavigate();

    const onSubmit = async (value: Fields) => {
        await zipCodeController.addZipCodeController(value)
        navigate(-1)
    };
    const required = (value:any) => (value ? undefined : 'Required')

    return (
        <div className='mainPage'>{
            <Card>
                <Form onSubmit={onSubmit}
                      render={({handleSubmit, submitting}) => (
                          <form onSubmit={handleSubmit}>
                              <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                  <h1>Add ZipCode</h1>
                                  <Space direction="vertical" size="middle"
                                         style={{display: 'flex',}}>
                                      <Row gutter={[16, 20]}>

                                          {cityInputs.map(u => {
                                              return <Col className="gutter-row" span={6}>
                                                  <label>{u.label}: </label>
                                                  <Field validate={required}
                                                         name={u.name}
                                                         render={({input, meta}: { input: any , meta:any}) => (
                                                             <div>
                                                                 <Input {...input} type='text' placeholder={u.label}/>
                                                                 {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                             </div>
                                                         )}
                                                  ></Field>
                                              </Col>
                                          })
                                          }  {cityNumber.map(u => {
                                          return <Col className="gutter-row" span={6}>
                                              <label>{u.label}: </label>
                                              <Field validate={required}
                                                     name={u.name}
                                                     render={({input , meta}: { input: any , meta:any}) => (
                                                         <div>
                                                             <Input {...input} type='number' placeholder={u.label}/>
                                                             {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                         </div>
                                                     )}
                                              ></Field>
                                          </Col>
                                      })
                                      }

                                      </Row>
                                      <div>
                                          <label>Is active: </label>
                                          <Field
                                              name="isActive"
                                              render={({input}: { input: any }) => (
                                                  <Switch defaultChecked={input.value} onChange={input.onChange}/>
                                              )}
                                          />
                                      </div>
                                      <div className="buttons">
                                          <Button disabled={submitting} type="primary" htmlType="submit">
                                              Submit
                                          </Button>
                                      </div>
                                  </Space>
                              </Space>
                          </form>
                      )
                      }/>
            </Card>
        }</div>

    );
};

export default AddZipCode;