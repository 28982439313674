import React, {useState} from 'react';
import {Modal, Spin, Table, Button} from "antd";

import moment from "moment";
import {sendEvent} from "../../../../../Utils/analytics";
import {fmcsa} from "../../../../../API/TT_ELD/fmcsa";
import {Link} from "react-router-dom";

type numStr = string | number

interface reportSource {
    no: numStr
    driver: numStr
    text: numStr,
    type: numStr
    address: numStr
    status: numStr
    odometr: numStr
    date: {date:string , day:string}
    key: React.Key;

}

const columns: object[] = [
    {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        sorter: {
            compare: (a: any, b: any) => a.no - b.no,
            multiple: 1,
        },
        width: 50
    },
    {
        title: 'Driver full name',
        dataIndex: 'driver',
        key: 'driver',
    },
    {
        title: 'Violations',
        dataIndex: 'text',
        key: 'text',
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (val: { date: string, day: string }) => (
          <> {val.date ? val.date: val.day}</>
        )
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Odometer',
        dataIndex: 'odometr',
        key: 'odometr',
    },
]
const FMCSA_Audit = ({
                         data,
                         isModalVisible,
                         setIsModalVisible,
                         status,
                         refetch
                     }: { data: any, isModalVisible: boolean | undefined, setIsModalVisible(isModalVisible: boolean): void ,status:string ,refetch:any}) => {
    const handleCancel = () => {
        setIsModalVisible(!isModalVisible)
    }

    const warning = () => {
        Modal.warning({
            title: 'FMCSA audit',
            content: 'Are you sure you want to approve this FMCSA report?',
            okText:'Approve',
            onOk: async () => {
                return new Promise(async (resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    sendEvent('FMCSA', 'Checking', '', 1);
                    await fmcsa.fmcsaChech(data?.id, {"report_status": 'checked'})
                    refetch()
                    setIsModalVisible(!isModalVisible)
                }).catch(() => {
                });
            },
            okCancel:true
        });
    };
    return (
        <Modal width='90%' title='FMCSA audit' open={isModalVisible} onCancel={handleCancel} footer={[
            <>
                { status === 'needs_review' &&<>
                    <Button key="submit" type="primary" onClick={warning}>
                        Approve
                    </Button>
                    <Button
                        onClick={handleCancel}>
                        Cancel
                    </Button>
                </>}
            </>
        ]}>
            <Spin size='large' spinning={!data}>
                <Table bordered
                       dataSource={data?.response[0]?.map((u: any, i: number): reportSource => {
                           const obj: reportSource = {
                               no: i + 1,
                               driver: u?.driver?.first_name + ' ' + u?.driver?.second_name,
                               text: u.text,
                               type: u?.type,
                               address: u?.data?.address,
                               status: u?.data?.status,
                               odometr: u?.data?.odometr,
                               date: {date :u?.date , day: u.day},
                               key: i
                           }
                           return obj
                       })} columns={columns} pagination={{total: data?.count, pageSize: 50, showSizeChanger: false,}}
                       scroll={{y: 550, x: 1000}} sticky/>
            </Spin>
        </Modal>
    );
};

export default FMCSA_Audit;