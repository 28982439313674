import {useQuery} from "react-query";
import {truckInfo} from '../../../API/TT_ELD/Users/truckInfo'

export const useTruckInfo = (driverId: number): object => {
    return useQuery([
            `truckInfo/${driverId || 'all'}`, driverId],
        () => truckInfo.read(driverId),
        {refetchOnWindowFocus: false})
}

export const useGetOneTruckInfo = (truckInfoId: number | undefined): any => {
    return useQuery([
            `getOneTruckInfo/${truckInfoId || 'all'}`, truckInfoId],
        () => truckInfo.getOneTruckInfo(truckInfoId),
        {refetchOnWindowFocus: false})
}


export const useGetDocuments = (id: string | undefined, userId: undefined | number | string, companyId: number | string, type: string | number, skip: number): object => {
    return useQuery([
            `getDocuments/${id || 'all'}`, id, userId, companyId, type, skip],
        () => truckInfo.getDocuments(id, userId, companyId, type, skip),
        {refetchOnWindowFocus: false})
}