import React from 'react'
import {Button, Modal, Space, Spin, Table, Tag} from "antd";
import {Link} from "react-router-dom";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import { useCategoryData } from '../../../../../Hooks/cleverTms/Categoty';
import { useQueryClient } from 'react-query';
import moment from 'moment';
import {contactController} from "../../../../../API/Tms/contact";
import {deviceController} from "../../../../../API/Tms/device";
import {useDeviceData} from "../../../../../Hooks/cleverTms/Device";


type numStr = string | number



interface contactSource {
    no: numStr
    updatedAt: numStr,
    createdAt: numStr,
    isActive: boolean,
    model: numStr,
    appVersion: numStr,
    deviceInfo: numStr,
    driverId: numStr,
    companyId: numStr,
    action: { id: numStr, queryClient: any,};
    key: React.Key;
}

const DeviceTable = ({device = [], total = 0, onChange, isLoading} : {device: Array<any>| undefined, total: number | undefined, onChange(current: any): void, isLoading: boolean | undefined }) => {
    const queryClient = useQueryClient();
    const filterData = (customer:any) =>
        customer?.map((item:any) => ({
            key: item,
            value: item,
            text: item
        }));
    const columns: object[] = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            sorter: {
                compare: (a:any, b:any) => a.no - b.no,
            },
        },
        {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            sorter: (a:any, b:any) => a.updatedAt.length - b.updatedAt.length,

        },{
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a:any, b:any) => a.createdAt.length - b.createdAt.length,

        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Is Active',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (tag: boolean) => (
                <Tag color={tag ? 'geekblue' : 'red'}>
                    {tag ? 'True' : 'False'}
                </Tag>
            ),
            filters: [
                {
                    text: 'True',
                    value: true,
                },
                {
                    text: 'False',
                    value: false,
                },

            ],
            onFilter: (value: any, record:any) => {
                return record.isActive === value

            },
        },
        {
            title: 'Company ID',
            dataIndex: 'companyId',
            key: 'companyId',
            sorter: {
                compare: (a:any, b:any) => a.companyId - b.companyId,
            },
        },{
            title: 'Device info',
            dataIndex: 'deviceInfo',
            key: 'deviceInfo',
            sorter: (a:any, b:any) => a.deviceInfo.length - b.deviceInfo.length,


        },{
            title: 'App version',
            dataIndex: 'appVersion',
            key: 'appVersion',
            filters: filterData(
                device?.map((item:any) => item.appVersion)
                    .filter((value, index, self) => self.indexOf(value) === index)
            ),
            onFilter: (value:any, record:any) => record.appVersion.indexOf(value) === 0,
            // sorter: (a:any, b:any) => a.appVersion.length - b.appVersion.length
        },{
            title: 'Driver ID',
            dataIndex: 'driverId',
            key: 'driverId',
            sorter: {
                compare: (a:any, b:any) => a.driverId - b.driverId,
            },
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: ({id, queryClient} : {id: string | number,queryClient:any }) =>
                <Space>
                    <Link to={`${id}`}><Button>Edit</Button></Link>
                    <Button type='primary' danger onClick={() => {
                        Modal.confirm({
                                title: 'LoadController',
                                icon: <ExclamationCircleOutlined rev={undefined}/>,
                                content: 'Do you want to delete this load ?',
                                okText: 'Ok',
                                cancelText: 'cancel',
                                onOk: async () => {
                                    await deviceController.deleteDevice(id)
                                    queryClient.refetchQueries(useDeviceData);
                                }
                            }
                        );
                    }
                    }
                    >Delete
                    </Button>
                </Space>

        },
    ]
    return (
        <div>
            <Spin size='large' spinning={isLoading}>
                <Table style={{margin:'15px 0 0 0'}} onChange={onChange} dataSource={device?.map((u: any, i: number): contactSource => {
                    let create = u.createdAt
                    let update = u.updatedAt
                    const obj: contactSource = {
                        no: i + 1,
                        updatedAt: update ? moment(update).format("YYYY-MM-DD, h:mm:ss a") : "",
                        createdAt:  create ? moment(create).format("YYYY-MM-DD, h:mm:ss a") : "",
                        model: u.model,
                        isActive: u.isActive,
                        companyId:u.companyId,
                        deviceInfo: u.deviceInfo,
                        appVersion: u.appVersion,
                        driverId:u.driverId,
                        action: {id: u.id, queryClient},
                        key: u.id
                    }
                    return obj
                })} columns={columns} pagination={{total: total, pageSizeOptions: [10,50,100]}}
                />
            </Spin>
        </div>
    )
}

export default DeviceTable