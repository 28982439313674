import React, {useState} from 'react'
import {Space, Tabs} from "antd";
import {SecurityScanOutlined, WarningOutlined, HistoryOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {useCompanyHistory} from "../../../../../../Hooks/TT_ELD/Supports";
import SupportError from "./SupportError";
import SupportNote from "./SupportNote";
import SupportHistory from "./SupportHistory";

interface Data {
    data?: {
        data: Array<any>,
        count: number
    };
    isLoading?: any,
    refetch?: any,
    isFetching?: any
}

type params = {
    readonly id: any;
};

const SupportMain = () => {

    const {id} = useParams<params>();
    const {data, isLoading, isFetching, refetch}: Data = useCompanyHistory(id)
    const [skip, setSkip] = useState(0);
    const onChange = (query: any) => {
        setSkip(10 * (parseInt(query.current) - 1));
    }
    const { TabPane } = Tabs;

    const items = [
        {
            label: (
                <span>
        Errors
      </span>
            ),
            key: '1',
            children: (
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <SupportError />
                </Space>
            ),
            icon : <WarningOutlined rev={undefined} />
        },
        {
            label: (
                <span>
      History
      </span>
            ),
            key: '2',
            children: (
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <SupportHistory
                        support={data}
                        total={data?.count}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        onChange={onChange}
                    />
                </Space>
            ),
            icon :   <HistoryOutlined rev={undefined} />

        },
        {
            label: (
                <span>
        Support
      </span>
            ),
            key: '3',
            children: (
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <SupportNote support={data} refetch={refetch} />
                </Space>
            ),
            icon :  <SecurityScanOutlined rev={undefined} />
        },
    ];
    return (
        <div className='mainPage'>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                <Tabs defaultActiveKey="1" items={items} />
            </Space>
        </div>
    )
}

export default SupportMain