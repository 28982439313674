import React from 'react';
import {Link} from "react-router-dom";
import {Card, Descriptions, Empty, Tag} from "antd";
type numStr = string | number

interface vehicleType {
  id:number
  first_name:string
  second_name:string
  username:string
  is_active:boolean
  address:numStr
  role:string
  emailVerified:boolean
  whichservice:string
}

const TabDrivers = ({driver ,codriver,isLoading ,isFetching}:{driver:vehicleType, codriver:vehicleType,isLoading: boolean | undefined,isFetching: boolean|undefined}) => {
  return (
      <>
          { driver ? <div>
              <Card loading={isLoading || isFetching} title="Driver " bordered={false} style={{width: '100%'}}>
                  <p>{driver && (
                      <Link style={{color: 'inherit'}} to={`/user/users/${driver.id}`}>
                          <Descriptions size='small' title="" bordered>
                              <Descriptions.Item label="First Name">
                                  {driver?.first_name}
                              </Descriptions.Item>
                              <Descriptions.Item label="Second Name">
                                  {driver?.second_name}
                              </Descriptions.Item>
                              <Descriptions.Item label="Username">
                                  {driver?.username}
                              </Descriptions.Item>
                              <Descriptions.Item label="Role">
                                  {driver?.role}
                              </Descriptions.Item>
                              <Descriptions.Item label="Is Active">{<Tag color={driver?.is_active ? 'geekblue' : 'red'}>
                                  {driver?.is_active ? 'True' : 'False'}
                              </Tag>}</Descriptions.Item>
                              <Descriptions.Item label="Which Service">
                                  {driver?.whichservice}
                              </Descriptions.Item>
                          </Descriptions>
                      </Link>
                  )}</p>
              </Card>
              {codriver && <Card loading={isLoading || isFetching} title="CoDriver " bordered={false}
                                 style={{width: '100%', marginTop: '20px'}}>
                  <p>{codriver && (
                      <Descriptions size='small' title="" bordered>
                          <Descriptions.Item label="First Name">
                              {codriver?.first_name}
                          </Descriptions.Item>
                          <Descriptions.Item label="Second Name">
                              {codriver?.first_name}
                          </Descriptions.Item>
                          <Descriptions.Item label="Role">
                              {codriver?.username}
                          </Descriptions.Item>
                          <Descriptions.Item label="Role">
                              {codriver?.role}
                          </Descriptions.Item>
                          <Descriptions.Item label="Is Active">{<Tag color={codriver?.is_active ? 'geekblue' : 'red'}>
                              {codriver?.is_active ? 'True' : 'False'}
                          </Tag>}</Descriptions.Item>
                          <Descriptions.Item label="Which Service">
                              {codriver?.whichservice}
                          </Descriptions.Item>
                      </Descriptions>
                  )}</p>
              </Card>}
          </div> : <Empty style={{margin:'200px  0'}}/>}
      </>

  );
};

export default TabDrivers;
