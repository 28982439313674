import {useQuery} from "react-query";
import {loadController} from "../../../API/Tms/loadController";

export const useLoadsData = (orderNumber: string | number |undefined,date: any , skip:string|number ): object => {
    return useQuery([`admin/loads/${orderNumber}`, orderNumber,date,skip],
        () => loadController.read(orderNumber,date,skip),
        { refetchOnWindowFocus: false })
}

export const useControllerOneData = (companyId: number | string | undefined): any => {
    return useQuery([
            `admin/loads/${companyId || 'all'}`, companyId],
        () => loadController.loadControllerOne(companyId),
        { refetchOnWindowFocus: false })
}

// export const useLoadFindDriver = async ({name,  companyId, role}: {name: string,companyId: number | undefined, role: string | undefined}) => {
//     name = '%' + name + '%';
//     return await loadController.driverFinderLoad({name, companyId, role});
// }
// export const useLoadFindDriver =  (name:any): any => {
//     return useQuery([
//         `admin/findLoad/${name}` ,name, ],
//         ()=>  loadController.driverFinderLoad(name),
//         { refetchOnWindowFocus: false, staleTime: 5 * 60 * 1000 })
// }

export const useLoadFindDriver = async (orderNumber :any) => {
    return await loadController.driverFinderLoad(orderNumber)
}
