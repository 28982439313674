import {useQuery} from "react-query";
import {logbook} from "../../../API/TT_ELD/logbook";
import {dailies} from "../../../API/TT_ELD/daily";

export const useLogBook = (companyId: string | number | undefined,active:string , skip: string | number): object => {
    return useQuery([
            `logbook/${companyId}`, companyId,active, skip],
        () => logbook.read(companyId,active, skip),
        {refetchOnWindowFocus: false})
}
export const useAssignLogBook = (name: string | number | undefined): object => {
    return useQuery([
            `assign_logbook/${name}`, name,],
        () => logbook.assignLogBook(name),
        {refetchOnWindowFocus: false})
}
export const useAssignUser = (name: any , ): object => {
    return useQuery([
            `assign_logbook_user/${name}`, name,],
        () => dailies.driverFinderUser(name , ''),
        {refetchOnWindowFocus: false})
}
export const useLogBookOne = (id:string| undefined) => {
    return useQuery([`logbook_one/${id}`,id], () => logbook.logbookFind(id), { refetchOnWindowFocus: false})
}

///driverFinderUser