import axios from 'axios';
import {clear_local_storage, ErrorNotification} from "../Utils/data";

const token: string | null = localStorage.getItem('token');

const instance = axios.create({
    baseURL: 'https://api.evoeld.com/',
});
instance.interceptors.response.use(response => response, error => {
    if(error.response) {
        if (error.response.data.error.statusCode === 401) {
            clear_local_storage();
            ErrorNotification({ status: error.response && error.response.status , message: error.response.data.error.message });
        }
    }
    return Promise.reject(error);
})

if(token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}


export default instance;