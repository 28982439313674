import React, {useEffect, useState} from "react";
import { Tabs} from "antd";
import {
    AndroidOutlined,
    AppleOutlined,
    UserOutlined,
    WindowsOutlined,
    DeliveredProcedureOutlined,
    DiffOutlined,
    CarOutlined,
    SafetyCertificateOutlined,
    HistoryOutlined
} from "@ant-design/icons";
import TabDrivers from "./CompanyTabs/TabDrivers";
import UserCompanies from "../../../UserCompanies/UserCompanies";
import Subscription from "./CompanyTabs/Subscription";
import MainField from "./CompanyTabs/MainField";
import Stripe from "./CompanyTabs/Stripe";
import IFTA from "./CompanyTabs/IFTA/IFTA";
import useRouteQuery from "../../../../../../Utils/useRouterQuery";
import VehiclesCompany from "../../Vehicles/VehiclesCompany";
import FreeTrial from "./CompanyTabs/FreeTrial";
import {useCompanyData} from "../../../../../../Hooks/TT_ELD/Companies";
import {useParams} from "react-router-dom";
import  Whichservice  from "./CompanyTabs/Whichservice";
import SubcriptionQuantity from "./CompanyTabs/SubcriptionQuantity";
import SubscriptionUpdate from "./CompanyTabs/SUBSCRIPTION_History";

type MyObjectType = {
    [key: string | number]: any;
};
type params = {
    readonly id: any
}

export const Company: React.FC = () => {
    const [getTabKey, setTabKey] = useRouteQuery("1", 'getTabKey', (v) => String(v));
    const [getTabKeyLocal, setTabKeyLocal] = useState('');
    useEffect(() => {
        setTabKeyLocal(getTabKey);
    }, [getTabKey])
    const {id} = useParams<params>();
    const {data}: MyObjectType = useCompanyData(id);
    const items = [
        {
            label: <span>Main fields</span>,
            key: '1',
            children:<MainField/>,
            icon: <AppleOutlined rev={undefined}/>
        },
        {
            label: <span>Stripe</span>,
            key: '2',
            children:<Stripe/>,
            icon: <AndroidOutlined rev={undefined}/>
        },
        {
            label: <span>Subscription</span>,
            key: '3',
            children: <Subscription/>,
            icon: <DeliveredProcedureOutlined rev={undefined}/>
        },
        {
            label: <span>Subscription history</span>,
            key: '4',
            children: <SubscriptionUpdate/>,
            icon: <HistoryOutlined rev={undefined}/>
        },
        {
            label: <span>Subscription update</span>,
            key: '5',
            children: <SubcriptionQuantity/>,
            icon: <DeliveredProcedureOutlined rev={undefined}/>
        },
        {
            label: <span>Drivers</span>,
            key: '6',
            children: <TabDrivers/>,
            icon: <WindowsOutlined rev={undefined}/>
        },
        {
            label: <span>Users</span>,
            key: '7',
            children: <UserCompanies uid={''}/>,
            icon: <UserOutlined rev={undefined}/>
        },
        {
            label: <span>Vehicles</span>,
            key: '8',
            children: <VehiclesCompany/>,
            icon: <DiffOutlined rev={undefined}/>
        },
        {
            label: <span>IFTA</span>,
            key: '9',
            children: <IFTA/>,
            icon :<SafetyCertificateOutlined rev={undefined}/>

        },
        {
            label: data?.stripe?.subscription?.status !== 'active' && <span>Free trial</span> ,
            key: '10',
            children: <FreeTrial/>,
            icon :data?.stripe?.subscription?.status !== 'active' && <CarOutlined  rev={undefined}/>

        },
        {
            label: <span>Change whichservice</span>,
            key: '11',
            children: <Whichservice/>,
            icon :<SafetyCertificateOutlined rev={undefined}/>
        },
    ]

    return <div className='mainPage'>
            <div >
                { getTabKeyLocal && <Tabs
                    onTabClick={(e) => {
                        setTabKey(e);
                    }}
                    defaultActiveKey={getTabKey}
                    style={{height: '100%'}}
                    items={items.map((tab) => {
                        return {
                            label: tab.label,
                            key: tab.key,
                            children: tab.children,
                            icon : tab.icon
                        };
                    })}
                />}
            </div>
    </div>
};

 export default Company
