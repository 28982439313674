import React, {useState} from 'react';
import {useGetRole, useUserCompaniesData} from "../../../../Hooks/TT_ELD/UserCompanies";
import UserCompaniesTable from "./UserCompaniesTable";
import {
    SearchForUserCompanyDR,
    SearchForUserCompanyEM,
    SearchResultForCompany,
} from "../../../../Utils/SearchResults";
import SearchOptions from "../../../../Utils/SearchOptions";
import {Button, Modal, Select} from "antd";
import AddMultiUserModal from "./AddMultiUserModal";
import {useParams} from "react-router-dom";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {userCompany} from "../../../../API/TT_ELD/userCompany";

type params = {
    readonly id: any
}
type Data = {
    data?: {
        data: Array<any>,
        count: number
    },
    isLoading?: boolean,
    refetch?: any,
    isFetching?: any
}


const {Option} = Select;

const UserCompanies = ({uid}: { uid: string | undefined }) => {
    const {id} = useParams<params>();
    const [skip, setSkip] = useState<string | number | undefined>(1);
    const [driverId, setDriverId] = useState<undefined | number | string>(uid);
    const [role, setRole] = useState<string | undefined>('');
    const [companyId, setCompanyId] = useState<undefined | number | string>(uid ? '' : id);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isModalBulk, setIsModalBulk] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [getKey, setGetKey] = useState<Array<number> | string | number>([])
    const {data, refetch, isLoading, isFetching}: Data = useUserCompaniesData(id, companyId, role, driverId, skip);
    const getRole: any = useGetRole()
    const filteredData: any = data?.data.map((u: any) => {
        return {
            value: u?.company?.id,
            label: u?.company?.name,
        }
    })
    const roleList = getRole?.data?.data ? Object.keys(getRole.data.data).map(key => ({
        label: getRole.data[key],
        value: key
    })) : [];
    let getCMPname

    if (filteredData) {
        getCMPname = filteredData[0]

    }
    const onChange = (query: any) => {
        setSkip(1 * (parseInt(query.current)));
    }
    const onSelectDriver = (value: any, {valId}: { valId: number | string | undefined }) => {
        setDriverId(valId);
        if (valId) {
            setSkip(1)
        }
    }
    const showModal = () => {
        setIsModalVisible(true);
    };

    const [modal, contextHolder] = Modal.useModal();
    const confirm = () => {
        modal.confirm({
            title: 'Delete Companies',
            icon: <ExclamationCircleOutlined/>,
            // content: 'Bla bla ...',
            okText: 'Delete',
            cancelText: 'Cancel',
            okType: 'danger',
            onOk: async () => {
                return new Promise(async (resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    await userCompany.deleteUserCompanyMany({"userCompanyIds": getKey});
                    // sendEvent('UserCompanies Bulk edit Delete', 'Delete UserCompanies' ,'' , 1);
                    refetch();
                }).catch(() => {
                    refetch();
                });
            },
        });
    };
    return (
        <div className='mainPage'>
            {contextHolder}
            <span style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                {isModalVisible &&
                    <AddMultiUserModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}
                                       userId={driverId}
                                       companyId={companyId} refetch={refetch} id={id} getCMPname={getCMPname}/>}
                <SearchOptions SearchResult={(query: string) => SearchForUserCompanyDR(query, id)}
                               onSelect={onSelectDriver} placeholder="Search by username" value={''}/>
                 <SearchOptions SearchResult={(query: string) => SearchForUserCompanyEM(query, id)}
                                onSelect={onSelectDriver} placeholder="Search by email" value={''}/>

                {!id && <SearchOptions SearchResult={SearchResultForCompany}
                                       onSelect={(value: any, {valId}: { valId: number | undefined }) => {
                                           setCompanyId(valId);
                                           if (valId) {
                                               setSkip(1)
                                           }
                                       }} placeholder={'Search by Company '} value={''}/>}
                <Select defaultValue="all" onChange={(value, option) => {
                    setRole(value === 'all' ? undefined : value)
                    if (value) {
                        setSkip(1)
                    }
                }} style={{width: 200,}}>
                <Option key={'all'}>All Users</Option>
                    {roleList.map((u: any, index: any) => <Option key={index} value={u.value}
                                                                  title={u.title}>{u.label}</Option>)}
            </Select>
            <Button type="primary" style={{marginLeft: 'auto'}} size={'large'} onClick={showModal}
            >Add User</Button>
                    <Button size={'large'} style={{marginLeft: '15px'}} onClick={refetch}>Refresh</Button>
                {isModalBulk &&
                    <Button style={{marginLeft: '15px'}} type="primary" size={'large'} onClick={confirm}>Delete
                        All</Button>
                }
            </span>
            <UserCompaniesTable userCompanies={data?.data} total={data?.count} onChange={onChange} isLoading={isLoading}
                                isFetching={isFetching} open={open} setOpen={setOpen} setIsModalBulk={setIsModalBulk}
                                refetch={refetch} skip={skip} setGetKey={setGetKey}/>
        </div>
    )
};

export default UserCompanies;
