import {useQuery} from "react-query";
import {makesList, modelList} from "../../../API/TT_ELD/Users/make_moddel";

type Data = {
    data?: {
        data: Array<any>,
        count: number
    }
    refetch(): void
}
export const useMakes = (makeName: string | number | undefined, skip: string | number): object => {
    return useQuery([
            `makes/${makeName}`, makeName, skip],
        () => makesList.read(makeName, skip),
        {refetchOnWindowFocus: false})
}


export const useModel = (modelName: string | number | undefined, makeId: string | number | undefined, skip: string | number): object => {
    return useQuery([
            `models`, modelName, makeId, skip],
        () => modelList.read(modelName, makeId, skip),
        {refetchOnWindowFocus: false}
    )

}

export const useModelFindOne = (id: number | string | undefined): object => {
    return useQuery(
        [`modelFindOne/${id || "all"}`, id],
        () => modelList.modelFindOne(id),
        {refetchOnWindowFocus: false}
    );
};


export const useMakeFindOne = (id: number | string | undefined): Data => {
    return useQuery(
        [`makeFindOne/${id || "all"}`, id],
        () => makesList.makeFindOne(id),
        {refetchOnWindowFocus: false}
    );
};

export const useMakeSelect = (): object => {
    return useQuery(
        [`makeSelect/"all"`,],
        () => makesList.makeSelect(),
        {refetchOnWindowFocus: false}
    );
};

export const useModelSelect = (makeId: string | number | undefined,): object => {
    return useQuery(
        [`modelSelect/"all"`, makeId],
        () => modelList.modelSelect(makeId),
        {refetchOnWindowFocus: false}
    );
};

export const useModelFinderName = async (name: any) => {
    return await modelList.modelsFinder(name)
}