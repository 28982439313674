import React from 'react';
import {makesList} from "../../../../../../API/TT_ELD/Users/make_moddel";
import {Form as FormAnt, Input, Modal} from "antd";


const AddMakeList = ({
                         open,
                         setOpen,
                         refetch
                     }: { open: boolean, setOpen(open: boolean): void, refetch(): void }) => {

    const handleCancel = () => {
        setOpen(!open)
    }
    const [form] = FormAnt.useForm();
    return (
        <>
            <Modal
                open={open}
                title="Create a new make"
                okText="Create"
                cancelText="Cancel"
                onCancel={handleCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then(async (values) => {
                            form.resetFields();
                            await makesList.addMakesList(values)
                            setOpen(!open)
                            refetch()
                        })
                        .catch((info) => {
                            refetch()
                        });
                }}
            >
                <FormAnt
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{modifier: 'public'}}
                >
                    <FormAnt.Item
                        name="name"
                        label="Name"
                        rules={[{required: true, message: 'Please enter your name!'}]}
                    >
                        <Input/>
                    </FormAnt.Item>
                </FormAnt>
            </Modal>
        </>
    );
};

export default AddMakeList;