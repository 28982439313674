import {useQuery} from "react-query";
import {reportController} from "../../../API/Tms/report";

export const useReportData = ( skip : string|number, date: string| number | any): object => {
    return useQuery(
        [
            `admin/report${ skip || 'all'}`,skip , date,
        ],
        () => reportController.read(skip, date),
        {refetchOnWindowFocus: false}
    );
};