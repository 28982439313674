import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {Button, Card, Col, Input, Row, Space, Spin, Switch, Tabs} from "antd";
import {Field, Form} from "react-final-form";
import {AppleOutlined} from "@ant-design/icons";
import {contactController} from "../../../../../API/Tms/contact";
import {useContactOneData} from "../../../../../Hooks/cleverTms/Contact";

const TabPane = Tabs.TabPane;

type Data = {
    data?: {
        data: Array<any>,
        count: number
    }
}
type params = {
    readonly id: any
}
type numStr = number | string;

interface Fields {
    fullName: numStr,
    updatedAt: numStr,
    createdAt: numStr,
    companyId: numStr,
    isActive: boolean,
    phone: numStr,
    email: numStr,
    brokerCompanyId: numStr,
    customerId: numStr

}

const ContactEdit = () => {

    const [companyData, setCompanyData] = useState<{ data: Array<Fields>, count: number }>();
    const {id} = useParams<params>();
    const {data}: Data = useContactOneData(id)
    let navigate = useNavigate();


    const contactInputs = [
        {label:"Full name",name:"fullName"},
        {label:"Phone number",name:"phone"},
        {label:"Email",name:"email"},
        {label:"Broker company ID",name:"brokerCompanyId"},
        {label:"Customer ID",name:"customerId"},
    ]
    useEffect(() => {
        if (data) {
            setCompanyData(data)
        }
    }, [data])

    const onSubmit = async (value: any) => {
        await contactController.contactPatch(value)
        navigate(-1)
    };
    return (
        <div className='mainPage'>
            <Card>
                <Spin size="large" spinning={!data}>
                    {companyData && <Form onSubmit={onSubmit}
                                          initialValues={{...companyData,}}
                                          render={({handleSubmit, values}) => (
                                              <form onSubmit={handleSubmit}>
                                                  <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                                      <Tabs defaultActiveKey="1">
                                                          <TabPane
                                                              tab={<span>MAIN FIELDS</span>}
                                                              key="1"
                                                              icon={<AppleOutlined rev={undefined}/>}
                                                          >
                                                              <Space direction="vertical" size="middle"
                                                                     style={{display: 'flex',}}>
                                                                  <Row gutter={[16, 20]}>

                                                                      {contactInputs.map(u => {
                                                                          return <Col className="gutter-row" span={6}>
                                                                              <label>{u.label}: </label>
                                                                              <Field
                                                                                  name={u.name}
                                                                                  render={({input}: { input: any }) => (
                                                                                      <Input placeholder={u.label}
                                                                                             type="text" {...input} />
                                                                                  )}
                                                                              />
                                                                          </Col>
                                                                      })}

                                                                      <Col className="gutter-row" span={6}>
                                                                          <label>Company ID: </label>
                                                                          <Field
                                                                              name='companyId'
                                                                              render={({input}: { input: any }) => (
                                                                                  <Input placeholder='Company ID'
                                                                                         type="number" {...input} />
                                                                              )}
                                                                          />
                                                                      </Col>

                                                                  </Row>
                                                                  <div>
                                                                      <label>Is active: </label>
                                                                      <Field
                                                                          name="isActive"
                                                                          render={({input}: { input: any }) => (
                                                                              <Switch defaultChecked={input.value}
                                                                                      onChange={input.onChange}/>
                                                                          )}
                                                                      />
                                                                  </div>
                                                                  <div className="buttons">
                                                                      <Button type="primary" htmlType="submit">
                                                                          Submit
                                                                      </Button>
                                                                  </div>
                                                              </Space>
                                                          </TabPane>
                                                      </Tabs>
                                                  </Space>
                                              </form>
                                          )
                                          }
                    />
                    }
                </Spin>
            </Card>
        </div>
    )
}

export default ContactEdit
