import { useQuery } from "react-query";
import { cityController } from "../../../API/Tms/cityController";

export const useCityData = (city: any): object => {
  return useQuery(
    [`admin/city-controller/${city}`, city],
    () => cityController.read(city),
    { refetchOnWindowFocus: false }
  );
};

export const useCityOneData = (companyId: number | string | undefined): any => {
  return useQuery(
    [`admin/city-controllerOne/${companyId || "all"}`, companyId],
    () => cityController.cityCompanyOne(companyId),
    { refetchOnWindowFocus: false }
  );
};
export const useFindCity = async (query :any) => {
  return await cityController.companyFinderId(query)
}