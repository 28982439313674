import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {cityController} from "../../../../../API/Tms/cityController";
import {Button, Card, Col, Input, Row, Space, Spin, Switch, Tabs} from "antd";
import {Field, Form} from "react-final-form";
import {AppleOutlined} from "@ant-design/icons";
import {useCityOneData} from "../../../../../Hooks/cleverTms/CitiyController";

const TabPane = Tabs.TabPane;

type Data = {
    data?: {
        data: Array<any>,
        count: number
    }
}
type params = {
    readonly id: any
}

interface Fields {
    id: string,
    city: string;
    state: string;
    lat: number;
    lng: number;
    zips: string;
    timezone: string;
    updatedAt: string,
    createdAt: string,
    isActive: boolean;
    action: string;
}

const CityControllerEdit = () => {

    const [companyData, setCompanyData] = useState<{ data: Array<Fields>, count: number }>();
    const {id} = useParams<params>();
    const {data}: Data = useCityOneData(id)
    let navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setCompanyData(data)
        }
    }, [data])

    const onSubmit = async (value: any) => {
        await cityController.cityPatch(value)
        navigate(-1)
    };
    const cityInputs = [
        {label: "City", name: 'city'},
        {label: "State", name: 'state'},
        {label: "ZIP", name: 'zips'},
        {label: "Timezone", name: 'timezone'},
    ]

    const cityNumber = [
        {label: "Latitude", name: 'lat'},
        {label: "Longitude", name: 'lng'},
    ]

    return (
        <div className='mainPage'>
            <Card>
                <Spin size="large" spinning={!data}>
                    {companyData && <Form onSubmit={onSubmit}
                                          initialValues={{...companyData,}}
                                          render={({handleSubmit, values}) => (
                                              <form onSubmit={handleSubmit}>
                                                  <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                                      <Tabs defaultActiveKey="1">
                                                          <TabPane
                                                              tab={
                                                                  <span>

                                          MAIN FIELDS
                                           </span>
                                                              }
                                                              icon={<AppleOutlined rev={undefined}/>}
                                                              key="1"
                                                          >
                                                              <Space direction="vertical" size="middle"
                                                                     style={{display: 'flex',}}>
                                                                  <Row gutter={[16, 20]}>

                                                                      {cityInputs.map(u => {
                                                                          return <Col className="gutter-row" span={6}>
                                                                              <label>{u.label}: </label>
                                                                              <Field
                                                                                  name={u.name}
                                                                                  render={({input}: { input: any }) => (
                                                                                      <Input placeholder={u.label}
                                                                                             type="text" {...input} />
                                                                                  )}
                                                                              />
                                                                          </Col>
                                                                      })
                                                                      } {cityNumber.map(u => {
                                                                      return <Col className="gutter-row" span={6}>
                                                                          <label>{u.label}: </label>
                                                                          <Field
                                                                              name={u.name}
                                                                              render={({input}: { input: any }) => (
                                                                                  <Input placeholder={u.label}
                                                                                         type="number" {...input} />
                                                                              )}
                                                                          />
                                                                      </Col>
                                                                  })
                                                                  }

                                                                  </Row>
                                                                  <div>
                                                                      <label>isActive: </label>
                                                                      <Field
                                                                          name="isActive"
                                                                          render={({input}: { input: any }) => (
                                                                              <Switch defaultChecked={input.value}
                                                                                      onChange={input.onChange}/>
                                                                          )}
                                                                      />
                                                                  </div>
                                                                  <div className="buttons">
                                                                      <Button type="primary" htmlType="submit">
                                                                          Submit
                                                                      </Button>
                                                                  </div>
                                                              </Space>
                                                          </TabPane>
                                                      </Tabs>
                                                  </Space>
                                              </form>
                                          )
                                          }
                    />
                    }
                </Spin>
            </Card>
        </div>
    );
};

export default CityControllerEdit;