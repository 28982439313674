import React, {useState} from 'react';

import { Table} from 'antd';
import {Col, Spin} from 'antd';
import {Link ,useLocation} from "react-router-dom";
import moment from "moment";
import {useSupporFiltertData} from "../../../../../../Hooks/TT_ELD/Supports";
import SupportTable from "./SupportTable";
import {SearchResultForCompany} from "../../../../../../Utils/SearchResults";
import SearchOptions from "../../../../../../Utils/SearchOptions";
import {calcOrder} from "../../../../../components/core/calcOrder";


type Data = {
    data?: {
        data: Array<any>,
        count: number
    };
    isLoading?: any,
    isFetching?:any
    refetch?: any,
}
type numStr = number | string | null;

interface supportSource {
    // no: numStr;
    name: { no: number, id: numStr, name: numStr, updatedAt: numStr };
    // isActive: boolean;
    // status: string;
    // origin: string;
    // action: string;
    key: React.Key;

}

const columns: object[] = [
    // {
    //     title: 'no',
    //     dataIndex: 'no',
    //     key: 'no',
    // },
    {
        title: 'Company',
        dataIndex: 'name',
        key: 'name',
        render: (val: { no: number, id: number | string, name: string, updatedAt: string | number, }) => (
            <Link to={`${val.id}`} >
                {val.no} &nbsp; &nbsp; {val.name} &nbsp; &nbsp;  {val.updatedAt}
            </Link>
        )
    },
]
const SupportLayout = ({company = [] , isFetchingCMP, isLoadingCMP ,onChangeCMP, total, skip2} :{company :Array<any>, isFetchingCMP:boolean, isLoadingCMP:boolean, onChangeCMP(current: any): void, total:number, skip2:string |any}) => {
    const location = useLocation();
    let topErrors = location.pathname === '/errors/top-errors'
    const [companyId, setCompanyId] = useState<any>();
    const [skip, setSkip] = useState(1);
    const [innerRelation, setInnerRelation] = useState<any>();
    const {data, isFetching, isLoading}: Data = useSupporFiltertData(companyId, innerRelation, skip);

    const onChang = (query: any) => {
        setSkip(1 * (parseInt(query.current)));
    }

    return (
        <>
            <SearchOptions SearchResult={SearchResultForCompany}
                           onSelect={(value: any, {valId}: { valId: number | undefined }) => {setCompanyId(valId)}} placeholder={'History Search'} value={''}/>
            <div style={{margin: '24px 0px 0 0', display: 'flex'}}>
                <Col span={7} style={{boxShadow: 'rgba(0, 0, 0, 0.18) 0px 2px 4px'}}>
                    <Spin size='large' spinning={isFetchingCMP|| isLoadingCMP }>
                        <Table size='small' onChange={onChangeCMP}
                               dataSource={company?.map((u: any, i:number): supportSource => {
                                   let checked_date = u?.checked_date ? u.checked_date : ''
                                   const obj: supportSource = {
                                       name: {
                                           no: calcOrder(skip2, 100, i),
                                           id: topErrors ?  u.companyId : u.id ,
                                           name: topErrors ?  u?.company?.name : u.name ,
                                           updatedAt: topErrors ? null : moment(checked_date).format("YYYY-MM-DD, h:mm:ss a")
                                       },
                                       key: u.id
                                   }
                                   return obj
                               })} columns={columns} pagination={{total: total, pageSize: 100 ,showSizeChanger: false,  current:parseInt(skip2)}} scroll={{y: 640}}/>
                    </Spin>
                </Col>
                <div className="site-layout-background" style={{margin: '0px 0px 0 20px', textAlign: 'center'}}>
                    {
                        <SupportTable support={data?.data} total={data?.count} onChange={onChang} isLoading={isLoading} isFetching={isFetching} skip={skip}/>
                    }
                </div>
            </div>

        </>
    )
}

export default SupportLayout;
