import React from 'react';
import {Spin, Table, Tag} from "antd";

type numStr = string | number

interface approvalSource {
    no: numStr,
    id: numStr,
    driverName: number
    code: number | string
    date: string
    due: numStr
    stopStatus: numStr
    total: numStr
    status: numStr,
    createdAt: numStr,
    isActive: boolean,
    key: React.Key;
}

const columns: object[] = [
    {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        sorter: {
            compare: (a: any, b: any) => a.no - b.no,
            multiple: 1,
        },
    },
    {
        title: 'Driver',
        dataIndex: 'driverName',
        key: 'driverName',

    },
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',

    },
    {
        title: 'Due',
        dataIndex: 'due',
        key: 'due',

    },

    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',

    },
    {
        title: 'Is Active',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (tag: boolean) => (
            <Tag color={tag ? 'geekblue' : 'red'}>
                {tag ? 'True' : 'False'}
            </Tag>
        ),
        filters: [
            {
                text: 'True',
                value: true,
            },
            {
                text: 'False',
                value: false,
            },

        ],
        onFilter: (value: any, record: any) => {
            return record.isActive === value
        },
    },{
        title: 'Status',
        dataIndex: 'status',
        key: 'status',

    }, {
        title: 'Created at',
        dataIndex: 'createdAt',
        key: 'createdAt',

    },

]
const SalaryListTable = ({data = [], onChange, total = 0, isLoading, isFetching}: {

    data: Array<any>, onChange(current: any): void, total: number | undefined, isLoading: any, isFetching: any,
}) => {
    return (
        <div>
            <Spin size='large' spinning={isLoading || isFetching}>
                <Table style={{margin:'15px 0 0 0'}} onChange={onChange} dataSource={data?.map((u: any, i: number): approvalSource => {
                    let create = u?.createdAt
                    const obj: approvalSource = {
                        no: i + 1,
                        id: u.id,
                        driverName: u?.driverName,
                        code: u?.code,
                        date: u?.date,
                        total: u.total,
                        due: u?.due,
                        stopStatus: u?.load?.stopStatus,
                        status: u.status,
                        createdAt: u?.createdAt,
                        isActive: u?.isActive,
                        key: u.id
                    }
                    return obj
                })} columns={columns} pagination={{total: total, pageSizeOptions: [10, 50, 100]}}

                />
            </Spin>
        </div>
    );
};

export default SalaryListTable;