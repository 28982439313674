import {useQuery} from "react-query";
import {userCompany} from "../../../API/TT_ELD/userCompany";

export const useUserCompaniesData = (id:number | string, companyId: undefined| number| string, role: undefined | string, driverId: undefined | string | number, skip:string | undefined | number = 0): object => {
    return useQuery([
            `userCompanies/${id}`,id, companyId, role, driverId, skip],
        () => userCompany.read(id , companyId, role, driverId, skip),
        { refetchOnWindowFocus: false})
}


export const useUserData = (userId: number | string | undefined): object => {
    return useQuery([
            `userCompaniesData/${userId || 'all'}`, userId],
        () => userCompany.userCompaniesData(userId),
        { refetchOnWindowFocus: false})
}
export const useGetRole = (): object => {
    return useQuery([
            `userGetRole/`,],
        () => userCompany.getRole(),
        { refetchOnWindowFocus: false})
}



