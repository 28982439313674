import {useQuery} from "react-query";
import {brokerController} from "../../../API/Tms/brokerController";

export const useBrokerData = (id: string | number | undefined, companyId: undefined | number): object => {
    return useQuery([
            `admin/broker-companies/${id}`,id,companyId],
        () => brokerController.read(id,companyId),
        { refetchOnWindowFocus: false })
}

export const useBrokerOneData = (companyId: number | string | undefined): any => {
    return useQuery([
            `admin/broker-companies/${companyId || 'all'}`, companyId],
        () => brokerController.brokerCompanyOne(companyId),
        { refetchOnWindowFocus: false })
}

export const useBrokerFindCompany = async (query :any) => {
    return await brokerController.companyFinderId(query)
}

// export const useBrokerFindCompany = (companyId: number | string | undefined): any => {
//     return useQuery([
//             `admin/broker-companiesFind/${companyId || 'all'}`, companyId],
//         () => brokerController.companyFinderId(companyId),
//         { refetchOnWindowFocus: false, staleTime: 5 * 60 * 1000 })
// }