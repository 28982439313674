import React from 'react';
import {Spin, Table, Tag} from "antd";


type numStr = string | number

interface reportSource {
    no: numStr
    companyId: numStr,
    count: numStr
    email: numStr
    name: numStr
    key: React.Key;

}

const columns: object[] = [
    {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        sorter: {
            compare: (a: any, b: any) => a.no - b.no,
            multiple: 1,
        },
    },
    {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },

    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },

    {
        title: 'Company ID',
        dataIndex: 'companyId',
        key: 'companyId',

    },

]
const ReportTable = ({
                         report = [],
                         isLoading,
                         onChange,
                         total = 0
                     }: { report: Array<any> | undefined, isLoading: boolean | undefined, onChange(current: any): void, total: number | undefined }) => {

    return (
        <div>
            <Spin size='large' spinning={isLoading}>
                <Table style={{margin:'15px 0 0 0'}} onChange={onChange} dataSource={report?.map((u: any, i: number): reportSource => {
                    const obj: reportSource = {
                        no: i + 1,
                        name: u?.name,
                        companyId: u?.companyId,
                        count: u?.count,
                        email: u?.email,
                        key: i
                    }
                    return obj
                })}
                       columns={columns} pagination={{total: total, showSizeChanger: false}}
                />
            </Spin>
        </div>
    );
};

export default ReportTable;