import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import './App.css'
import App from './Component/App';
// import App from './App'
import {QueryClient, QueryClientProvider,} from 'react-query'
import {Provider} from 'react-redux';
import './Component/styles/index.less';
import './Component/mock';
import store from "./Component/stores";
import {BrowserRouter} from "react-router-dom";
import ReactGA from "react-ga4";
const queryClient = new QueryClient()
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
ReactGA.initialize('G-538K6M91NT');

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <App/>
                </Provider>
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>
);

