import React from 'react';
import {Field, Form} from "react-final-form";
import {Button, Card, Col, Input, Row, Space, Switch} from "antd";
import {cityController} from "../../../../../API/Tms/cityController";
import {useNavigate} from "react-router-dom";

const AddCityController = () => {

    const cityInputs = [
        {label:"City", name:'city'},
        {label:"State", name:'state'},
        {label:"ZIP", name:'zips'},
        {label:"Timezone", name:'timezone'},
    ]

    const cityNumber = [
        {label:"Lat", name:'lat'},
        {label:"Lng", name:'lng'},
    ]
    type Fields = {
        city: string;
        state: string;
        lat: number;
        lng: number;
        zips: string;
        timezone: string;
        isActive: boolean;
        key: React.Key;
    }
    let navigate = useNavigate();

    const onSubmit = async (value: Fields) => {
        await cityController.addCityController(value)
        navigate(-1)
    };
    const required = (value: any) => (value ? undefined : 'Required')

    return (
        <div className='mainPage'>
            <Card>
                {<Form onSubmit={onSubmit}
                       render={({handleSubmit, values}) => (
                           <form onSubmit={handleSubmit}>
                               <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                   <h1>Add City</h1>
                                   <Space direction="vertical" size="middle"
                                          style={{display: 'flex',}}>
                                       <Row gutter={[16, 20]}>

                                           {cityInputs.map(u => {
                                               return <Col className="gutter-row" span={6}>
                                                   <label>{u.label}: </label>
                                                   <Field validate={required}
                                                          name={u.name}
                                                          render={({input, meta}: { input: any, meta: any }) => (
                                                              <div>
                                                                  <Input {...input} type='text' placeholder={u.label}/>
                                                                  {meta.touched && meta.error &&
                                                                      <div style={{color: 'red'}}>{meta.error}</div>}
                                                              </div>
                                                          )}
                                                   ></Field>
                                               </Col>
                                           })}

                                           {cityNumber.map(u => {
                                               return <Col className="gutter-row" span={6}>
                                                   <label>{u.label}: </label>
                                                   <Field validate={required}
                                                          name={u.name}
                                                          render={({input, meta}: { input: any, meta: any }) => (
                                                              <div>
                                                                  <Input {...input} type='number' placeholder={u.label}/>
                                                                  {meta.touched && meta.error &&
                                                                      <div style={{color: 'red'}}>{meta.error}</div>}
                                                              </div>
                                                          )}
                                                   ></Field>
                                               </Col>
                                           })
                                           }

                                       </Row>
                                       <div>
                                           <label>Is Active: </label>
                                           <Field
                                               name="isActive"
                                               render={({input}: { input: any }) => (
                                                   <Switch defaultChecked={input.value} onChange={input.onChange}/>
                                               )}
                                           />
                                       </div>
                                       <div className="buttons">
                                           <Button type="primary" htmlType="submit">
                                               Submit
                                           </Button>
                                       </div>
                                   </Space>
                               </Space>
                           </form>
                       )
                       }
                />
                }
            </Card>
        </div>
    );
};

export default AddCityController;