import {useQuery} from "react-query";
import { categoryController } from "../../../API/Tms/category";

export const useCategoryData = (name: any,  companyId: undefined | number |string
): object => {
    return useQuery([`admin/categories${name}`, name, companyId,],
        () => categoryController.read(name, companyId),
        {refetchOnWindowFocus: false}
    );
};

export const useCategoryOneData = (companyId: number | string | undefined): any => {
    return useQuery(
        [`admin/categoriesOne/${companyId || "all"}`, companyId],
        () => categoryController.categoryCompanyOne(companyId),
        {refetchOnWindowFocus: false}
    );
};

export const useFindCategory = async (query :any) => {
    return await categoryController.categoryFinderId(query)
}