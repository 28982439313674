import React, { useEffect, useRef } from "react";
import { useApprovalCount } from "../../../../Hooks/TT_ELD/Approval";
import { Badge, notification } from "antd";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

type Data = {
  data?: {
    data: any;
    count: any;
  };
  status: boolean | string;
};

const ApprovalCount = () => {
  const { data, status }: Data = useApprovalCount();
  const userCount = localStorage.getItem("user_count");
  const notificationRef = useRef<ReturnType<typeof notification.info>>();

  const showNotification = () => {
    notificationRef.current = notification.info({
      message: "New user!",
      description: "Please check the Approval page",
      duration: 4, // Установите значение 0 для того, чтобы уведомление не исчезало автоматически
      onClick: () => {
      },
    });

    // const audio = new Audio("/notif.mp3");
    // audio.addEventListener("canplaythrough", () => {
    //   // audio.play();
    // });
  };

  useEffect(() => {
    if (userCount !== undefined) {
      if (data?.count && data?.count !== 0 && data?.count > Number(userCount)) {
        showNotification();
        localStorage.setItem("user_count", `${data?.count}`);
      }
    } else {
      localStorage.setItem("user_count", `${data?.count}`);
    }
  }, [data?.count]);

  const padding = data?.count >= 10 ? "0 2.5px" : "";
  const marginRight = data?.count >= 10 ? "8px" : "10px";
  const antIcon = (
      <LoadingOutlined style={{fontSize: 15, marginRight: "9px"}} spin rev={undefined} />
  );

  return (
      <>
        {status === "loading" ? (
            <Spin indicator={antIcon} />
        ) : data?.count !== 0 ? (
            <Badge
                style={{ marginRight: marginRight, padding: padding }}
                size="small"
                count={data?.count}
            />
        ) : (
            <SafetyCertificateOutlined rev={undefined} />
        )}
      </>
  );
};

export default ApprovalCount;
