import React, { useState} from 'react';
import {Button, Space, Modal, Input, Card} from "antd";
import {users} from "../../../../../../API/TT_ELD/Users/user";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Letter from "./Letter";
import {useParams} from "react-router-dom";
import {useUserData} from "../../../../../../Hooks/TT_ELD/Users";
import {sendEvent} from "../../../../../../Utils/analytics";

const { confirm } = Modal;
interface Params {
    id: string | undefined;

    [key: string]: string | undefined;
}

type Data = {
    data: any
    isLoading?: boolean,
    refetch(): void,
}
const Commands = () => {
    const {id} = useParams<Params>();

    const {data, isLoading, refetch}: Data = useUserData(id);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newPassword, setNewPassword] = useState<string | number>('');
    const getCommand = async (command: string) => {
        await users.command(data.id, command)
    }
    const showPromiseConfirm = (command: string) => {
        confirm({
            title: 'Command Confirmation',
            icon: <ExclamationCircleOutlined rev={undefined} />,
            content: 'Are you sure you want to run this command?',
            async onOk() {
                await getCommand(command);
                sendEvent('Users page', `Commands Tab ${command}` , `Commands Click ` ,1 )
            },
        });
    };
    const showPromiseConfirmKill = (command: string | undefined) => {
        confirm({
            title: 'Command Confirmation',
            icon: <ExclamationCircleOutlined rev={undefined} />,
            content: 'Are you sure you want to kill this Token?',
            async onOk() {
                await users.killToken(command);
                sendEvent('Users page', `Commands Tab ${command}` , `Commands Click ` ,1 )
            },
        });
    };


    const showModal = () => {
        setIsModalVisible(true);
        sendEvent('Users page', 'show Modal' , `Commands Litter ` ,1 )
    };
    const handleOk = async () => {
        await users.changeUserPassword(newPassword, data.id)
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const [open, setOpen] = useState(false);
    const showModals = () => {
        setOpen(!open)
    };
    return (
        <Card>
            <Modal title="Basic Modal" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Input type={'password'} placeholder={'New Password.tsx'} onChange={(e) => setNewPassword(e.currentTarget.value)} />
            </Modal>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                {data.role === "driver" &&
                    <Space direction="horizontal" size="middle" style={{display: 'flex'}}>
                        <Button type="primary" style={{backgroundColor: 'rgb(254, 149, 38)', borderColor: 'rgb(254, 149, 38)', width: '100%'}} onClick={() => showPromiseConfirm('reset_eld')}>RESET ELD</Button>
                        <Button type="primary" style={{backgroundColor: 'rgb(255, 203, 47)', borderColor: 'rgb(255, 203, 47)', width: '100%'}} onClick={() => showPromiseConfirm('disconnect_eld')}>DISCONNECT ELD</Button>
                        <Button type="primary" style={{backgroundColor: 'rgb(83, 216, 106)', borderColor: 'rgb(83, 216, 106)', width: '100%'}} onClick={() => showPromiseConfirm('restart_bluetooth')}>RESTART BLUETOOTH</Button>
                        <Button type="primary" style={{backgroundColor: 'rgb(253, 61, 57)', borderColor: 'rgb(253, 61, 57)', width: '100%'}} onClick={() => showPromiseConfirm('clear_database')}>CLEAR DATABASE</Button>
                        <Button title={'to turn off the check engine'} type="primary"  style={{backgroundColor: 'rgb(182,11,11)', borderColor: 'rgb(182,11,11)', width: '100%'}} onClick={() => showPromiseConfirm('clear_dtc')} >CLEAR DTC</Button>
                        <Button type="primary" onClick={() => showPromiseConfirm('rebirth')}>REBIRTH</Button>
                        <Button type="primary" style={{backgroundColor:'#404040' , borderColor:'#404040'}} onClick={() => showPromiseConfirmKill(id)}>KILL Token</Button>
                    </Space>
}

                <Space direction="horizontal" size="middle" style={{display: 'flex'}}>
                    {data.role === "driver" && <Button type="primary" onClick={() => showPromiseConfirm('update_user_info')}>UPDATE USER
                        INFO</Button>}
                    <Button type="primary" onClick={showModal}>CHANGE PASSWORD</Button>
                    {data.role === "driver"  && <Button type="primary" style={{
                        backgroundColor: 'rgb(89, 90, 212)',
                        borderColor: 'rgb(89, 90, 212)',
                        width: '100%'
                    }} onClick={showModals}>LETTER</Button>}

                    {/*<Button type="primary">LETTER</Button>*/}
                    {/*<Button type="primary">MALFUNCTION</Button>*/}
                </Space>
            </Space>
            <Letter open={open} setOpen={setOpen}/>

        </Card>
    )
};

export default Commands;