import React from 'react'
import {useNavigate} from "react-router-dom";
import {Button, Card, Col, Input, Row, Space, Switch} from "antd";
import {Field, Form} from "react-final-form";
import { categoryController } from '../../../../../API/Tms/category';
import {contactController} from "../../../../../API/Tms/contact";


type numStr = number | string;

interface Fields {
    no: numStr,
    name: numStr,
    type: numStr,
    updatedAt: numStr,
    createdAt: numStr,
    isActive: boolean;
    companyId: numStr
    action: string;

}
const contactInputs = [
    {label:"Full name",name:"fullName"},
    {label:"Phone number",name:"phone"},
    {label:"Email",name:"email"},
    {label:"Broker company ID",name:"brokerCompanyId"},
    {label:"Customer ID",name:"customerId"},
]
const AddContact = () => {
    let navigate = useNavigate();

    const onSubmit = async (value: Fields) => {
        await contactController.addContactController(value)
        navigate(-1)
    };


    const required = (value:any) => (value ? undefined : 'Required')

    return (
        <div className='mainPage'>{
            <Card>
                <Form onSubmit={onSubmit}
                      render={({handleSubmit, submitting}) => (
                          <form onSubmit={handleSubmit}>
                              <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                  <h1>Add Contact</h1>
                                  <Space direction="vertical" size="middle"
                                         style={{display: 'flex',}}>
                                      <Row gutter={[16, 20]}>

                                          {contactInputs.map(u => {
                                              return <Col className="gutter-row" span={6}>
                                                  <label>{u.label}: </label>
                                                  <Field validate={required}
                                                         name={u.name}
                                                         render={({input, meta}: { input: any , meta:any}) => (
                                                             <div>
                                                                 <Input {...input} type='text' placeholder={u.label}/>
                                                                 {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                             </div>
                                                         )}
                                                  ></Field>
                                              </Col>
                                          })
                                          }

                                          {/*<Col className="gutter-row" span={6}>*/}
                                          {/*    <label>Company ID: </label>*/}
                                          {/*    <Field  validate={required}*/}
                                          {/*            name='companyId'*/}
                                          {/*            render={({input , meta}: { input: any , meta:any}) => (*/}
                                          {/*                <div>*/}
                                          {/*                    <Input {...input} type='number' placeholder='Company ID'/>*/}
                                          {/*                    {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}*/}
                                          {/*                </div>*/}
                                          {/*            )}*/}
                                          {/*    ></Field>*/}
                                          {/*</Col>*/}

                                      </Row>
                                      <div>
                                          <label>Is active: </label>
                                          <Field
                                              name="isActive"
                                              render={({input}: { input: any }) => (
                                                  <Switch defaultChecked={input.value} onChange={input.onChange}/>
                                              )}
                                          />
                                      </div>
                                      <div className="buttons">
                                          <Button disabled={submitting} type="primary" htmlType="submit">
                                              Submit
                                          </Button>
                                      </div>
                                  </Space>
                              </Space>
                          </form>
                      )}/>
            </Card>
        }</div>
    )
}

export default AddContact