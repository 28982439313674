import React from 'react';
import {AppleOutlined, HistoryOutlined} from "@ant-design/icons";
import {Tabs} from "antd";
import DisabledCompanies from "./UnpaidCompaniesTabs/DisabledCompanies";
import Expires from "./UnpaidCompaniesTabs/Expires";

const UnpaidCompany = () => {
    const items = [
        {
            label: (<span>Subscription Expired</span>),
            key: '1',
            children: (<DisabledCompanies/>),
            icon: <AppleOutlined rev={undefined}/>
        },
        {
            label: (<span>Subscription Expires soon</span>),
            key: '2',
            children: (<Expires/>),
            icon: <HistoryOutlined rev={undefined}/>
        },
    ];
    return (
        <div className='mainPage'>
            <Tabs defaultActiveKey="1" items={items}/>
        </div>
    );
};

export default UnpaidCompany;