import moment from "moment-timezone";
import {ErrorLevel, ErrorType} from "./TTError";
import Event from './TTError'
import instance from "../../../../../../../API/api";
// import instance from "../../../../../../../API/api";

// export const times = (date:any) => {
//     const tz = getTz();
//     if (window.location.hash.includes('driver/')) {
//         let hash = window.location.hash.split('driver/')[1].split('/')[1];
//         const startDate = moment.tz(date || hash, 'MM-DD-YYYY', tz);
//         return { startDate, tz }
//     }
//     return { startDate: moment.tz(moment().utc(), tz), tz }
// }
export const getStatus = (log:any) => {
    if (log && log.event_code && log.event_type) {
        const code = +log.event_code;
        const type = +log.event_type
        if (type === 1 && code === 1) { return 'off'; }
        if (type === 1 && code === 2) { return 'sleep'; }
        if (type === 1 && code === 3) { return 'driving'; }
        if (type === 1 && code === 4) { return 'on'; }

        if (type === 2 && code === 1) { return 'intermediate'; }
        if (type === 2 && code === 2) { return 'intermediate'; }

        if (type === 3 && code === 1) { return 'personal'; }
        if (type === 3 && code === 2) { return 'yard'; }

        if (type === 5 && code === 1) { return 'login'; }
        if (type === 5 && code === 2) { return 'logout'; }

        if (type === 6 && code === 1) { return 'poweron'; }
        if (type === 6 && code === 2) { return 'poweron'; }
        if (type === 6 && code === 3) { return 'poweroff'; }
        if (type === 6 && code === 4) { return 'poweroff'; }

        if (type === 7) { return 'malfunction'; }
        if (type === 7 && code === 1) { return 'malfunction'; }
        if (type === 7 && code === 2) { return 'malfunction'; }

        if(type === 4 && code >= 1 && code <= 9) { return 'certify' }
    }
    return null;
}

export const getLogStatus = (log:any) => {
    if (log && log.event_code && log.event_type) {
        const code = +log.event_code;
        const type = +log.event_type
        if (type === 1 && code === 1) { log.status='off'; return {...log} }
        if (type === 1 && code === 2) { log.status='sleep'; return {...log} }
        if (type === 1 && code === 3) { log.status='driving'; return {...log} }
        if (type === 1 && code === 4) { log.status='on'; return {...log} }

        if (type === 2 && code === 1) { log.status='intermediate'; return {...log} }
        if (type === 2 && code === 2) { log.status='intermediate'; return {...log} }

        if (type === 3 && code === 1) { log.status='personal'; return {...log} }
        if (type === 3 && code === 2) { log.status='yard'; return {...log} }

        if (type === 5 && code === 1) { log.status='login'; return {...log} }
        if (type === 5 && code === 2) { log.status='logout'; return {...log} }

        if (type === 6 && code === 1) { log.status='poweron'; return {...log} }
        if (type === 6 && code === 2) { log.status='poweron'; return {...log} }
        if (type === 6 && code === 3) { log.status='poweroff'; return {...log} }
        if (type === 6 && code === 4) { log.status='poweroff'; return {...log} }

        if ((type === 7 && code === 2) || (type === 7 && code === 1)) { log.status='malfunction'; return {...log} }
        if ((type === 7 && code === 4) || (type === 7 && code === 3)) { log.status='diagnostic'; return {...log} }
        if(type === 4 && code >= 1 && code <= 9) { log.status='certify'; return {...log} }

        // if (type === 7 && code === 2) { log.status='malfunction'; return {...log} }
    }
    return log;
}

export const filterOffLogsForErrors = (LOGS=[], date:any, TZ:any) => {
    const timesForErrors = () => {
        let tz:any = TZ
        if (date) {
            const startDate = moment.tz(date, 'MM-DD-YYYY', tz);
            return { startDate, tz }
        }
        return { startDate: moment.tz(moment().utc(), tz), tz }
    }
    const { startDate, tz } = timesForErrors();
    const getLogSecForErrors = (date:any) => {
        const { startDate, tz } = timesForErrors();
        let lDate = moment.tz(date || moment(), tz);
        let secs;
        if (startDate.isSameOrBefore(lDate)) {
            secs = moment.duration(lDate.diff(startDate)).asSeconds();
        } else {
            secs = 0
        }
        return secs;
    }
    let timestmp = moment.tz(moment().utc(), tz);
    const endDate = moment.tz(startDate, tz).endOf('day');
    let logs:any = [];
    const today = moment.tz(tz);
    const isToday = startDate?.isSame(today, 'date');

    LOGS.forEach((el) => {
        let log:any = getLogStatus(el);
        log.start_date = log?.start_date && moment.tz(log?.start_date, tz).isSameOrAfter(startDate) ?
            moment(log?.start_date).toISOString() :
            moment(startDate).toISOString();
        log.end_date = log?.end_date && moment.tz(log?.end_date, tz).isSameOrBefore(endDate) ?
            moment(log?.end_date).toISOString() :
            (isToday ?
                    moment(timestmp).toISOString() :
                    endDate.toISOString()
            );
        log.start = getLogSecForErrors(log?.start_date);
        log.end = getLogSecForErrors(log?.end_date);

        logs.push(log);
    });

    logs.sort((a:any, b:any) => a?.start - b?.start )

    return logs
}

export const compareDriverWithCoDriver = (driverData:any, coDriver:any) => {
    let data:any = [];
    driverData?.forEach((driver:any) => {
        let num = data.length;
        if(driver.status === 'driving' || driver.status === 'on' || driver.status === 'yard') {
            for(let i = 0;i < coDriver.length;i++) {
                if((coDriver[i].status === 'driving' || coDriver[i].status === 'on' || coDriver[i].status === 'yard') && driver.start <= coDriver[i].end && driver.end >= coDriver[i].start) {
                    data.push(true);
                } else if((coDriver[i].status === 'driving' || coDriver[i].status === 'on' || coDriver[i].status === 'yard') && driver.start >= coDriver[i].start && driver.end >= coDriver[i].start && coDriver[i].end >= driver.end) {
                    data.push(true);
                } else if((coDriver[i].status === 'driving' || coDriver[i].status === 'on' || coDriver[i].status === 'yard') && coDriver[i].start <= driver.start && coDriver[i].end >= driver.end) {
                    data.push(true);
                } else if((coDriver[i].status === 'driving' || coDriver[i].status === 'on' || coDriver[i].status === 'yard') && coDriver[i].start >= driver.start && coDriver[i].end <= driver.end) {
                    data.push(true);
                }
            }
            if(num === data.length) {
                data.push(false);
            }
        } else {
            data.push(false)
        }
    });
    return data;
}

export const isDrNotInfoStatus = (st:any) => (
    st === 'driving' || st === 'personal' || st === 'yard'
)
export const isDrStatus = (st:any) => (
    st === 'driving' || st === 'personal' || st === 'yard' || st === 'intermediate'
)
export const isPowerStatus = (st:any) => (
    st === 'poweron' || st === 'poweroff'
)
export const getPower = (st:any) => (st === 'poweron' ? 'on' :
    st === 'poweroff' ? 'off' : undefined);
export const checkStatusChart = (st:any) => (
    st === 'on' || st === 'yard' ||
    st === 'driving' || st === 'sleep' ||
    st === 'off' || st === 'personal'
);

export const logDurationMins = (log:any) => {
    let start = moment.utc(log?.start_date);
    let end = moment.utc(log.end_date || moment());
    let dur = moment.duration(end.diff(start));
    return dur.asMinutes();
}

export const getDurationMins = (preLog:any, log:any) => {
    let start = moment.utc(preLog?.start_date);
    let end = moment.utc(
        log ? log?.start_date :
            preLog.end_date ? preLog.end_date :
                moment()
    );
    let dur = moment.duration(end.diff(start));

    return dur.asMinutes();
}

export const checkDistance:any = (log:any, pre:any) => {
    if( log && pre &&
        log.coordinates && log.coordinates.lat && log.coordinates.lng &&
        pre.coordinates && pre.coordinates.lat && pre.coordinates.lng
    ) {
        let distance = getDistance(log.coordinates, pre.coordinates);
        return distance;
    }
    else {
        return false;
    }
}

export const getDistance = function(p1:any, p2:any) {
    var R = 6378137;
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d * 0.0006213711922; // returns the distance in mile
};
const rad = function(x:any) {
    return x * Math.PI / 180;
};

export const getErrors = (logs:any ,codriver_logs:any ,malfunctions:any, driver:any ,startDate:any, tz:any) => {
    // const tzZone:any = times();
    // const { tz } = tzZone;
    // if(!startDate) {
    //     startDate = tzZone.startDate
    // }
    // let logs:any = dr_logs.logs;
    let driveExist:any;
    let ERRORS:any = [];
    let isDriving:boolean = false;
    let pwr:any = false;
    let firstPwr:any = false;
    let lastDuty:any = false;
    let isOffPc:any = false;
    let lastDutyForJump:any = undefined;
    let lastDutyForJumpLogin:any = undefined;
    let rs3 = false;
    let lastPowerLog:any
    let lastLogInOutCheck:any;
    let lastLogInOrOut = 'login';
    let powerLogExist: boolean
    let drStatusesExist :any;


    logs = logs?.filter(
        (u:any) => u.event_type !== Event.TYPE_MALFUNCTION && (driver ? driver?.id === u?.driverId : true),
    );
    if (codriver_logs) {
        // codriver_logs = codriver_logs?.filter((u:any) => u?.event_type !== Event.TYPE_MALFUNCTION);
    }
    let chartLogs = logs?.filter(
        (el:any) => checkStatusChart(getStatus(el)) || getStatus(el) === 'intermediate',
    );
    chartLogs?.forEach((log:any, i:any) => {
        if ((getStatus(log) === 'driving' || getStatus(log) === 'intermediate') && i) {
            if (chartLogs[i + 1]) {
                let diff = moment(chartLogs[i + 1].start_date).diff(moment(log?.start_date), 'minute');
                if (diff > 61) {
                    ERRORS.push({
                        log: { ...log },
                        next: { ...logs[i + 1] },
                        text: 'No intermediate',
                        type: ErrorType.intermediate,
                        level: ErrorLevel.danger,
                        driver: driver,
                    });
                }
            }
        }
    });
    // const isToday = () => {
    //     let today = moment.tz(tz).startOf('day');
    //     let date = moment.tz(startDate, tz).startOf('day');
    //     return date.isSame(today)
    // }
    const isSameDate = (date:any) => {
        let today = moment.tz(tz).startOf('day');
        let d1 = moment.tz(startDate, tz).startOf('day');
        let d2 = moment.tz(date, tz).startOf('day');
        if(d2.isSame(today)) {
            return false;
        }
        return d1.isSame(d2);
    }
    logs?.forEach((log:any, i:any) => {
        let time;
        let preTime;
        let prePwr = undefined;
        if (i > 0) {
            prePwr = logs[i - 1];
        }
        if (i > 1) {
            time = log;
            preTime = logs[i - 1];
        }
        let pre;
        let preTr;
        let tr = log.vehicleId;

        if (!rs3 && log.record_status === Event.CODE_ON_DUTY) {
            rs3 = true;
        }
        if((isSameDate(log.start_date) || isSameDate(log.end_date)) && !powerLogExist) {
            if(getPower(getStatus(log))) {
                powerLogExist = true;
            }
            if(isDrStatus(getStatus(log))) {
                drStatusesExist = log;
            }

            if(!powerLogExist && !isSameDate(logs[i + 1]?.start_date) && drStatusesExist && !log.codriverId && !codriver_logs) {
                ERRORS.push({
                    log: Object.assign({}, drStatusesExist),
                    text: 'Power log is not available this day.',
                    type: ErrorType.status,
                    level: ErrorLevel.danger,
                    driver: driver,
                });
            }
        }

        if (getStatus(lastPowerLog) === getStatus(log) && lastPowerLog?.driverId === log?.driverId) {
            ERRORS.push({
                log: Object.assign({}, log),
                text: 'Power log status duplication',
                type: ErrorType.duplication,
                level: ErrorLevel.danger,
                driver: driver,
            });
        }
        if (getPower(getStatus(log))) {
            lastPowerLog = log;
        }
        if(isDrStatus(getStatus(log)) && !driveExist) {
            driveExist = log;
        }
        const currentStatus = getStatus(log);
        if (currentStatus === 'login' || currentStatus === 'logout') {
            if (currentStatus === lastLogInOutCheck) {
                ERRORS.push({
                    log: { ...log },
                    text: `${currentStatus} duplicated`,
                    type: ErrorType.odometer,
                    level: ErrorLevel.danger,
                    driver: driver,
                });
            }
            lastLogInOrOut = currentStatus;
            lastLogInOutCheck = currentStatus;
        }
        // if(!lastPowerLog && logs.length - 1 === i && driveExist && !isToday() && !codriver_logs && !log?.codriverId) {
        //     ERRORS.push({
        //         log: { ...driveExist, start_date: moment(driveExist.start_date).add(1, 'day').startOf('day').toISOString() },
        //         text: "Power log is not available this day.",
        //         type: ErrorType.power,
        //         level: ErrorLevel.warning,
        //         driver: driver
        //     })
        // }
        if (lastLogInOrOut === 'logout' && log.event_type === 4) {
            ERRORS.push({
                log: { ...log },
                text: 'Certify after logout is wrong',
                type: ErrorType.certify,
                level: ErrorLevel.danger,
                driver: driver,
            });
        }
        if (getStatus(log) && getStatus(log) !== 'login') {
            if (i > 0) {
                pre = [...logs.slice(0, i)].reverse().find(l => l.event_type !== Event.TYPE_CERTIFICATION);
                preTr = pre && pre.vehicleId;
            }
            if (pre && getStatus(pre)) {
                let durMins = getDurationMins(pre, log);

                let odomDiff = log.odometr - pre.odometr;
                let engDiff = +log.engine_hours - +pre.engine_hours;

                if (
                    log.event_type !== Event.TYPE_CERTIFICATION &&
                    pre.event_type !== Event.TYPE_CERTIFICATION &&
                    tr === preTr
                ) {
                    if (engDiff < 0) {
                        ERRORS.push({
                            log: { ...log },
                            pre: { ...pre },
                            text: 'Engine-hours change incorrect',
                            type: ErrorType.engineHours,
                            level: ErrorLevel.warning,
                            driver: driver,
                        });
                    }

                    if (isDriving) {
                        if (
                            odomDiff === 2 &&
                            durMins > 30 &&
                            (lastDutyForJumpLogin === null || lastDutyForJumpLogin === void 0
                                ? void 0
                                : lastDutyForJumpLogin.event_type) !== 5
                        ) {
                            ERRORS.push({
                                log: { ...log },
                                pre: { ...pre },
                                text: 'Odometer non-change while driving',
                                type: ErrorType.odometer,
                                level: ErrorLevel.danger,
                                driver: driver,
                            });
                        }
                        if (odomDiff < 2 && durMins > 30) {
                            ERRORS.push({
                                log: { ...log },
                                text: 'Odometer change incorrect',
                                type: ErrorType.odometer,
                                level: ErrorLevel.danger,
                                driver: driver,
                            });
                        } else {
                            if (odomDiff < 0) {
                                ERRORS.push({
                                    log: { ...log },
                                    text: 'Odometer change incorrect',
                                    type: ErrorType.odometer,
                                    level: ErrorLevel.danger,
                                    driver: driver,
                                });
                            }
                        }
                    } else {
                        if (
                            (odomDiff > 2 || odomDiff < 0) &&
                            getStatus(pre) !== 'login' &&
                            driver?.id === log?.driverId
                        ) {
                            ERRORS.push({
                                log: { ...log },
                                pre: { ...pre },
                                text: 'Odometer change while not driving',
                                type: ErrorType.odometer,
                                level: ErrorLevel.danger,
                                driver: driver,
                            });
                        }
                        if (lastDuty && log.event_type === Event.TYPE_INTERMEDIATE) {
                            ERRORS.push({
                                log: { ...log },
                                text: 'Intermediate while not driving',
                                type: ErrorType.intermediate,
                                level: ErrorLevel.danger,
                                driver: driver,
                            });
                        }
                    }

                    if (pwr === 'on') {
                        if ((engDiff === 0 && durMins > 30) || (engDiff < 0.5 && durMins > 30)) {
                            pwr = undefined;
                            ERRORS.push({
                                log: { ...log },
                                pre: { ...pre },
                                text: 'Engine-hours non-change while power on',
                                type: ErrorType.engineHours,
                                level: ErrorLevel.warning,
                                driver: driver,
                            });
                        } else if (Math.abs(engDiff * 60 - durMins) > 30) {
                            ERRORS.push({
                                log: { ...log },
                                pre: { ...pre },
                                text: 'Engine-hours change incorrect',
                                type: ErrorType.engineHours,
                                level: ErrorLevel.warning,
                                driver: driver,
                            });
                        }
                    }
                    if (pwr === 'off' && firstPwr === 'on') {
                        if (engDiff > 2) {
                            if (odomDiff <= 2) {
                                pwr = undefined;
                            }
                            ERRORS.push({
                                log: { ...log },
                                text: 'Engine-hours change while power off',
                                type: ErrorType.engineHours,
                                level: ErrorLevel.warning,
                                driver: driver,
                            });
                        }
                        if (odomDiff > 2) {
                            pwr = undefined;
                            ERRORS.push({
                                log: { ...log },
                                pre: pre && { ...pre },
                                text: 'Odometer change while power off',
                                type: ErrorType.odometer,
                                level: ErrorLevel.warning,
                                driver: driver,
                            });
                        }
                    }
                }
            }

            if (getStatus(log) === 'certify') {
                if (log.certify_date) {
                    let cert_date = moment.utc(log.certify_date).startOf('day');
                    let l_date = moment.utc(log.start_date);
                    if (l_date.isSameOrBefore(cert_date)) {
                        ERRORS.push({
                            log: { ...log },
                            text: 'Certify date is incorrect',
                            type: ErrorType.certify,
                            level: ErrorLevel.danger,
                            driver: driver,
                        });
                    }
                }
                if (isDriving) {
                    ERRORS.push({
                        log: { ...log },
                        text: 'Certify log while driving',
                        type: ErrorType.certify,
                        level: ErrorLevel.danger,
                        driver: driver,
                    });
                }
            }

            if (checkStatusChart(getStatus(log))) {
                isDriving = isDrStatus(getStatus(log));
                if (
                    isDriving &&
                    pwr === 'off' &&
                    prePwr &&
                    (prePwr.event_code === Event.CODE_SHUT_DOWN_REDUCED ||
                        prePwr.event_code === Event.CODE_SHUT_DOWN_CONVENTIONAL) &&
                    time &&
                    preTime &&
                    time?.start_date === preTime?.start_date
                ) {
                    ERRORS.push({
                        log: { ...log },
                        text: 'Driving while power off',
                        type: ErrorType.driving,
                        level: ErrorLevel.danger,
                        driver: driver,
                    });
                }
                if (lastDuty && getStatus(lastDuty) === getStatus(log)) {
                    ERRORS.push({
                        log: { ...log },
                        text: 'Duty status duplication',
                        type: ErrorType.duplication,
                        level: ErrorLevel.danger,
                        driver: driver,
                    });
                }
                lastDuty = log;
            }

            if (isPowerStatus(getStatus(log))) {
                pwr = getPower(getStatus(log));
                if (!firstPwr) {
                    firstPwr = pwr;
                }
            }
        }

        if (log.address && log.address.trim() !== '') {
            let coors = log.coordinates;
            let lat = coors && coors.lat;
            let lng = coors && coors.lng;
            if (
                pre &&
                checkDistance(log, i - 1 > 0 ? pre : undefined) * 1.3 > 90 &&
                driver?.id === log?.driverId
            ) {
                let odometerChange = log.odometr - pre.odometr;
                const distance = checkDistance(log, i - 1 > 0 ? pre : undefined) * 1.3;
                if (
                    log.event_type !== 4 &&
                    pre.event_type !== 4 &&
                    distance - odometerChange > 50 &&
                    distance - odometerChange < 1000 &&
                    lastDutyForJumpLogin?.event_type !== 5
                ) {
                    ERRORS.push({
                        log: { ...log },
                        text: 'Location Jump',
                        type: ErrorType.coordinates,
                        level: ErrorLevel.danger,
                        driver: driver,
                    });
                }
            } else if (
                lastDutyForJump &&
                log.event_type !== 4 &&
                lastDutyForJump.vehicleId === log.vehicleId &&
                checkStatusChartForJump(getStatus(lastDutyForJump)) &&
                checkDistance(log, lastDutyForJump) * 1.3 > 15 &&
                checkDistance(log, lastDutyForJump) < 1000 &&
                driver?.id === log?.driverId &&
                getStatus(pre) !== 'login' &&
                // && !codriver_logs && !log.codriverId && !lastDutyForJump.codriverId
                log?.event_type !== 5 &&
                lastDutyForJumpLogin?.event_type !== 5
            ) {
                ERRORS.push({
                    log: { ...log },
                    text: 'Location Jump',
                    type: ErrorType.coordinates,
                    level: ErrorLevel.danger,
                    driver: driver,
                });
            }
            if (!lat || !lng || !coors) {
                ERRORS.push({
                    log: { ...log },
                    text: 'No coordinates',
                    type: ErrorType.coordinates,
                    level: ErrorLevel.danger,
                    driver: driver,
                });
            } else {
                if (lat && (lat < -90 || lat > 90)) {
                    ERRORS.push({
                        log: { ...log },
                        text: 'Lat is not in [-90,90] range',
                        type: ErrorType.coordinates,
                        level: ErrorLevel.danger,
                        driver: driver,
                    });
                }
                if (lng && (lng < -180 || lng > 180)) {
                    ERRORS.push({
                        log: { ...log },
                        text: 'Lng is not in [-180,180] range',
                        type: ErrorType.coordinates,
                        level: ErrorLevel.danger,
                        driver: driver,
                    });
                }
            }
        }

        if (!log.vehicleId) {
            ERRORS.push({
                log: { ...log },
                text: 'Vehicle not set',
                type: ErrorType.vehicle,
                level: ErrorLevel.danger,
                driver: driver,
            });
        }

        // if (
        //     log.vin_number &&
        //     (!validateVin(log.vin_number) || log.vin_number?.length < 17)
        // ) {
        //     ERRORS.push({
        //         log: Object.assign({}, log),
        //         text: "Not Valid VIN",
        //         type: ErrorType.vehicle,
        //         level: ErrorLevel.danger,
        //         driver: driver
        //     })
        // }

        if (getStatus(log) === 'driving' && logs[i + 1]) {
            let secondLog = logs[i + 1];
            let start = moment(log.start_date);
            let end = moment(log.end_date);
            let diff = end.diff(start);
            let difference = diff / 1000;
            if (
                difference > 600 &&
                log.coordinates &&
                secondLog &&
                secondLog.coordinates &&
                log.coordinates.lat === secondLog.coordinates.lat &&
                log.coordinates.lng === secondLog.coordinates.lng
            ) {
                ERRORS.push({
                    log: { ...log },
                    text: 'Error in the location',
                    type: ErrorType.location,
                    level: ErrorLevel.danger,
                    driver: driver,
                });
            }
        }
        if (log.event_code === 1 && log.event_type === 3) {
            isOffPc = true;
        } else if (isOffPc) {
            const logStatus = getStatus(log);
            if (logStatus === 'intermediate' || logStatus === 'poweron' || logStatus === 'poweroff') {
                if (log.event_code === 2 || log.event_code === 4) {
                    return;
                }
                const obj = {
                    poweron: {
                        incorrect: 'poweron (driving)',
                        correct: 'poweron (personal)',
                    },
                    poweroff: {
                        incorrect: 'poweroff (driving)',
                        correct: 'poweroff (personal)',
                    },
                    intermediate: {
                        incorrect: 'intermediate (driving)',
                        correct: 'intermediate (personal)',
                    },
                };
                ERRORS.push({
                    log: { ...log },
                    text: `The status does not match “Personal Conveyance”. \nIt should be “${obj[logStatus].correct}”, NOT “${obj[logStatus].incorrect}”`,
                    type: ErrorType.location,
                    level: ErrorLevel.danger,
                    driver: driver,
                });
            } else {
                isOffPc = false;
            }
        }
        if (codriver_logs) {
            let lastLoginLogout :any;
            codriver_logs?.forEach((u:any, j:any) => {
                if (getStatus(u) === 'login' || getStatus(u) === 'logout') {
                    lastLoginLogout = u;
                }
                if (
                    codriver_logs &&
                    getStatus(lastLoginLogout) === 'login' &&
                    (getStatus(u) === 'logout' || j === codriver_logs?.length - 1) &&
                    (getStatus(log) === 'login' || getStatus(log) === 'logout')
                ) {
                    if (
                        moment(log.start_date).isAfter(lastLoginLogout.start_date) &&
                        (moment(log.start_date).isBefore(u.start_date) || u.id === lastLoginLogout.id)
                    ) {
                        ERRORS.push({
                            log: { ...log },
                            text: `Conflict with login/logout`,
                            time:
                                ' ' +
                                moment(lastLoginLogout.start_date)?.tz(tz)?.format('MM-DD-YYYY hh:mm:ss A'),
                            type: ErrorType.odometer,
                            level: ErrorLevel.danger,
                            driver: driver,
                        });
                    }
                }
                if (
                    (getStatus(log) === 'login' || getStatus(log) === 'logout') &&
                    moment(u.start_date).isSame(log.start_date)
                ) {
                    ERRORS.push({
                        log: { ...log },
                        text: `Conflict with login/logout`,
                        time:
                            ' ' +
                            moment(u.start_date)
                                .tz(tz)
                                .format('MM-DD-YYYY hh:mm:ss A'),
                        type: ErrorType.odometer,
                        level: ErrorLevel.danger,
                        driver: driver,
                    });
                }
                if (
                    lastLoginLogout &&
                    getStatus(log) === 'login' &&
                    getStatus(u) === 'logout' &&
                    getStatus(lastLoginLogout) === 'login' &&
                    (moment(u.start_date).isAfter(log.start_date) ||
                        moment(u.start_date).isSame(log.start_date)) &&
                    (getStatus(lastLoginLogout) === 'logout' ||
                        moment(lastLoginLogout.start_date).isBefore(log.start_date) ||
                        moment(lastLoginLogout.start_date).isSame(log.start_date))
                ) {
                    ERRORS.push({
                        log: { ...log },
                        text: `Conflict with login/logout`,
                        time:
                            ' ' +
                            moment(u.start_date)
                                .tz(tz)
                                .format('MM-DD-YYYY hh:mm:ss A'),
                        type: ErrorType.odometer,
                        level: ErrorLevel.danger,
                        driver: driver,
                    });
                }
                if (
                    moment(log.start_date).isBefore(u.start_date) &&
                    log.odometr &&
                    u.odometr &&
                    u.odometr - log.odometr < -2 &&
                    log.vehicleId === u.vehicleId &&
                    log.event_type !== 4 &&
                    u.event_type !== 4
                ) {
                    if (!logs[i + 1] || !moment(logs[i + 1].start_date).isBefore(u.start_date)) {
                        ERRORS.push({
                            log: { ...log },
                            text: `Codriver odometer is wrong`,
                            time:
                                ' ' +
                                moment(u.start_date)
                                    .tz(tz)
                                    ?.format('MM-DD-YYYY hh:mm:ss A'),
                            type: ErrorType.odometer,
                            level: ErrorLevel.danger,
                            driver: driver,
                        });
                    }
                    return u;
                }
                if (
                    getStatus(log) === 'driving' &&
                    moment(u.start_date).isBefore(log.end_date) &&
                    moment(u.start_date).isAfter(log.start_date) &&
                    u?.driverId !== log?.driverId
                ) {
                    ERRORS.push({
                        log: { ...log },
                        text: `Conflict with codriver while driving `,
                        time:
                            ' ' +
                            moment(u.start_date)
                                .tz(tz)
                                .format('MM-DD-YYYY hh:mm:ss A'),
                        type: ErrorType.odometer,
                        level: ErrorLevel.danger,
                        driver: driver,
                    });
                }
                if (getStatus(u) === 'login' || getStatus(u) === 'logout') {
                    lastLoginLogout = u;
                }
                if (
                    getStatus(log) === 'certify' &&
                    getStatus(u) === 'driving' &&
                    moment(log.start_date).isBefore(u.end_date) &&
                    moment(log.start_date).isAfter(u.start_date) &&
                    u?.driverId !== log?.driverId
                ) {
                    ERRORS.push({
                        log: { ...log },
                        text: `Conflict with codriver while driving certify`,
                        time:
                            ' ' +
                            moment(u.start_date)
                                .tz(tz)
                                .format('MM-DD-YYYY hh:mm:ss A'),
                        type: ErrorType.certify,
                        level: ErrorLevel.warning,
                        driver: driver,
                    });
                }
            });
        }
        if (
            lastDutyForJump &&
            log.event_type !== 4 &&
            lastDutyForJump.vehicleId === log.vehicleId &&
            checkStatusChartForJump(getStatus(lastDutyForJump)) &&
            log.odometr - lastDutyForJump.odometr >= 5 &&
            log.odometr - lastDutyForJump.odometr < 1000 &&
            driver?.id === log?.driverId &&
            // !codriver_logs && !log.codriverId && !lastDutyForJump.codriverId &&
            log.event_type !== 5 &&
            lastDutyForJumpLogin?.event_type !== 5 &&
            getStatus(pre) !== 'login'
        ) {
            ERRORS.push({
                log: { ...log },
                pre: pre && { ...pre },
                text: 'Odometer Jump',
                type: ErrorType.odometer,
                level: ErrorLevel.danger,
                driver: driver,
            });
        }
        if (checkStatusChart(getStatus(log))) {
            lastDutyForJump = log;
        }
        if (checkStatusChart(getStatus(log)) || (log.event_type === 5 && codriver_logs)) {
            lastDutyForJumpLogin = log;
        }
    });
    if (malfunctions && malfunctions.length) {
        malfunctions.forEach((el:any) => {
            if (el?.driverId === logs[0]?.driverId && (el.event_code === 1 || el.event_code === 2)) {
                ERRORS.push({
                    log: { ...el },
                    text: el.event_code === 1 ? 'Malf. Start' : 'Malf. End',
                    type: ErrorType.duplication,
                    level: ErrorLevel.danger,
                    driver: driver,
                });
            }
        });
    }
    return ERRORS;
};
export const checkStatusChartForJump = (st:any) => (st === 'on' || st === 'yard' || st === 'sleep' ||
    st === 'off');

interface Driver {
    // Define the structure of the driver data
}

interface Violation {
    // Define the structure of the violation data
}

interface Timer {
    // Define the structure of the timer data
}

interface LogData {
    driver: Driver;
    violations: Violation[];
    timers: Timer;
    warnings: any[]; // Adjust the type as needed
}

interface GetAllLogsParams {
    startDate: string;
    tz: any;
    search: string;
}

// Adjust the type as needed
interface ProcessedLogData {
    id: string;
    driver: string;
    driverData: Driver;
    tracking: any; // Adjust the type as needed
    is_active: boolean;
    truckNo: string;
    status: string;
    lastPosition: {
        place: string;
        time: string;
    };
    lastLocation: {
        place: string;
        time: string;
    };
    violations: any[]; // Adjust the type as needed
    warnings: any[]; // Adjust the type as needed
    break: number;
    drive: number;
    shift: number;
    cycle: number;
    recap: number;
}

export const getAllLogs = async (
    key: any,
    { startDate, tz, search }: GetAllLogsParams,
    cursor = 1
): Promise<{ data: ProcessedLogData[]; total: number; cursor: number }> => {
    const newDate = moment.tz(startDate, tz).format('DD-MM-YYYY');
    const { data } = await instance.get(`logs/logslist?date=${newDate}&page=${cursor}&search=${search}`);
debugger
    let newData: ProcessedLogData[] = [];
    data.data.forEach(({ driver, violations, timers, warnings }: any, i: number) => {
        if (driver) {
            newData.push({
                id: driver.id,
                driver: driver.first_name + ' ' + driver.second_name,
                driverData: driver,
                tracking: driver.tracking,
                is_active: driver.is_active,
                truckNo: driver.vehicle && driver.vehicle.truck_number,
                status: driver.status && getStatus(driver.status), // Assuming you have a getStatus function
                lastPosition: {
                    place: driver.status && driver.status.address,
                    time: driver.status && driver.status.start_date,
                },
                lastLocation: {
                    place: driver.tracking && driver.tracking.address,
                    time: driver.tracking && driver.tracking.date,
                },
                violations: violations ? violations.map((el: any) => el.value) : [],
                warnings: warnings ? warnings.map((el: any) => el.value) : [],
                break: timers && +timers.break,
                drive: timers && +timers.driving,
                shift: timers && +timers.shift,
                cycle: timers && +timers.cycle,
                recap: timers && +timers.recap,
            });
        }
    });

    return { data: newData, total: data.total, cursor };
};
