import React, {useState} from 'react';
import {useReportData} from "../../../../Hooks/cleverTms/Report";
import ReportTable from "./ReportTable/ReportTable";
import {DatePicker} from "antd";
import dayjs from "dayjs";


const {RangePicker} = DatePicker;

type Data = {
    data?: {
        data: Array<any>,
        count: number
    },
    isLoading?: boolean,

}


const Report = () => {

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date().setHours(24),
            key: "selection"

        }
    ]);


    const [skip, setSkip] = useState(1);

    const {data, isLoading}: Data = useReportData(skip, state);

    const onChange = (query: any) => {
        setSkip(1 * (parseInt(query.current)));
    }


    return (
        <div className='mainPage'>
            <RangePicker  onChange={(value: any) => {
                setState(value.map((item: any) => {
                    return dayjs(item).format('MM-DD-YYYY')
                }))
            }}/>
            <ReportTable report={data?.data} total={data?.count} isLoading={isLoading} onChange={onChange}/>
        </div>
    );
};

export default Report;