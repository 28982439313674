import React, {useEffect, useState} from 'react';
import { Input, Modal, Select,  Form as FormAnt} from "antd";
import { modelList} from "../../../../../../API/TT_ELD/Users/make_moddel";
import { useMakeSelect} from "../../../../../../Hooks/TT_ELD/Makes-Models";



type Data = {
    data?: {
        data: Array<any>
        count: number
    },
    isLoading?: boolean,
    refetch?: any,
    isFetching?: any
}

const AddModelList = ({
                          open,
                          setOpen,
                          dataRefetch
                      }: { open: boolean, setOpen(open: boolean): void, dataRefetch(): void }) => {

    const [form] = FormAnt.useForm();
    const [makeData , setMakeData] = useState<Array<any>>([])
    const {data, refetch}: Data = useMakeSelect()

    const handleCancel = () => {
        setOpen(!open)

    }
    useEffect(()=>{
        if(data?.data){
            setMakeData(data?.data)
        }

    },[data?.data])

    return (
        <>
            <Modal
                open={open}
                title="Create a new model"
                okText="Create"
                cancelText="Cancel"
                onCancel={handleCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then( async (values) => {
                            form.resetFields();
                            await modelList.addModelList(values)
                            setOpen(!open)
                            dataRefetch()
                        })
                        .catch((info) => { dataRefetch()});
                }}
            >
                <FormAnt
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{ modifier: 'public' }}

                >
                    <FormAnt.Item
                        label="Make"
                        name="makeId"
                        rules={[{ required: true, message: 'Please enter your makeId!' }]}
                    >
                        <Select
                            showSearch
                            placeholder="Select a make"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={makeData?.map((option:any) => ({
                                value: option.id,
                                label: option.name,
                            }))}
                        />
                    </FormAnt.Item>

                    <FormAnt.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please enter your name!' }]}
                    >
                        <Input />
                    </FormAnt.Item>
                    <FormAnt.Item
                        label="Port"
                        name="port"
                        // rules={[{ required: true, message: 'Please input your port!' }]}
                    >
                        <Input />
                    </FormAnt.Item>
                </FormAnt>
            </Modal>
        </>
    );
};

export default AddModelList;

