import React from 'react';
import {
    dispatchersFinderOwner, useCompanyUsdot,
    useDailiesFindDriver,
    useDailiesFindDriverEmails,
    useDailiesFindDriverUser,
    useDriverCompanyFinder,
} from '../Hooks/TT_ELD/Dailies';
import {useVehicleSearch} from "../Hooks/TT_ELD/Companies";
import {
    useVehicleFindCompany,
    useVehicleFindTruck,
    useVehicleFindVehicleId,
    useVehicleFindVin
} from "../Hooks/TT_ELD/Vehicles";
import {useSupportFindUsers} from "../Hooks/TT_ELD/Supports";
import {useFindDeviceName} from "../Hooks/TT_ELD/Team/Devices";
import {useModelFinderName} from "../Hooks/TT_ELD/Makes-Models";
import {useUnitSearch} from "../Hooks/cleverTms/Units";
import {Tag} from "antd";

type MyStructure = Object[];

export const SearchResultForFindDriver = async (query: any) => {
    const data: MyStructure = await useDailiesFindDriver(query);
    const dataFor = [{id: undefined, first_name: 'All', second_name: 'Users', role: 'User'}, ...data]
    return dataFor.map((el: any) => {
        const category = `${el.first_name + ' ' + el.second_name}`;
        return {
            valId: el.id,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{display: 'flex', justifyContent: 'space-between',}}
                    key={el.id}
                >
                        <span>
                          {el.first_name + ' ' + el.second_name +' - ' +"CMPN" + " : " + el.companyId}
                        </span>
                </div>
            ),
        };
    })
}
export const SearchForUserCompanyDR = async (query: string, id: number | string) => {
    const data: MyStructure = await useDailiesFindDriverUser(query, id);
    const dataFor = [{id: ' ', first_name: 'All', second_name: 'dispatcher'}, ...data]
    return dataFor?.map((el: any) => {
        const category = `${el.first_name + ' ' + el.second_name}`;
        return {
            valId: el.id,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                          {el.first_name + ' ' + el.second_name }
                        </span>
                </div>
            ),
        };
    });
}
export const SearchForUserCompanyDRAdd = async (query: string, id: number | string) => {
    const data: MyStructure = await useDailiesFindDriverUser(query, id);
    const dataFor = [...data]
    return dataFor?.map((el: any) => {
        const category = `${el.first_name + ' ' + el.second_name}`;
        return {
            valId: el.id,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                          {el.first_name + ' ' + el.second_name + ' : ' + el?.whichservice}
                        </span>
                </div>
            ),
        };
    });
}
export const SearchForUserCompanyEM = async (query: string, id:number| string) => {
    const data: any = await useDailiesFindDriverEmails(query, id);
    const dataFor = [...data]
    return dataFor?.map((el: any) => {
        const category = `${el.email}`;
        return {
            valId: el.id,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                          {el.email}
                        </span>
                </div>
            ),
        };
    });
}
export const SearchResultForCompany = async (query: string) => {
    const data: MyStructure = await useDriverCompanyFinder(query);
    const dataFor = [...data]
    return dataFor.map((el: any) => {
        const category = `${el.name + ':' + el?.usdot }`;
        return {
            valId: el?.id,
            value: category,
            key: el?.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el?.id}
                >
                            {el?.name}  <Tag>USDOT: {el.usdot}</Tag>
                </div>
            ),
        };
    });
}
export const SearchResultForCompanyUSDOT = async (query: string) => {
    const data: MyStructure = await useCompanyUsdot(query);
    const dataFor = [...data]
    return dataFor.map((el: any) => {
        const category = `${el.name + ':' + el?.usdot }`;
        return {
            valId: el?.id,
            value: category,
            key: el?.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el?.id}
                >
                            {el?.name}  <Tag>USDOT: {el.usdot}</Tag>
                </div>
            ),
        };
    });
}
export const SearchResultForDevices = async (query: string) => {
    const data: MyStructure = await useFindDeviceName(query);
    const dataFor = [{id: undefined, first_name: 'All', second_name: 'Users'}, ...data]
    return dataFor.map((el: any, i) => {
        const category = `${el.first_name + ' ' + el.second_name  + " : " + el.id }`;
        return {
            valId: el.id,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{display: 'flex', justifyContent: 'space-between',}}
                    key={el.id}
                >
                        <span key={el.id}>
                          {el.first_name + ' ' + el.second_name  + " : " + el.id}
                        </span>
                </div>
            ),
        };
    })
}
export const SearchResultForVehicle = async (query: string, companyId: string | number | undefined) => {
    const data: MyStructure | any = await useVehicleSearch(query, companyId);
    // const dataFor = [{id: undefined, truck_number: 'All truck'}, ...data]
    return data?.map((el: any) => {
        const category = `${el.truck_number}`;
        return {
            valId: el.id,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                          Truck_number: {el.truck_number} Vin: {el.vin}
                        </span>
                </div>
            ),
        };
    });
}
export const SearchForVehicleCompany = async (query: string, truckId: string | number , vinId:string|number) => {
    const data: MyStructure = await useVehicleFindCompany(query, truckId, vinId);
    const dataFor = [{ company: ''}, ...data]
    return dataFor.map((el: any) => {
        const category = ` ${el.company?.name}`;

        return {
            valId: el.company?.id,
            value: category,
            key: el.company?.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                        CMP: {el.company?.name}
                        </span>
                </div>
            ),
        };
    });
}
export const SearchForTruckId = async (query: string, companyId: string | number | undefined, vinId:string|number) => {
    const data: MyStructure = await useVehicleFindTruck(query, companyId, vinId);
    const dataFor = [{ truck_number: ''}, ...data]
    return dataFor.map((el: any) => {
        const category = ` ${el.truck_number}`;
        return {
            valId: el.truck_number,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                        Truck number: {el.truck_number} Vin: {el.vin}
                        </span>
                </div>
            ),
        };
    });
}
export const SearchForVehicleVin = async (query: string, companyId: string | number | undefined,truckId:string|number) => {
    const data: MyStructure = await useVehicleFindVin(query, companyId,truckId);
    const dataFor = [{vin: ''}, ...data]
    return dataFor.map((el: any) => {
        const category = ` ${el.vin}`;
        return {
            valId: el.vin,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                         Vin: {el.vin}
                        </span>
                </div>
            ),
        };
    });
}
export const SearchForVehicleId = async (query: string,  valId: string | number ) => {
    const data: MyStructure = await useVehicleFindVehicleId(query, valId);
    const dataFor = [{id: ''}, ...data]
    return dataFor.map((el: any) => {
        const category = ` ${el.id}`;
        return {
            valId: el.id,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                         Vehicle: {el.id}
                        </span>
                </div>
            ),
        };
    });
}
export const SearchResultForFindSupport = async (query: any) => {
    const data: MyStructure = await useSupportFindUsers(query);
    const dataFor = [{id: undefined, first_name: 'All', second_name: 'Users', role: 'User'}, ...data]
    return dataFor.map((el: any) => {
        const category = `${el.first_name + ' ' + el.second_name}`;
        return {
            valId: el.id,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                            {el.first_name + ' ' + el.second_name}
                        </span>
                </div>
            ),
        };
    });
}
export const SearchResultForModel = async (query: string) => {
    const data: MyStructure = await useModelFinderName(query);
    const dataFor = [{id :'' ,name: 'All Company'}, ...data]
    return dataFor.map((el: any) => {
        const category = `${el.name}`;
        return {
            valId: el.name,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                          {el.name}
                        </span>
                </div>
            ),
        };
    });
}
export const SearchResultForUnit = async (query: string, company:number|undefined) => {
    const data: MyStructure = await useUnitSearch(query ,company);
    const dataFor = [...data]
    return dataFor.map((el: any) => {
        const category = `${el?.dispatcher?.firstName + ' ' + el?.dispatcher?.secondName + " : " + el?.unit.name }`;
        return {
            valId: el?.unit.name,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                             { el?.dispatcher?.firstName + ' ' + el?.dispatcher?.secondName + " : " + el?.unit.name}
                        </span>
                </div>
            ),
        };
    });
}
export const SearchForOwnerDispatchers = async (query: string, id: number | string) => {
    const data: MyStructure = await dispatchersFinderOwner(query);
    const dataFor = [...data]
    return dataFor?.map((el: any) => {
        const category = `${el.first_name + ' ' + el.second_name}`;
        return {
            valId: el.id,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                          {el.first_name + ' ' + el.second_name }
                        </span>
                </div>
            ),
        };
    });
}