import instance from "../api";

export const eldModels = {
    async read( companyId: string | number|undefined, skip: string | number = 1) {
        let query: any = {"where": {"companyId":companyId}}
        const {data}: any = await instance(`elds?filter=${encodeURIComponent(JSON.stringify({...query}))}`);
        return {
            data: data,
            count: data.length
        };
    },
    async eldModelsOne(id: string | number | undefined) {
        const {data}: { data: any } = await instance(`elds/${id}`);
        return {data};
    },

}