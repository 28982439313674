import React, {useState} from 'react';
import ContactTable from "./ContactTable/ContactTable";
import {useContactData} from "../../../../Hooks/cleverTms/Contact";
import SearchOptions from "../../../../Utils/SearchOptions";
import { SearchResultForContact} from "../UtilsTMS/SearchResults";
import {SearchResultForCompany} from "../../../../Utils/SearchResults";
import {Link} from "react-router-dom";
import {Button} from "antd";

type Data = {
    data?: {
        data: Array<any>,
        count: number
    },
    isLoading?: boolean,
}
const Contact = () => {

    const [skip, setSkip] = useState(0);

    const [fullName, setFullName] = useState<string | number >('')
    const [companyId, setCompanyId] = useState<undefined | number | string>('');

    const {data,isLoading}: Data = useContactData(fullName,companyId);
    const onChange = (query: any) => {
        setSkip(10 * (parseInt(query.current) - 1));
    }
    return (
        <div className='mainPage'>
            <SearchOptions SearchResult={SearchResultForCompany}
                    onSelect={(value: any, {valId}: {valId: number | undefined}) =>
                    {setCompanyId(valId);}}
                    placeholder={'Search by company'}  value={''}/>
            <SearchOptions SearchResult={SearchResultForContact}
                    onSelect={(value: any, {valId}: { valId: string | number  }) => {
                        setFullName(valId);
                    }} placeholder="Search by name"  value={''}/>
            <Link to={'add-contact'}>
                <Button type="primary" style={{float: "right"}} size={'large'}>
                    Add Contact
                </Button>

            </Link>
            <ContactTable contact={data?.data} total={data?.count} onChange={onChange} isLoading={isLoading} />
        </div>
    );
};

export default Contact;
//SearchResultForContact