import React, {useState} from 'react';
import {useCityData} from "../../../../Hooks/cleverTms/CitiyController";
import CityControllerTable from "./CityControllerTable/CityControllerTable";
import SearchOptions from "../../../../Utils/SearchOptions";
import {SearchResultForCity} from "../UtilsTMS/SearchResults";
import {Link} from "react-router-dom";
import {Button} from "antd";


type Data = {
    data?: {
        data: Array<any>,
        count: number
    }
}
const CityController = () => {

    const [skip, setSkip] = useState(0);
    const [city, setCity] = useState<string | number | undefined>('')
    const {data}: Data = useCityData(city);
    const onChange = (query: any) => {
        setSkip(10 * (parseInt(query.current) - 1));
    }

    return (
        <div className='mainPage'>
            <div>
            <SearchOptions SearchResult={SearchResultForCity}
                    onSelect={(value: any, {valId}: { valId: string | number | undefined }) => {
                        setCity(valId);
                    }} placeholder="Search by city"  value={''}/>
            <Link to={'add-city'}>
                <Button type="primary" style={{float: "right"}} size={'large'}
                >
                    Add City
                </Button>
            </Link>
          <CityControllerTable  city={data?.data} total={data?.count} onChange={onChange}/>
            </div>

        </div>
    );
};

export default CityController;