import React from 'react';
import {Col, Input, Row, Select, Space, Switch} from "antd";
import {Field} from "react-final-form";



const Coordinates = ({ data }: { data: any }) => {
    //
    // const coordinatesInputs = [
    //     "address",
    //     "coordinates.lat",
    //     "coordinates.lng",
    //     "eld_coordinates.lat",
    //     "eld_coordinates.lng",
    //     "gps_coordinates.lat",
    //     "gps_coordinates.lng",
    //     "fused_coordinates.lat",
    //     "fused_coordinates.lng",
    // ]
    const coordinatesInputs = [
        {label :"Address",name :"address",},
        {label :"Coordinates latitude",name :"coordinates.lat",},
        {label :"Coordinates longitude",name :"coordinates.lng",},
        {label :"ELD coordinates latitude",name :"eld_coordinates.lat",},
        {label :"ELD coordinates longitude",name :"eld_coordinates.lng",},
        {label :"GPS coordinates latitude",name :"gps_coordinates.lat",},
        {label :"GPS coordinates longitude",name :"gps_coordinates.lng",},
        {label :"Fused coordinates latitude",name :"fused_coordinates.lat",},
        {label :"Fused_coordinates longitude",name :"fused_coordinates.lng",},
    ]
    // Lat = Latitude
    // Lng = longitude
    return (
        <div>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>

                <Row gutter={[16, 20]}>
                    {coordinatesInputs.map(u => {
                        return <Col className="gutter-row" span={8}>
                            <label>{u.label} </label>
                            <Field
                                name={u.name}
                                render={({input}: { input: any }) => (
                                    <Input  placeholder={u.label}
                                            type="text" {...input} />
                                )}
                            />
                        </Col>
                    })
                    }
                </Row>

            </Space>
        </div>
    );
};

export default Coordinates;