import type { MenuList } from '../../interface/layout/menu.interface';
import type { FC } from 'react';
import { Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUserItem } from '../../stores/user.store';
import { CustomIcon } from './customIcon';

interface MenuProps {
  menuList: MenuList;
  openKey?: string;
  onChangeOpenKey: (key?: string) => void;
  selectedKey: string;
  onChangeSelectedKey: (key: string) => void;
}

const MenuComponent: FC<MenuProps> = props => {
  const { menuList, openKey, onChangeOpenKey, selectedKey, onChangeSelectedKey } = props;
  const { device, locale } = useSelector((state:any) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getTitle = (menu: MenuList[0] | any) => {
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <CustomIcon type={menu.icon!} />
        <span>{menu.label[locale]}</span>
      </span>
    );
  };

  const onMenuClick = (path: string) => {
    onChangeSelectedKey(path);
    navigate(path);

    if (device !== 'DESKTOP') {
      dispatch(setUserItem({ collapsed: true }));
    }
  };

  const onOpenChange = (keys: string[]) => {
    const key = keys.pop();

    onChangeOpenKey(key);
  };

    // const rootSubmenuKeys = ['sub1', 'sub2', 'sub3','sub4','sub5', "sub7"];
    // const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
    // const [openKeys, setOpenKeys] = useState(['sub1']);
    //
    // const onOpenChange: any['onOpenChange'] = (keys:any) => {
    //     const latestOpenKey = keys.find((key:any) => openKeys.indexOf(key) === -1);
    //     if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
    //         setOpenKeys(keys);
    //     } else {
    //         setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    //     }
    // };

  return (
    <Menu
      mode="inline"
      selectedKeys={[selectedKey]}
      openKeys={openKey ? [openKey] : []}
      onOpenChange={onOpenChange}
      onSelect={(k:any)=> onMenuClick(k.key)}
      className="layout-page-sider-menu text-2"
      items={menuList
          .filter((menu: any) => menu.config !== false) // Проверка на config
          .map((menu: any) => {
              return menu.children
                  ? {
                      key: menu.code,
                      label: getTitle(menu),
                      children: menu.children
                          .filter((child: any) => child.config !== false) // Проверка на config для дочерних элементов
                          .map((child: any) => ({
                              key: child.path,
                              label: child.label[locale],
                          })),
                  }
                  : {
                      key: menu.path,
                      label: getTitle(menu),
                  };
          })}
    ></Menu>
      // <Menu
      //     mode="inline"
      //     openKeys={openKeys }
      //     onOpenChange={onOpenChange}
      //     items={allMenu}
      // />
  );
};

export default MenuComponent;
