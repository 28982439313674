import {useQuery} from "react-query";
import {serverConfig} from "../../../../API/TT_ELD/Team/serverConfig";

export const useServerConfig = (): object => {
    return useQuery([`devices/all`,], () => serverConfig.read(), {refetchOnWindowFocus: false});
};

export const useSubscription = (): object => {
    return useQuery([`subscription/all`,], () => serverConfig.getsubscription(),
        {refetchOnWindowFocus: false});
};
