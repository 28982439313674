export const zhCN_guide = {
  'app.guide.guideIntro': `引导页对于一些第一次进入项目的人很有用，你可以简单介绍下项目的功能。本 Demo 是基于`,
  'app.guide.showGuide': '打开引导',
  'app.guide.driverjs.closeBtnText': '关闭',
  'app.guide.driverjs.prevBtnText': '上一步',
  'app.guide.driverjs.nextBtnText': '下一步',
  'app.guide.driverjs.doneBtnText': '完成',
  'app.guide.driverStep.sidebarTrigger.title': 'Siderbar开关',
  'app.guide.driverStep.sidebarTrigger.description': '打开和关闭Siderbar',
  'app.guide.driverStep.notices.title': '通知中心',
  'app.guide.driverStep.notices.description': '所有通知消息都会显示在这里',
  'app.guide.driverStep.switchLanguages.title': '切换语言',
  'app.guide.driverStep.switchLanguages.description': '你可以点击这里来切换语言',
  'app.guide.driverStep.pageTabs.title': '页面标签',
  'app.guide.driverStep.pageTabs.description': '你的浏览历史会在这里集中显示',
  'app.guide.driverStep.pageTabsActions.title': '标签操作栏',
  'app.guide.driverStep.pageTabsActions.description': '点击这里可以对页面标签做一些快捷操作',
  'app.guide.driverStep.switchTheme.title': '切换主题',
  'app.guide.driverStep.switchTheme.description': '点击这里切换系统主题颜色',
};
