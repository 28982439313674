import React, {useState} from 'react';
import FMCSA_Table from "./FMCSA_Table/FMCSA_Table";
import {useFMCSA, useFmcsaCount} from "../../../../Hooks/TT_ELD/FMCSA";
import {Button, Select} from "antd";

const {Option} = Select;

const report_status = [
    {
        label: "Needs review",
        value: 'needs_review'
    },

    {
        label: "Checking",
        value: 'checking',
    },

]
type Data = {
    data?: {
        data: any
        count: number
    },
    isLoading?: boolean,
    refetch?: any,
    isFetching?: boolean
}

const Fmcsa = () => {
    const [skip, setSkip] = useState<number>(1);
    const [status, setStatus] = useState<string>('');
    const {data, refetch, isLoading, isFetching}: Data = useFMCSA(status, skip)
    const count = useFmcsaCount()
    const onChange = (query: any) => {
        setSkip(1 * (parseInt(query.current)));
    }

    const updates = () => {
        refetch()
        count.refetch()
    }

    return (
        <div className=''>
               <span style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                 <Select value={status ? status : 'all'} defaultValue="pending" onChange={(value: any, option) => {
                     setStatus(value === 'all' ? '' : value)
                     setSkip(1)
                 }} style={{width: 200}}>
                <Option key={'all'}>All Status</Option>
                     {report_status.map((u: any, index: any) => <Option key={index} value={u.value}>{u.label}</Option>)}
            </Select>
            <Button size={'large'} onClick={updates}>Refresh</Button>
            </span>
            <FMCSA_Table data={data?.data} onChange={onChange} total={data?.count} isLoading={isLoading}
                         isFetching={isFetching} refetch={updates} status='needs_review' skip={skip}/>
        </div>

    );
};

export default Fmcsa;