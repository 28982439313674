import React from 'react'
import {Button, Modal, Space, Spin, Table, Tag} from "antd";
import {Link} from "react-router-dom";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {customerController} from '../../../../../API/Tms/customerController';
import {useTagData} from "../../../../../Hooks/cleverTms/TagController";
import {useCustomerData} from "../../../../../Hooks/cleverTms/CustomerController";
import {useQueryClient} from "react-query";

type numStr = number | string;

interface cotrollerSource {
    no: numStr;
    lat: number,
    lng: number,
    address: numStr,
    name: numStr,
    city: numStr,
    state: numStr,
    updatedAt: numStr,
    placeId: numStr,
    zipCodes: numStr,
    isActive: boolean,
    companyId: numStr,
    action: { id: numStr, queryClient: any, };
    key: React.Key;
}

const CustomerControllerTable = ({
                                     customer = [],
                                     total = 0,
                                     onChange,
                                     isLoading = true
                                 }: { customer: Array<any> | undefined, total: number | undefined, onChange(current: any): void, isLoading: boolean | undefined }) => {
    const queryClient = useQueryClient();
    const filterData = (customer: any) =>
        customer?.map((item: any) => ({
            key: item,
            value: item,
            text: item
        }));


    const columns: object[] = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: 70,
            sorter: {
                compare: (a: any, b: any) => a.no - b.no,
                multiple: 3,
            },
        },
        {
            title: 'Latitude',
            dataIndex: 'lat',
            key: 'lat',

        }, {
            title: 'Longitude',
            dataIndex: 'lng',
            key: 'lng',

        }, {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            sorter: (a: any, b: any) => a.address.length - b.address.length,

        }, {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            sorter: (a: any, b: any) => a.city.length - b.city.length,

        }, {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            filters: filterData(
                customer?.map((item) => item.state)
                    .filter((value, index, self) => self.indexOf(value) === index)
            ),
            onFilter: (value: any, record: any) => record.state.indexOf(value) === 0,
            sorter: (a: any, b: any) => a.state.length - b.state.length
        },

        {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            sorter: (a: any, b: any) => a.updatedAt.length - b.updatedAt.length,
            width: 230
        },
        // {
        //     title: 'Place ID',
        //     dataIndex: 'placeId',
        //     key: 'placeId',
        // },
        {
            title: 'Zip codes',
            dataIndex: 'zipCodes',
            key: 'zipCodes',
        },
        {
            title: 'Company ID',
            dataIndex: 'companyId',
            key: 'companyId',
        },
        {
            title: 'Is active',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (tag: boolean) => (
                <Tag color={tag ? 'geekblue' : 'red'}>
                    {tag ? 'True' : 'False'}
                </Tag>
            ),
            filters: [
                {
                    text: 'True',
                    value: true,
                },
                {
                    text: 'False',
                    value: false,
                },

            ],
            onFilter: (value: any, record: any) => {
                return record.isActive === value
            },
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 180,
            render: ({id, queryClient}: { id: string | number, queryClient: any }) =>
                <Space>
                    <Link to={`${id}`}><Button>Edit</Button></Link>
                    <Button type='primary' danger onClick={() => {
                        Modal.confirm({
                                title: 'LoadController',
                                icon: <ExclamationCircleOutlined rev={undefined}/>,
                                content: 'Do you want to delete this load ?',
                                okText: 'Ok',
                                cancelText: 'cancel',
                                onOk: async () => {
                                    await customerController.deleteCustomerController(id)
                                    queryClient.refetchQueries(useCustomerData);
                                }
                            }
                        );
                    }
                    }
                    >Delete
                    </Button>
                </Space>

        },
    ]


    return (
        <div>
            <Spin size='large' spinning={isLoading}>
                <Table style={{margin:'15px 0 0 0'}} onChange={onChange} dataSource={customer?.map((u: any, i: number): cotrollerSource => {
                    const obj: cotrollerSource = {
                        no: i + 1,
                        lat: u.lat,
                        lng: u.lng,
                        address: u.address,
                        name: u.name,
                        city: u.city,
                        state: u.state,
                        updatedAt: u.updatedAt,
                        placeId: u.placeId,
                        zipCodes: u.zipCodes,
                        companyId: u.companyId,
                        isActive: u.isActive,
                        action: {id: u.id, queryClient},
                        key: u.id
                    }
                    return obj
                })} columns={columns} pagination={{total: total, pageSizeOptions: [10, 50, 100]}}
                       scroll={{x: 1000}} sticky
                />
            </Spin>
        </div>
    )
}

export default CustomerControllerTable