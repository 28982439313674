import React from 'react';
import {Button, Card, Col, Input, Row, Space, Spin, Switch, Tabs} from "antd";
import {Field, Form} from "react-final-form";
import {useNavigate} from "react-router-dom";
import {brokerController} from "../../../../../API/Tms/brokerController";

const AddBrokerController = () => {
    // const BrokerInputs = [
    //     "name",
    //     "city",
    //     "state",
    //     "phone",
    //     "address",
    //     "email",
    //     "notes",
    //     "attachment",
    //     "zipCodes",
    //     "placeId",
    // ]
    const brokerInputs = [
        {label:"Name", name:'name'},
        {label:"City", name:'city'},
        {label:"State", name:'state'},
        {label:"Phone number", name:'phone'},
        {label:"Address", name:'address'},
        {label:"Email", name:'email'},
        {label:"Notes", name:'notes'},
        {label:"Attachment", name:'attachment'},
        {label:"ZIP codes", name:'zipCodes'},
        {label:"Place ID", name:'placeId'},
    ]
    let navigate = useNavigate();
    type Fields = {
        "id": boolean,
        "name": boolean,
        "city": boolean,
        "state": boolean,
        "updatedAt": boolean,
        "createdAt": boolean,
        "isActive": boolean,
        "phone": boolean,
        "address": boolean,
        "email": boolean,
        "notes": boolean,
        "attachment": Array<string>,
        "zipCodes": boolean,
        "companyId": boolean,
    }
    const onSubmit = async (value: Fields) => {
        await brokerController.addBrokerController(value)
        navigate(-1)
    };
    const required = (value: any) => (value ? undefined : 'Required')

    return (
        <div className='mainPage'>
            <Card>
                {<Form onSubmit={onSubmit}
                       render={({handleSubmit, submitting}) => (
                           <form onSubmit={handleSubmit}>
                               <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                   <h1>Add Broker</h1>
                                   <Space direction="vertical" size="middle"
                                          style={{display: 'flex',}}>
                                       <Row gutter={[16, 20]}>
                                           {brokerInputs.map(u => {
                                               return <Col className="gutter-row" span={6}>
                                                   <label>{u.label}: </label>
                                                   <Field validate={required}
                                                          name={u.name}
                                                          render={({input, meta}: { input: any, meta: any }) => (
                                                              <div>
                                                                  <Input {...input} type='text' placeholder={u.label}/>
                                                                  {meta.touched && meta.error &&
                                                                      <div style={{color: 'red'}}>{meta.error}</div>}
                                                              </div>
                                                          )}
                                                   ></Field>
                                               </Col>
                                           })
                                           }
                                           <Col span={6}>
                                               <div>
                                                   <label>companyId : </label>
                                                   <Field validate={required}
                                                          name="companyId"
                                                          render={({input, meta}: { input: any, meta: any }) => (
                                                              <div>
                                                                  <Input {...input} type='number'
                                                                         placeholder='companyId'/>
                                                                  {meta.touched && meta.error &&
                                                                      <div style={{color: 'red'}}>{meta.error}</div>}
                                                              </div>
                                                          )}
                                                   ></Field>
                                               </div>
                                           </Col>

                                       </Row>
                                       <div>
                                           <label>Is Active: </label>
                                           <Field
                                               name="isActive"
                                               render={({input}: { input: any }) => (
                                                   <Switch defaultChecked={input.value} onChange={input.onChange}/>
                                               )}
                                           />
                                       </div>
                                       <div className="buttons">
                                           <Button disabled={submitting} type="primary" htmlType="submit">
                                               Submit
                                           </Button>
                                       </div>
                                   </Space>
                               </Space>
                           </form>
                       )
                       }
                />
                }
            </Card>
        </div>
    );
};

export default AddBrokerController;