import instance from "../apiClevertms";
import {message} from "antd";

type numStr = string | number
interface Field {
    id: numStr,
    isActive: boolean,
    updatedAt: numStr,
    createdAt: numStr,
    type: numStr,
    name: numStr,
    companyId: numStr
}

export const categoryController = {
    async read(name: string |number | undefined,  companyId: undefined | number |string) {


        let query: any = {
            // "limit": 100,
            "where": {
                "and":[
                    {"name":{"ilike":`%${name}%`}},
                    companyId?{
                        companyId
                    }:{}
                ]
            }
        }
        const {data}: { data: object } = await instance(`admin/categories?filter=${encodeURIComponent(JSON.stringify({...query}))}`);
        const getCount = async () => {
            return 0;
        }
        const count = await getCount();

        return {data, count: count};
    },

    async categoryCompanyOne(Id: string | number | undefined) {
        const {data}: { data: any } = await instance(`admin/categories/${Id}`);
        const {
            createdAt,
            updatedAt,
            companyId,
            isActive,
            ...rest
        } = data
        return {...rest};
    },

    async categoryPatch(companyData: Field) {
        const {id, ...rest} = companyData
        const key = 'updatable';
        message.loading({content: 'Loading...', key});
        const {data}: { data: any } = await instance(`admin/categories/${id}`, {
            method: 'PATCH',
            data: rest
        }).then(u => {
            setTimeout(() => {
                message.success({content: 'Loaded!', key, duration: 2});
            }, 1000);
            return u;
        });
        return data;
    },

    async addCategoryController(companyId: any) {
        message.loading({content: 'Loading...', key: companyId});
        const {data} = await instance("/admin/categories/", {
            method: 'POST',
            data: {
                ...companyId,
            },
        }).then(u => {
            setTimeout(() => {
                message.success({content: 'Loaded!', key: companyId, duration: 2});
            }, 1000);
            return u;
        });
        return data;
    },


    async deleteCategoryController(id: number | string) {
        message.loading({content: 'Loading...', key: id});
        let res;
        let error = "";
        try {
            const {data} = await instance(`/admin/categories/${id}`, {
                method: 'DELETE',
            }).then(u => {
                setTimeout(() => {
                    message.success({content: 'Deleted!', key: id, duration: 2});
                }, 1000);
                return u;
            });
            res = data;
        } catch (err) {
            error = "Oops something went wrong!";
        }
        return {data: res, error};
    },
    async categoryFinderId(name :any) {
        let query: any = {
            "limit": 100,
            "where": {
                "and":[
                    {"name":{"ilike":`%${name}%`}},
                ]
            },
        }
        const {data}: { data: Array<any> } = await instance(`admin/categories?filter=${encodeURIComponent(JSON.stringify({...query}))}`);
        return data;
    },
}