import React, {useState} from 'react';
import {useZipCodeData} from "../../../../Hooks/cleverTms/ZipCodeController";
import ZipCodeTable from "./ZipCodeTable/ZipCodeTable";
import SearchOptions from "../../../../Utils/SearchOptions";
import { SearchResultForZipCode} from "../UtilsTMS/SearchResults";
import {Link} from "react-router-dom";
import {Button} from "antd";


type Data = {
    data?: {
        data: Array<any>,
        count: number
    },
    refetch?: any,
}
const ZipCode = () => {

    const [skip, setSkip] = useState(0);
    const [text, setText] = useState<string | number | undefined>('')

    const {data, refetch}: Data = useZipCodeData(text);
    const onChange = (query: any) => {
        setSkip(10 * (parseInt(query.current) - 1));
    }
    return (
        <div className='mainPage'>
            <SearchOptions SearchResult={SearchResultForZipCode}
                    onSelect={(value: any, {valId}: { valId: string | number | undefined }) => {
                        setText(valId);
                    }} placeholder="Search by ZIP" value={''}/>
            <Link to={'add-zip-code'}>
                <Button type="primary" style={{float: "right"}} size={'large'}>
                    Add ZIP Code
                </Button>
            </Link>
            <ZipCodeTable zipCode={data?.data} total={data?.count} onChange={onChange} refetch={refetch} />
        </div>
    );
};

export default ZipCode;