import instance from "../apiClevertms";


export const reportController = {
    async read(skip: string| number , date: any) {
        let url = date && !date || !date[0] || !date[1] ?
            `admin/report/loads?page=${skip}&limit=10`
            :
            `admin/report/loads?page=${skip}&limit=10&fromDate=${date[0]}&toDate=${date[1]}`
            const {data}: { data: any } = await instance(`${url}`);
            return {
                data: data?.data,
                count: data.count
            };

    },
}