import React, {useState} from 'react';
import {Button, Col, Form as FormAnt, Input, Row, Space, Switch} from "antd";
import {useSystem} from "../../../../../Hooks/TT_ELD/Team/System";
import {system} from "../../../../../API/TT_ELD/Team/system";
import {sendEvent} from "../../../../../Utils/analytics";

type MyObjectType = {
    [key: string | number]: any;
};

const GetSystem = () => {
    const [form] = FormAnt.useForm();
    const {data, refetch, isLoading, isFetching}: MyObjectType = useSystem()
    const onSubmit = async (values: any) => {

        const body: MyObjectType = {}
        let key: string | number
        let key2: string | number
        for (key in values) {
            if (typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] != null) {
                for (key2 in values[key]) {
                    if (typeof values[key][key2] === 'object' && !Array.isArray(values[key][key2]) && values[key][key2] != null) {
                        if (JSON.stringify(values[key][key2]) != JSON.stringify(data[key][key2])) {
                            body[key] = values[key]
                            break
                        }
                    } else if (data[key][key2] != values[key][key2]) {
                        body[key] = values[key]
                        break
                    }
                }
            } else if (data[key] != values[key]) {
                body[key] = values[key]
            }
        }
        await system.editSystem(body)
        refetch()
        form.resetFields();
        sendEvent(` Changed System `, ` Changed System `, `Switch System , Changed System ` , 1);
    }
    const [selected, setSelected] = useState<any>(null);
    const [checked, setChecked] = useState<string>('');

    const onChange = (checked: any) => {
        setChecked(checked)
    };
    const toggle = (i: any) => {
        if (selected === i) {
            return (setSelected(null))
        }
        setSelected(i);
    };
    return (
        <div>
            <Space
                direction="vertical"
                size="middle"
                style={{display: "flex"}}
            >
                {/*<pre style={{ border: 'none' }}>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>*/}
                <FormAnt
                    form={form}
                    layout="horizontal"
                    name="form_in_modal"
                    onFinish={onSubmit}
                    initialValues={{...data}}
                    // onFieldsChange={(_, allFields: any) => {
                    //     onChange(allFields);
                    // }}
                >
                    <Row gutter={[16, 10]} style={{display: 'flow-root'}}>
                        {data && Object.keys(data?.data).map((item, i) => {
                            return (
                                <div style={{display: 'flex'}}>
                                    <Col key={i} style={{flex:'1', marginRight:'40px'}}>
                                        <FormAnt.Item
                                            label={item}
                                            name={item}
                                        >
                                            <Switch key={i}
                                                    checked={selected === i ? checked : data.data[item]}
                                                    defaultChecked={data.data[item]}
                                                    onChange={(i) => onChange(i)} onClick={() => toggle(i)}/>
                                        </FormAnt.Item>
                                    </Col>
                                    <Col  style={{ width:'100%'}}>
                                        <FormAnt.Item className={selected === i ? "content show" : "content"}>
                                            <Button htmlType="submit">
                                                Submit
                                            </Button>
                                        </FormAnt.Item>
                                    </Col>
                                </div>
                            )
                        })}
                    </Row>

                </FormAnt>
            </Space>
        </div>
    );
};

export default GetSystem;