import React, { useState} from 'react';
import {useGetDocuments} from "../../../../../../Hooks/TT_ELD/TruckInfo";
import {useParams} from "react-router-dom";
import {Button, Col, Spin, Table} from "antd";
import {truckInfo} from "../../../../../../API/TT_ELD/Users/truckInfo";
import DownloadButton from "./DownloadButton";
import SearchOptions from "../../../../../../Utils/SearchOptions";
import {
    SearchResultForCompany,
    SearchResultForFindDriver
} from "../../../../../../Utils/SearchResults";
import SearchInput from "../../../../../../Utils/SearchInput";
import useRouteQuery from "../../../../../../Utils/useRouterQuery";
import {sendEvent} from "../../../../../../Utils/analytics";
import {calcOrder} from "../../../../../components/core/calcOrder";

type Data = {
    data?: {
        data: Array<any>,
        count: number
    },
    isLoading?: boolean,
    refetch?: any,
    isFetching?: boolean
}

interface userDataSource {
    id: number | string;
    name: string;
    company: number | string;
    type: number | string;
    createdAt: number | string;
    edit: [],
    key: React.Key;
}

interface Params {
    id: string | undefined | any;

    [key: string]: string | undefined;
}

const Documents = () => {
    const {id} = useParams<Params>();
    const [skip, setSkip] = useState<number>(1);
    const [companyId, setCompanyId] = useState<number | string>('');
    const [userId, setUserId] = useState<undefined | number | string>('');
    const [type, setType] = useRouteQuery("", 'type', (v) => String(v));

    const {data, refetch, isLoading, isFetching}: Data = useGetDocuments(id, userId, companyId, type, skip)
    const columns: object[] = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 65
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',

        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Download',
            dataIndex: 'edit',
            key: 'edit',
            width: 200,
            render: (links: any) => {
                return (
                    <>
                        {links.map((link: any) => (
                            <DownloadButton
                                key={link?.link}
                                link={link?.link}
                                fileName={link?.fileName}
                            />
                        ))}
                    </>
                )
            },
        },
    ];


    const sendCommandAndRefetch = async (command: string) => {
        await truckInfo.commandsPost({
            "command": command,
            "userId": id,
        });
        sendEvent('Documents Tab ', `Documents Button  ${command} ` , `Button Clicked ` ,1 )

        refetch();
    };


    const onChange = (query: any) => {
        setSkip(1 * (parseInt(query.current)));
    }

    const handleChange = (event:string, inputName:string) => {
        const updateFunctions: Record<string, (value: string) => void> = {
            type: setType,
        };

        if (updateFunctions[inputName]) {
            updateFunctions[inputName](event?.trim());
        }
    };

    const onSelectDriver = (value: any, {valId}: { valId: number | string | undefined }) => {
        setUserId(valId ? valId : '');
        if (valId) {
            setSkip(1)
        }
    }
    return (
        <div className='mainPage'>
            <Spin size="large" spinning={isLoading || isFetching}>
                {!id && <span style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '15px'
                }}>
                      <SearchOptions SearchResult={SearchResultForCompany}
                                     onSelect={(value: any, {valId}: { valId: number | string }) => {
                                         setCompanyId(valId ? valId : '');
                                     }} placeholder="Search by company" value={''}/>
                 <SearchOptions SearchResult={(query: string) => SearchResultForFindDriver(query)}
                                onSelect={onSelectDriver} placeholder="User Search" value={''}/>

                       <SearchInput value={type} placeholder="Type Search"  onDebounceChange={(event:string) => handleChange(event, 'type')}/>

                      <Button style={{marginLeft: 'auto'}} size={'large'} onClick={refetch}>Refresh</Button></span>}
                <Col style={{margin: '0px 0 15px 0'}}>
                    {id && <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '15px'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width:'40%'
                        }}>
                        <Button style={{marginRight:'10px'}}  type='primary' onClick={() => sendCommandAndRefetch("upload_log_file")}>Request Log</Button>
                        <Button  style={{marginRight:'10px'}}  type='primary' onClick={() => sendCommandAndRefetch("upload_db_file")}>Request DB</Button>
                        <Button  style={{marginRight:'10px'}} type='primary' onClick={() => sendCommandAndRefetch("upload_timer_file")}>Upload Timer File</Button>
                        <Button style={{marginRight:'10px'}}  type='primary' onClick={() => sendCommandAndRefetch("delete_db_file")}>Delete DB File</Button>
                        <Button  style={{marginRight:'10px'}} type='primary' onClick={() => sendCommandAndRefetch("upload_pref_file")}>Upload Pref File</Button>
                        <Button type='primary' onClick={() => sendCommandAndRefetch("upload_screenshot_file")}>Upload Screenshot File</Button>
                        </div>

                        <Button style={{marginLeft: 'auto'}} size={'large'} onClick={refetch}>Refresh</Button>
                    </span>}
                </Col>


                <Table onChange={onChange} dataSource={data?.data?.map((u: any, i: number): userDataSource => {
                    const obj: userDataSource = {
                        id: calcOrder(skip, 10, i),
                        name: u?.user.first_name + ' ' + u?.user.second_name,
                        company: u.company?.name + ' : ' + u?.company?.id,
                        type: u.type,
                        createdAt: u.createdAt,
                        edit: u.links,
                        key: u.id
                    }
                    return obj;
                })} columns={columns}
                       pagination={{total: data?.count, showSizeChanger: false,}}
                       scroll={{x: 1000}}
                       sticky
                />
            </Spin>
        </div>
    );
};

export default Documents;