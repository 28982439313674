import React, {useState, FormEvent} from "react";
import {useCompanyData, useSubscription} from "../../../../../../../Hooks/TT_ELD/Companies";
import {
    Button,
    Statistic,
    Descriptions,
    Row,
    Card,
    Input,
    Tag, Modal, Select,
} from "antd";
import {Form} from "react-final-form";
import {calculator} from "../../../../../../../Utils/data";
import {companies} from "../../../../../../../API/TT_ELD/Users/company";
import {sendEvent} from "../../../../../../../Utils/analytics";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";

interface AllFields {
    units?: number | string | any;
    eta?: string;
    total?: number;
    cards?: Array<any>; // Замените any на более конкретный тип, если это возможно
    card?: string;
    price_payments?: any; // Замените any на более конкретный тип, если это возможно
    price?: number;
}

interface FormProps {
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
    values: AllFields;
}

type MyObjectType = {
    [key: string | number]: any; // Индексная подпись с параметром типа 'string'
};
type params = {
    readonly id: any
}
type Data = {
    data?: {
        data: any;
        count: any;
    };
    status: boolean | string;
    isLoading?: boolean;
    refetch?: any;
    isFetching?: any;
};
const {Option} = Select;
const tabListNoTitle: any = [
    {
        key: "payment",
        label: "Payment",
    },
];

const stripeList = [
    {
        label: "Stripe 1",
        value: 'stripe1'
    },

    {
        label: "Stripe 2",
        value: 'stripe2',
    },
    {
        label: "Stripe EVO",
        value: "stripe_evo",
    }, {
        label: "Stripe Zippy",
        value: "stripe_zippy",
    },
]

const Subscription = () => {
    const {id} = useParams<params>();
    const company: MyObjectType = useCompanyData(id);
    const {
        data,
        refetch,
        isLoading,
        isFetching
    }: Data = useSubscription(company?.data?.stripe?.subscription?.id, company?.data?.id);
    const [modal, contextHolder] = Modal.useModal();
    const [stripeType, setStripeType] = useState<string>("");
    let cusId = company?.data?.stripeCustomerId2 ? company?.data?.stripeCustomerId2 : company?.data?.stripeCustomerId;

    const confirm = () => {
        modal.confirm({
            title: 'Are you sure',
            icon: <ExclamationCircleOutlined rev={undefined}/>,
            okText: 'Ok',
            onOk: async () => {
                try {
                    await companies.stripeMoves({
                        whereToMove: stripeType,
                        customerId1: company?.data?.stripeCustomerId,
                        customerId2: stripeCustomerId2 ? stripeCustomerId2 : cusId
                    });
                    company?.refetch()
                    // refetch()
                } catch (error) {
                    refetch()
                    company.refetch()
                }
                sendEvent('stripe', 'Submit', '', 1)
            },
            cancelText: 'Cancel',
        });
    };
    const [stripeCustomerId2, setStripeCustomerId2] = useState('');
    const subscrInfo: any = data

    const handleStripeCustomerIdChange = (e: any) => {
        setStripeCustomerId2(e.target.value);
    };
    const getInitialValue = () => {
        if (subscrInfo?.data?.card || subscrInfo?.data?.current_quantity) {
            const eta =
                subscrInfo?.data.current_price === subscrInfo.data.prices.price;
            const units = subscrInfo?.data.current_quantity;
            let crBalance = 0;
            if (subscrInfo?.data && subscrInfo?.data.customer) {
                crBalance = subscrInfo?.data.customer.balance;
            }
            return {
                eta,
                units,
                card: subscrInfo?.data.customer.invoice_settings
                    ?.default_payment_method,
                credits: Math.abs(crBalance / 100),
                price_payments: subscrInfo?.data.prices,
                cards: subscrInfo?.data?.card,
            };
        }
        if (subscrInfo?.data?.bankType) {
            const eta =
                subscrInfo?.data.current_price === subscrInfo.data.prices.price;
            const units = subscrInfo?.data.current_quantity;
            let crBalance = 0;
            if (subscrInfo?.data && subscrInfo.data.customer) {
                crBalance = subscrInfo?.data.customer.balance;
            }
            return {
                eta,
                units,
                credits: Math.abs(crBalance / 100),
                price_payments: subscrInfo.data.price_payments,
                cards: subscrInfo?.data.card,
            };
        }
    };
    const onGetUpcomingInvoice = (data: any) => {
    };
    let type = subscrInfo?.data?.subscription?.customer?.invoice_settings?.default_payment_method?.type
    let bankName = subscrInfo?.data?.subscription?.customer?.invoice_settings?.default_payment_method?.type === 'card' ? subscrInfo?.data?.subscription?.customer?.invoice_settings?.default_payment_method?.card?.brand : subscrInfo?.data?.subscription?.customer?.invoice_settings?.default_payment_method?.us_bank_account?.bank_name
    let last4 = subscrInfo?.data?.subscription?.customer?.invoice_settings?.default_payment_method?.type === 'card' ? subscrInfo?.data?.subscription?.customer?.invoice_settings?.default_payment_method?.card?.last4 : subscrInfo?.data?.subscription?.customer?.invoice_settings?.default_payment_method?.us_bank_account?.last4

    const StartDate = new Date(
        subscrInfo?.data?.upcoming_invoice?.period_start * 1000
    );
    const startYear = StartDate.getFullYear();
    const startMonth = String(StartDate.getMonth() + 1).padStart(2, "0");
    const startDay = String(StartDate.getDate()).padStart(2, "0");
    const formattedStarDate = `${startYear}-${startMonth}-${startDay}`;

    const EndDate = new Date(
        subscrInfo?.data?.upcoming_invoice?.period_end * 1000
    );
    const year = EndDate.getFullYear();
    const month = String(EndDate.getMonth() + 1).padStart(2, "0");
    const day = String(EndDate.getDate()).padStart(2, "0");
    const formattedEndDate = `${year}-${month}-${day}`;

    const contentListNoTitle: Record<string, React.ReactNode> = {
        payment: (
            <>
                <Form
                    onSubmit={onGetUpcomingInvoice}
                    initialValues={getInitialValue()}
                    decorators={[calculator]}
                    render={({handleSubmit, values}: FormProps) => (
                        <form onSubmit={handleSubmit}>
                            <Descriptions title={company?.data?.name}>
                                <Descriptions.Item label="Type">
                                    {type}
                                </Descriptions.Item>
                                <Descriptions.Item label="Start date">
                                    {formattedStarDate}
                                </Descriptions.Item>
                                <Descriptions.Item label="Latest invoice paid">
                                    {" "}
                                    <Tag
                                        color={
                                            subscrInfo?.data?.latest_invoice?.paid
                                                ? "geekblue"
                                                : "red"
                                        }
                                    >
                                        {subscrInfo?.data?.latest_invoice?.paid ? "True" : "False"}
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label="Name">
                                    {bankName}
                                </Descriptions.Item>
                                <Descriptions.Item label="End date">
                                    {formattedEndDate}
                                </Descriptions.Item>
                                <Descriptions.Item label="Upcoming invoice paid">
                                    {" "}
                                    <Tag
                                        color={
                                            subscrInfo?.data?.upcoming_invoice?.paid
                                                ? "geekblue"
                                                : "red"
                                        }
                                    >
                                        {subscrInfo?.data?.upcoming_invoice?.paid
                                            ? "True"
                                            : "False"}
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label="Last4">
                                    {last4}
                                </Descriptions.Item>
                            </Descriptions>
                            <Row style={{marginTop: 20}}>
                                <Statistic
                                    title="Status"
                                    valueStyle={{
                                        color:
                                            subscrInfo?.data?.subscription?.status === "active"
                                                ? "#3f8600"
                                                : "#cf1322",
                                    }}
                                    value={subscrInfo?.data?.subscription?.status.toUpperCase()}
                                />
                                <Statistic
                                    title="Quantity"
                                    prefix=""
                                    value={values.units}
                                    style={{
                                        margin: "0 0 0 32px",
                                    }}
                                />
                                <Statistic
                                    title="Price"
                                    prefix="$"
                                    value={values.total ? values?.total / values.units : 0}
                                    style={{
                                        margin: "0 32px",
                                    }}
                                />
                                {subscrInfo?.data?.subscription?.status !== "active" && (
                                    <Statistic
                                        title="Message"
                                        valueStyle={{color: "#cf1322"}}
                                        value={
                                            subscrInfo?.data?.latest_invoice?.payment_intent
                                                ?.last_payment_error?.message
                                                ? subscrInfo?.data?.latest_invoice?.payment_intent?.last_payment_error?.message.toUpperCase()
                                                : "CANCELED"
                                        }
                                        style={{
                                            margin: "0 32px",
                                        }}
                                    />
                                )}
                            </Row>
                        </form>
                    )}
                />
            </>
        ),
    };
    const [activeTabKey2, setActiveTabKey2] = useState<string>("payment");
    const onTab2Change = (key: string) => {
        setActiveTabKey2(key);
    };

    let disabledButton: boolean = true
    if (company?.data?.stripeType === 'stripe1' && stripeType) {
        disabledButton = false
    }
    if (company?.data?.stripeType === 'stripe2' && stripeType) {
        disabledButton = false
    }
    if (company?.data?.stripeType === 'stripe_evo' && stripeType) {
        disabledButton = false
    }
    if (company?.data?.stripeType === 'stripe_zippy' && stripeType) {
        disabledButton = false
    }
    if (company?.data?.stripeType === 'stripe1' && stripeType === 'stripe1') {
        disabledButton = true
    }
    if (company?.data?.stripeType === 'stripe2' && stripeType === 'stripe2') {
        disabledButton = true
    }
    if (company?.data?.stripeType === 'stripe_evo' && stripeType === 'stripe_evo') {
        disabledButton = true
    }
    if (company?.data?.stripeType === 'stripe_zippy' && stripeType === 'stripe_zippy') {
        disabledButton = true
    }


    return (
        <div>
            <Card
                title={'Subscription '}
                loading={isLoading || isFetching || company?.isLoading || company?.isFetching}
                style={{width: '100%'}}
                tabList={tabListNoTitle}
                activeTabKey={activeTabKey2}
                onTabChange={onTab2Change}
                extra={
                    isLoading || isFetching || !subscrInfo?.data &&
                    <Statistic valueStyle={{color: '#cf1322'}} value={'Subscription not created'}/>
                }
            >
                {contentListNoTitle[activeTabKey2]}
                <div style={{display: 'flex'}}>
                    <div style={{marginTop: '30px', width: '40%',}}>
                        <label>Stripe Customer ID: 2</label>
                        <Input placeholder="stripeCustomerId" type="text" defaultValue={cusId}
                               onChange={handleStripeCustomerIdChange}/>

                    </div>
                    <div style={{marginTop: '52px', marginLeft: '15px'}}>
                        <Select placeholder="Select Stripe type" allowClear onChange={(value: any, option) => {
                            setStripeType(value)
                        }} style={{width: 200}}>
                            {stripeList.map((u: any, index: any) => <Option key={index}
                                                                            value={u.value}>{u.label}</Option>)}
                        </Select>
                    </div>
                </div>

                <Button
                    disabled={disabledButton}
                    style={{marginTop: '30px'}} danger type="primary" onClick={confirm}>
                    Submit
                </Button>
                {contextHolder}
            </Card>
        </div>
    );
};

export default Subscription;
