import {useQuery} from "react-query";
import {logs} from "../../../API/TT_ELD/logs";

export const useLogsData = (id: string | undefined, todayDate: string, record_status: number | undefined): object => {
    return useQuery([
            `logs/${id}`, id, todayDate, record_status],
        () => logs.read(id, todayDate, record_status),
        { refetchOnWindowFocus: false })
}

export const useFindOneLog = (id: number | undefined,companyId:number| undefined, pathname: string): any => {
    return useQuery([
            `log/${id}`, id],
        () => logs.findOneLog(id, companyId ,pathname?.includes('logzeros') ? 'logzeros' : 'log'),
        { refetchOnWindowFocus: false })
}
export const useFindOneOrMany = (ids: Array<number>,companyId:string |number, pathname: string): any => {
    return useQuery([
            `logOneOrMany/${ids}`, ids],
        () => logs.findOneOrMany(ids,companyId, pathname?.includes('logzeros') ? 'logzeros' : 'log'),
        { refetchOnWindowFocus: false })
}

export const useVehicleSearch = (query: string, companyId: string | number | undefined): object => {
    return useQuery([
            `vehicles/${companyId}`,query, companyId],
        () => logs.searchVehicleData(query, companyId),
        { refetchOnWindowFocus: false })
}

export const useLastTrucking = (id: number | string | undefined): any => {
    return useQuery(
        [`dashboards/userlasttracking/${id || "all"}`, id],
        () => logs.lastTrucking(id),
        {refetchOnWindowFocus: false}
    );
};

