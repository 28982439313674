import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import { Select,  Tabs, Form as FormAnt, Modal} from "antd";
import {useGetRole, useUserData} from "../../../../../Hooks/TT_ELD/UserCompanies";
// import {Form, Field} from "react-final-form";
import {userCompany} from "../../../../../API/TT_ELD/userCompany";
import {AppleOutlined} from "@ant-design/icons";
import {contact} from "../../../../../API/TT_ELD/contact";


type Data = {
    data?: {
        data: Array<any>,
        count: number
    }
    isLoading?: boolean,
    isFetching?: boolean,
    refetch?: any
}
type params = {
    readonly id: any
}

interface Values {
    role?: string;
}


const UserCompaniesEdit = ({
                               isModalVisible,
                               setIsModalVisible,
                               refetchData,
                               getOneData
                           }: { isModalVisible: boolean | undefined, setIsModalVisible(isModalVisible: boolean): void, refetchData: any, getOneData: any }) => {
    const {data, refetch, isLoading, isFetching}: any = useUserData(getOneData.id)
    const getRole: any = useGetRole()
    const roleList = getRole?.data?.data ? Object.keys(getRole.data.data).map(key => ({
        label: getRole.data[key],
        value: key
    })) : [];

    const [form] = FormAnt.useForm();
    const onSubmit: any = async (values: Values) => {
        try {
            // await userCompany.userCompanyPatch(values);
            // refetch()
            // setIsModalVisible(false);
        }catch (error){

        }

    };

    const handleCancel = () => {
        setIsModalVisible(false);
    }
    const onReset = () => {
        form.resetFields();
    };


    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            let body = {role: values.role, id:getOneData.id}
            await userCompany.userCompanyPatch(body);
            setIsModalVisible(false);
            refetchData()
            refetch()
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };
    return (
        <Modal title='Role Edit' onOk={handleOk} open={isModalVisible} onCancel={handleCancel}>
            <br/>
            {data?.data && <FormAnt
                form={form}
                name="basic"
                layout="vertical"
                wrapperCol={{span: 16}}
                initialValues={data?.data}
                onFinish={onSubmit}
                autoComplete="off"
            >
                <FormAnt.Item
                    label="Role"
                    name="role"
                >
                    <Select
                        defaultValue={data?.data?.role}
                        showSearch
                        placeholder="Select a person"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={roleList?.map((option: any) => ({
                            value: option.value,
                            label: option.label,
                        }))}
                    />
                </FormAnt.Item>
            </FormAnt>}
            {/*<Spin size="large" spinning={isLoading || isFetching}>*/}
            {/*    {data?.data && <Form onSubmit={onSubmit}*/}
            {/*                          initialValues={{...data?.data,}}*/}
            {/*                          render={({handleSubmit, values}) => (*/}
            {/*                              <form onSubmit={handleSubmit}>*/}
            {/*                                  <Space direction="vertical" size="middle" style={{display: 'flex'}}>*/}
            {/*                                      <Row gutter={16}>*/}
            {/*                                                  <Col span={16}>*/}
            {/*                                                      <div style={{padding:'16px 0'}}>*/}
            {/*                                                          <label>Role</label>*/}
            {/*                                                          <Field*/}
            {/*                                                              name="role"*/}
            {/*                                                              render={({input}: { input: any }) => {*/}
            {/*                                                                  return <Select  defaultValue={input.value}*/}
            {/*                                                                                 onChange={(value, option) => {input.onChange(value)}} style={{width: '100%'}}>*/}
            {/*                                                                      {roleList.map((u: any, index: any) => <Select.Option key={index} value={u.value}>{u.label}</Select.Option>)}*/}
            {/*                                                                  </Select>*/}
            {/*                                                              }}*/}
            {/*                                                          />*/}
            {/*                                                      </div>*/}
            {/*                                                  </Col>*/}
            {/*                                              </Row>*/}
            {/*                                      <div className="buttons">*/}
            {/*                                          <Button type="primary" htmlType="submit">*/}
            {/*                                              Submit*/}
            {/*                                          </Button>*/}
            {/*                                      </div>*/}
            {/*                                  </Space>*/}
            {/*                              </form>*/}
            {/*                          )}*/}
            {/*    />*/}
            {/*    }*/}

            {/*</Spin>*/}
        </Modal>
    );
};

export default UserCompaniesEdit;