import React from "react";
import {useLoadFindDriver} from "../../../../Hooks/cleverTms/LoadController";
import {useBrokerFindCompany} from "../../../../Hooks/cleverTms/BrokerController";
import {useFindCity} from "../../../../Hooks/cleverTms/CitiyController";
import {useFindContact} from "../../../../Hooks/cleverTms/Contact";
import {useFindAddress, useFindCustomer} from "../../../../Hooks/cleverTms/CustomerController";
import { useFindTag } from "../../../../Hooks/cleverTms/TagController";
import {useFindZip} from "../../../../Hooks/cleverTms/ZipCodeController";
import {useFindCategory} from "../../../../Hooks/cleverTms/Categoty";
import {useFindDevices} from "../../../../Hooks/cleverTms/Device";
import {useDailiesFindDriver} from "../../../../Hooks/TT_ELD/Dailies";
import {useFindDriverForTMS} from "../../../../Hooks/cleverTms/Driver";

type MyStructure = Object[];
// type OrdersStructure = object{data:MyStructure , time:string, total:number};


export const SearchResultForFindDriverName = async ( orderNumber :string | number,) => {
    const data : any = await useLoadFindDriver(orderNumber);

    const dataFor = [{orderNumber: 'All Load',id: '' }, ...data.data]
    return dataFor.map((el: any) => {
        const category = `${el.orderNumber} `;
        return {
            valId: el.orderNumber,
            value: category,
            key: el.orderNumber,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.orderNumber}
                >
                        <span>
                         Name : {el.orderNumber}
                        </span>
                </div>
            ),
        };
    });
}


export const SearchResultForBroker = async ( name :any,city: string,) => {
    const data: MyStructure = await useBrokerFindCompany(name);
    const dataFor = [{name: 'All Broker',id: '' }, ...data]
    return dataFor.map((el: any) => {
        const category = `${el.name} `;
        return {
            valId: el.name,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                         Name : {el.name}
                        </span>
                </div>
            ),
        };
    });
}
export const SearchResultForCity = async ( name : any, ) => {
    const data: MyStructure = await useFindCity(name);
    const dataFor = [{city: 'All City',id: '', }, ...data]
    return dataFor.map((el: any) => {
        const category = `${el.city}`;
        return {
            valId: el.city,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                            City: {el.city}
                        </span>
                </div>
            ),
        };
    });
}
export const SearchResultForCustomer = async ( name : any, ) => {
    const data: MyStructure = await useFindCustomer(name);
    const dataFor = [{ city: '',id: ''}, ...data]
    return dataFor.map((el: any) => {
        const category = `${el.city}`;
        return {
            valId: el.city,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                            City: {el.city} Stata: {el.state}
                        </span>
                </div>
            ),
        };
    });
}

export const SearchResultForTag = async ( name : string | number | undefined, ) => {
    const data: MyStructure = await useFindTag(name);
    const dataFor = [{ text: 'All Tag' , id: ''}, ...data]
    return dataFor.map((el: any) => {
        const category = `${el.text}`;
        return {
            valId: el.text,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                            Text: {el.text}
                        </span>
                </div>
            ),
        };
    });
}

export const SearchResultForZipCode = async ( name : string | number | undefined, ) => {
    const data: MyStructure = await useFindZip(name);
    const dataFor = [{ zipCode: 'All Zip' , id: ''}, ...data]
    return dataFor.map((el: any) => {
        const category = `${el.zipCode}`;
        return {
            valId: el.id,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                            ZipCode: {el.zipCode}
                        </span>
                </div>
            ),
        };
    });
}

export const SearchResultForCategory = async ( name : string | number | undefined, ) => {
    const data: MyStructure = await useFindCategory(name);
    const dataFor = [{ name: 'All Category' , id: ''}, ...data]
    return dataFor.map((el: any) => {
        const category = `${el.name}`;
        return {
            valId: el.id,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                            Text: {el.name}
                        </span>
                </div>
            ),
        };
    });
}
export const SearchResultForContact = async ( fullName: string,) => {
    const data: MyStructure = await useFindContact(fullName);
    const dataFor = [{fullName: 'All Contacts', id:''}, ...data]
    return dataFor.map((el: any) => {
        const category = ` ${el.fullName}`;
        return {
            valId: el.fullName,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                         Contact: {el.fullName}
                        </span>
                </div>
            ),
        };
    });
}

export const SearchResultForDevice = async ( driverId: string,) => {
    const data: MyStructure = await useFindDevices(driverId);
    const dataFor = [{driverId: 'All driverId', id:''}, ...data]
    return dataFor.map((el: any) => {
        const category = ` ${el.driverId}`;
        return {
            valId: el.id,
            value: category,
            key: el.id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.id}
                >
                        <span>
                         DriverId: {el.driverId}
                        </span>
                </div>
            ),
        };
    });
}


export const SearchAddress = async (address :undefined | number | string) => {
    const data: MyStructure = await useFindAddress(address);
    // const dataFor = [{id: undefined,  role: 'User'}, ...data]
    return data.map((el: any) => {
        const category = `${el.formatted_address}`;

        return {
            valId: el.place_id,
            value: category,
            key: el.place_id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    key={el.place_id}
                >
                        <span>
                            {el.formatted_address}
                        </span>
                </div>
            ),
        };
    });
}


// export const SearchResultForFindDriver = async (companyId: string | number , driverId :string |number) => {
//     const data: MyStructure = await useFindDriverForTMS(companyId, driverId);
//     const dataFor = [{id: undefined, first_name: 'All', second_name: 'Users', role: 'User'}, ...data]
//     return dataFor.map((el: any) => {
//         const category = `${el.first_name + ' ' + el.second_name}`;
//         return {
//             valId: el.id,
//             value: category,
//             key: el.id,
//             label: (
//                 <div
//                     style={{display: 'flex', justifyContent: 'space-between',}}
//                     key={el.id}
//                 >
//                         <span>
//                           {el.first_name + ' ' + el.second_name +' - ' +"CMPN" + " : " + el.companyId}
//                         </span>
//                 </div>
//             ),
//         };
//     })
// }