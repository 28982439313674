import React, {useEffect, useState} from 'react';
import {useUsersData} from "../../../../../Hooks/TT_ELD/Users";
import SearchOptions from "../../../../../Utils/SearchOptions";
import {SearchResultForCompany} from "../../../../../Utils/SearchResults";
import UsersTable from "./UsersTable";
import {Button, Select} from "antd";
import SearchInput from "../../../../../Utils/SearchInput";
import { useLocation } from "react-router-dom";
import useRouteQuery from "../../../../../Utils/useRouterQuery";

type Data = {
    data?: {
        data: Array<any>,
        count: number
    },
    isLoading?: boolean,
    refetch?: any,
    isFetching?: any
}
const {Option} = Select;

const ROLE_DISPATCHER = 'dispatcher'
const ROLE_ADMIN = 'admin'
const ROLE_DRIVER = 'driver'

const Users = () => {
    const location = useLocation();
    const [skip, setSkip] = useState<string | number>(1);
    const [sortedInfo, setSortedInfo] = useState<any>({});
    const [companyId, setCompanyId] = useRouteQuery("", 'companyId', (v) => String(v));
    const [userName, setUserName] = useRouteQuery("", 'userName', (v) => String(v));
    const [driverName, setDriverName] = useRouteQuery("", 'driverName', (v) => String(v));
    const [driverEmail, setDriverEmail] = useRouteQuery("", 'driverEmail', (v) => String(v));
    const [licenseNumber, setLicenseNumber] = useRouteQuery("", 'licenseNumber', (v) => String(v));
    const [role, setRole] = useRouteQuery("driver", 'role', (v) => String(v));
    const [status, setStatus] = useRouteQuery("", 'status', (v) => String(v));

    const {
        data,
        refetch,
        isLoading,
        isFetching
    }: Data = useUsersData(companyId, role, status, driverName, driverEmail, userName, licenseNumber, skip);
    const handleChange = (event:string, inputName:string) => {
        const updateFunctions: Record<string, (value: string) => void> = {
            name: setDriverName,
            email: setDriverEmail,
            username: setUserName,
            license: setLicenseNumber,
        };

        if (updateFunctions[inputName]) {
            updateFunctions[inputName](event.trim());
        }
    };
    const roleList = [
        {
            label: "Dispatcher",
            value: ROLE_DISPATCHER
        },

        {
            label: "Admin ",
            value: ROLE_ADMIN,
        },
        {
            label: "Driver",
            value: ROLE_DRIVER,
        },
    ]

    const onChange = (query: any) => {
        setSkip(query.current);
    }
    useEffect(() => {
        if (sortedInfo?.order) {
            setStatus(sortedInfo?.order)
         }
        }, [sortedInfo, location.pathname])


    return (
        <div className='mainPage'>
            <span style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between',}}>
                <SearchInput value={driverName} placeholder="Search by name" onDebounceChange={(event:string) => handleChange(event, 'name')}/>
                <SearchInput value={driverEmail} placeholder="Search by email" onDebounceChange={(event:string) => handleChange(event, 'email')} />
                <SearchInput value={userName} placeholder="Search by userName" onDebounceChange={(event:string) => handleChange(event, 'username')} />
                <SearchInput value={licenseNumber} placeholder="Search by license" onDebounceChange={(event:string) => handleChange(event, 'license')} />
                <SearchOptions SearchResult={SearchResultForCompany}
                                onSelect={(value: any, {valId}: { valId: any }) => {
                                    if(valId){setCompanyId(valId);}
                                }} placeholder="Search by company " value={''}/>
                <Select value={role ? role : 'all'} defaultValue="all" onChange={(value:any, option) => {
                    setRole(value === 'all' ? undefined : value)
                }} style={{width: 200}}>
                    <Option key={'all'}>All Users</Option>
                    {roleList.map((u: any, index: any) => <Option key={index} value={u.value}>{u.label}</Option>)}
                </Select>
                <Button style={{marginLeft: 'auto'}} size={'large'} onClick={refetch}>Refresh</Button>
            </span>

            <UsersTable data={data?.data} total={data?.count}  isLoading={isLoading}
                        isFetching={isFetching} setSortedInfo={setSortedInfo} sortedInfo={sortedInfo} setSkip={setSkip}
                        refetch={refetch} onChange={onChange} pagination={false}/>
        </div>
    )
};

export default Users;