import instance from "../../api";

export const devicesEld = {
    async read(userId:string|number,model:string|number, appVersion:string|number,versionCode:string|number,device:string|undefined, sort: boolean|undefined|string, skip: number | undefined) {

        const {data}: any = await instance(`devices/list?page=${skip}&perPage=10&userId=${userId}&searchModel=${model}&isActive=${sort}&searchDeviceType=${device}&searchAppVersion=${appVersion}&searchVersionCode=${versionCode}`);
        return {
            data: data.data,
            count: data.count
        };

    },

    async deviceFinder(name: undefined | number | string) {
        const {data}: any = await instance(`devices/list?perPage=10&userId=${name}`);
        return data?.data;
    },

    async deviceFinderName(name: string) {
        const {data}: any = await instance(`users/searching-list?&searchName=${name}&driver=true`);
        return data?.data;
    },
}