import React, {useEffect, useState} from 'react';
import {useLastTrucking} from "../../../../../../Hooks/TT_ELD/Logs";
import {Button, Card, Descriptions, Modal, Spin, Tag} from "antd";
import moment from "moment";
import {useParams} from "react-router-dom";

interface Pt30 {
    geolocation_age:       number;
    velocity:              number;
    datetime:              string;
    engine_hours:          number;
    sequence_number:       number;
    live_event:            boolean;
    odometer:              number;
    event_tag:             string;
    geolocation_longitude: number;
    rpm:                   number;
    geolocation_heading:   number;
    satelite_count:        number;
    event_type:            string;
    geolocation_latitude:  number;
    geolocation_speed:     number;
    eld_address:           string;
}
interface debugInfo {
    appVersion:                   string;
    eldConnected:                 boolean;
    permissionLocationAlways:     boolean;
    lastLogId:                    string;
    pt30:                         Pt30;
    blueOn:                       boolean;
    appState:                     string;
    lastInApp:                    string;
    currentPage:                  string;
    lastGpsUpdate:                string;
    deviceModel:                  string;
    phoneId:                      string;
    networkQuality:               string;
    eldAddress:                   string;
    lastChangeConnection:         string;
    deviceType:                   string;
    gpsEnabled:                   boolean;
    permissionLocationWhileUsing: boolean;
    permissionLocation:           boolean;
    batteryLevel:                 number;
}
interface Trucking {
     data?: {
         data: Data,
         count: number
     },
     isLoading?: boolean,
     refetch?: () => void,
     isFetching?:boolean
}
interface Data {
    address:         string;
    coordinates:     Coordinates;
    date:            string;
    rotation:        number;
    speed:           number;
    delta_distance:  number;
    engine_hours:    number;
    state:           string;
    vehicleId:       number;
    odometr:         number;
    source:          string;
    is_live:         boolean;
    vin_number:      string;
    debug_info:      string;
    companyId:       number;
    gps_coordinates: Coordinates;
}
interface Coordinates {
    lat: number;
    lng: number;
}
interface Params {
    id: string | undefined;
    [key: string]: string | undefined;
}

const LastTracking = () => {
    const [parsedData, setParsedData] = useState<any>();
    const {id} = useParams<Params>();
    const {data,refetch, isLoading ,isFetching}: Trucking = useLastTrucking(id)
    let time =moment(data?.data?.date).format("YYYY-MM-DD, h:mm:ss a")
    const items2 = [
        { label: "App Version", value: parsedData?.appVersion },
        { label: "Engine Hours" , value: parsedData?.pt30 ? parsedData?.pt30?.engine_hours : parsedData?.iosIx?.engineHours},
        { label: "Odometer", value: parsedData?.pt30 ? parsedData?.pt30?.odometer : parsedData?.iosIx?.odometer},
        { label: parsedData?.pt30 ? "Velocity" : 'Voltage', value: parsedData?.pt30 ? parsedData?.pt30?.velocity : parsedData?.iosIx?.voltage },
        { label: "App State", value: parsedData?.appState },
        { label: "Battery Level", value: parsedData?.batteryLevel },
        { label: "Current Page", value: parsedData?.currentPage },
        { label: "Device Model", value: parsedData?.deviceModel },
        { label: "Device Type", value: parsedData?.deviceType },
        { label: "ELD Connected", value: parsedData?.eldConnected},
        { label: "ELD Address", value: parsedData?.eldAddress },
        { label: "Last Change Connection", value: parsedData?.lastChangeConnection },
        { label: "Last Gps Update", value: parsedData?.lastGpsUpdate },
        { label: "Last In App", value: parsedData?.lastInApp },
        { label: "Last Log ID", value: parsedData?.lastLogId },
        { label: "Network Quality", value: parsedData?.networkQuality },
        { label: "Phone ID", value: parsedData?.phoneId },
        { label: parsedData?.pt30 ? "Date Time" : 'Course', value: parsedData?.pt30 ?  parsedData?.pt30?.datetime : parsedData?.iosIx?.course},
        { label: parsedData?.pt30 ? "Eld Address" : 'Dop', value: parsedData?.pt30 ? parsedData?.pt30?.eld_address : parsedData?.iosIx?.dop},
        { label: parsedData?.pt30 ? "Event Tag":'Firmware Version', value:parsedData?.pt30 ? parsedData?.pt30?.event_tag : parsedData?.iosIx?.firmwareVersion},
        { label: parsedData?.pt30 ? "Event Type" : 'GPS Date Time', value: parsedData?.pt30 ? parsedData?.pt30?.event_type : parsedData?.iosIx?.gpsDateTime},
        { label: parsedData?.pt30 ? "Geolocation Age" :'GPS Speed', value: parsedData?.pt30 ? parsedData?.pt30?.geolocation_age : parsedData?.iosIx?.gpsSpeed},
        { label: parsedData?.pt30 ? "Geolocation Heading" : 'Latitude', value: parsedData?.pt30 ? parsedData?.pt30?.geolocation_heading : parsedData?.iosIx?.latitude},
        { label: parsedData?.pt30 ? "Geolocation Latitude" : 'Longitude', value:parsedData?.pt30 ? parsedData?.pt30?.geolocation_latitude : parsedData?.iosIx?.longitude},
        { label: parsedData?.pt30 ? "Geolocation Longitude" : 'MST Alt', value:parsedData?.pt30 ? parsedData?.pt30?.geolocation_longitude : parsedData?.iosIx?.mstAlt},
        { label: parsedData?.pt30 ? "Geolocation Speed" : 'Num Sats', value: parsedData?.pt30 ? parsedData?.pt30?.geolocation_speed : parsedData?.iosIx?.numSats},
        { label: "RPM", value: parsedData?.pt30 ? parsedData?.pt30?.rpm : parsedData?.iosIx?.rpm},
        { label: parsedData?.pt30 ? "Satelite Count" : 'Sequence', value: parsedData?.pt30 ? parsedData?.pt30?.satelite_count : parsedData?.iosIx?.sequence},
        { label: parsedData?.pt30 ? "Sequence Number" : 'Speed', value: parsedData?.pt30 ? parsedData?.pt30?.sequence_number : parsedData?.iosIx?.speed},
        { label: "BlueOn", value: parsedData?.blueOn },
        { label: "GPS Enabled", value: parsedData?.gpsEnabled},
        { label: "Permission Location", value: parsedData?.permissionLocation},
        { label: "Permission Location Always", value: parsedData?.permissionLocationAlways},
        { label: "Permission Location While Using", value: parsedData?.permissionLocationWhileUsing},
        { label: parsedData?.pt30 ? "Live event" : 'Trip Distance:', value:parsedData?.pt30 ? parsedData?.pt30?.live_event : parsedData?.iosIx?.tripDistance},
    ];
    const items1 = [
        { label: "Rotation", value: data?.data?.rotation },
        { label: "Speed", value: data?.data?.speed },
        { label: "Delta Distance", value: data?.data?.delta_distance },
        { label: "Engine Hours", value: data?.data?.engine_hours },
        { label: "State", value: data?.data?.state },
        { label: "Odometer", value: data?.data?.odometr },
        { label: "Source", value: data?.data?.source },
        { label: "Time", value: time },
        { label: "Is Live", value: data?.data?.is_live },
        { label: "Latitude", value: data?.data?.coordinates.lat },
        { label: "Longitude", value: data?.data?.coordinates.lng },
    ];
    let info:string
   if(parsedData?.pt30){
       info = '( PT 30 )'
   }else if(parsedData?.iosIx){
       info = '( IOSiX )'
   }else {
       info =''
   }
    useEffect(() => {
        try {
            const companyIdData = data?.data;
            if (companyIdData && companyIdData.debug_info) {
                const parsedData = JSON.parse(companyIdData.debug_info);
                setParsedData(parsedData);
            } else {
                // Данных нет или поле debug_info отсутствует
                console.error('Нет данных для разбора JSON');
            }
        } catch (error:any) {
            console.error('Ошибка разбора JSON:', error.message);
        }
    }, [data]);

    return (
        <div>
            <Spin size='large' spinning={isLoading || isFetching}>
                <Card extra={
                    <Button style={{ marginLeft:'auto'}} size={'large'} onClick={refetch}>Refresh</Button>}>
                    <Descriptions title="Tracking" bordered>
                        {items1.map((item, index) => (
                            <Descriptions.Item key={index} label={item.label}>
                                {typeof item.value === 'boolean' ? <Tag color={item.value ? 'geekblue' : 'red'}>
                                    {item.value ? 'True' : 'False'}
                                </Tag>: item.value}
                            </Descriptions.Item>
                        ))}
                    </Descriptions>
                </Card>
                <Card style={{marginTop:'10px'}}>
                    <Descriptions title={'Debug Info ' + info}  bordered>
                        {items2.map((item, index) => (
                            <Descriptions.Item key={index} label={item.label}>
                                {typeof item.value === 'boolean' ? (
                                    <Tag color={item.value ? 'geekblue' : 'red'}>
                                        {item.value ? 'True' : 'False'}
                                    </Tag>
                                ) : item.value}
                            </Descriptions.Item>
                        ))}
                    </Descriptions>
                </Card>
                <Card>{JSON.stringify(data?.data?.debug_info )}</Card>
            </Spin>
        </div>
    );
};

export default LastTracking;