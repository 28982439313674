import React, {useState} from 'react';
import {Form as FormAnt, InputNumber, Modal} from "antd";
import {logs} from "../../../../../../API/TT_ELD/logs";

const OpenNewDay = ({
                         open,
                         setOpen,
                         refetch,
                        driverId
                     }: { open: boolean, setOpen(open: boolean): void, refetch(): void ,driverId:number|string|undefined}) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const handleCancel = () => {
        setOpen(!open)
    }
    const [form] = FormAnt.useForm();
    return (
        <>
            <Modal
                open={open}
                title="Open new day"
                okText="Submit"
                cancelText="Cancel"
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
                onOk={() => {
                    form
                        .validateFields()
                        .then( async (values) => {
                            setConfirmLoading(true);
                            form.resetFields();
                            setTimeout(() => {
                                setOpen(false);
                                setConfirmLoading(false);
                            }, 2000);
                            await logs.openNewDay(values ,driverId)
                            setOpen(!open)
                            refetch()
                        })
                        .catch((info) => {});
                }}
            >
                <FormAnt
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{ days:2}}
                    autoComplete="off"
                >
                    <FormAnt.Item
                        name="days"
                        label="Days"
                        rules={[{ required: true }, { type: 'number', warningOnly: true }, { type: 'number', min: 1 , max:30, message:'Please enter a number between 1 and 30' }]}
                    >
                        <InputNumber placeholder="Enter your Day" style={{ width: '100%' }}  />
                    </FormAnt.Item>
                </FormAnt>
            </Modal>
        </>
    );
};

export default OpenNewDay;