import React from 'react';
import {SettingOutlined} from "@ant-design/icons";
import {Tabs} from "antd";
import FMCSA_review from "./FMCSA_review";
import FMCSA_check from "./FMCSA_check";


const Fmcsa = () => {
    const items = [
        {
            label: <span>Unchecked</span>,
            key: '1',
            children: <FMCSA_review/>,
            icon: <SettingOutlined rev={undefined}/>
        },
        {
            label: <span>Checked</span>,
            key: '2',
            children: <FMCSA_check/>,
            icon: <SettingOutlined rev={undefined}/>
        },
    ]

    return (
        <div className='mainPage'>
            <Tabs
                type="card"
                defaultActiveKey={'1'}
                style={{height: '100%'}}
                items={items.map((tab) => {
                    return {
                        label: tab.label,
                        key: tab.key,
                        children: tab.children,
                        icon: tab.icon
                    };
                })}
            />
        </div>

    );
};

export default Fmcsa;