import React, {useState} from 'react';
import {useDeviceData} from "../../../../Hooks/cleverTms/Device";
import DeviceTable from "./DeviceTable/DeviceTable";
import { SearchResultForDevice} from "../UtilsTMS/SearchResults";
import SearchOptions from "../../../../Utils/SearchOptions";
import {SearchResultForCompany} from "../../../../Utils/SearchResults";
import {Link} from "react-router-dom";
import {Button} from "antd";



type Data = {
    data?: {
        data: Array<any>,
        count: number
    },
    isLoading?: boolean,

}
const Contact = () => {
    const [driverId, setDriverId] = useState<string | number | undefined>('')
    const [companyId, setCompanyId] = useState<undefined | number | string>('');
    const {data, isLoading}: Data = useDeviceData(driverId,companyId);


    const onChange = (query: any) => {
        // setSkip(10 * (parseInt(query.current) - 1));
    }

    return (
        <div className='mainPage'>
            <SearchOptions SearchResult={SearchResultForCompany}
                    onSelect={(value: any, {valId}: {valId: number | undefined}) =>
                    {setCompanyId(valId);}}
                    placeholder={'Search by company'}  value={''}/>
            <SearchOptions SearchResult={SearchResultForDevice}
                    onSelect={(value: any, {valId}: { valId: string | number | undefined }) => {
                        setDriverId(valId);
                    }} placeholder="Driver Search" value={''}/>
            <Link to={'add-device'}>
                <Button type="primary" style={{float: "right"}} size={'large'}>
                    Add Device
                </Button>
            </Link>
            <DeviceTable device={data?.data} total={data?.count} onChange={onChange} isLoading={isLoading} />
        </div>
    );
};

export default Contact;