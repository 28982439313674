import {useQuery} from "react-query";
import {topErrorsCompany} from "../../../API/TT_ELD/topErrorsCompany";


export const useTopErrorsData = (companyId: undefined | number, sort: boolean | undefined, skip: undefined | number): any => {
    return useQuery([
            `topErrors/getcompanies${companyId}`, companyId, sort, skip],
        () => topErrorsCompany.read(companyId, sort, skip),
        {refetchOnWindowFocus: false})

}

export const useGetEightDayErrors = (): object => {
    return useQuery([
            `supportsGetLogsById/${ 'all'}`,],
        () => topErrorsCompany.getEightDayErrors(),
        {refetchOnWindowFocus: false})
}