import {useQuery} from "react-query";
import {approval} from "../../../API/TT_ELD/approval";

export const useApproval = (userName: string, userEmail: string, companyId:string |number, skip:string | number): any => {
    return useQuery([
            `approvalUsers/${userName || 'all'}`, userName, userEmail, companyId, skip],
        () => approval.read(userName, userEmail, companyId, skip),
        {refetchOnWindowFocus: false, refetchInterval: 5 * 60 * 1000})
}
export const useRejected = (userName: string, userEmail: string, companyId:string |number, skip:string | number): any => {
    return useQuery([
            `approvalUsers/${ userName || 'all'}`,userName, userEmail, companyId ,skip],
        () => approval.rejectedList(userName, userEmail, companyId, skip),
        { refetchOnWindowFocus: false, refetchInterval: 5 * 60 * 1000 })
}
export const useApprovalCount = (): any => {
    return useQuery([
            `approvalCount/all`],
        () => approval.approvalCount(),
        {refetchOnWindowFocus: false, refetchInterval: 5 * 60 * 1000})
}