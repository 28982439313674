export const enUS_title = {
  'title.login': 'Login',
  'title.dashboard': 'Dashboard',
  'title.documentation': 'Documentation',
  'title.guide': 'Guide',
  'title.permission.route': 'Route Permission',
  'title.permission.button': 'Button Permission',
  'title.permission.config': 'Permission Config',
  'title.account': 'Account',
  'title.notFount': '404',


  'title.approval': 'Approval',
  'title.rejected-users': 'RejectedUsers',
  'title.fmcsa': 'FMCSA',
  'title.contacts': 'Contact',
  'title.logsEdit': 'LogsEdit',
  'title.userCompanies': 'UserCompanies',
  'title.supports': 'Supports',
  'title.users': 'Users',
  'title.dispatcher': 'Dispatcher',
  'title.user/:id': 'User/:id',
  'title.logs/:id': 'Logs/:id',
  'title.companies': 'Companies',
  'title.disabledCompany': 'DisabledCompany',
  'title.companyEdit': 'CompanyEdit',
  'title.owners': 'Owners',
  'title.vehicles': 'Vehicles',
  'title.vehicleEdit': 'VehicleEdit',
  'title.makes': 'Makes',
  'title.makeEdit': 'MakeEdit',
  'title.models': 'Models',
  'title.modelEdit': 'ModelEdit',
  'title.documents': 'Documents',
  'title.eld': 'ELD Models',
  'title.logbook': 'LogBook',
  'title.company-errors': 'CompanyErrors',
  'title.topErrors': 'TopErrors',
  'title.topErrors/:id': 'TopErrors/:id',
  'title.top-errors': 'TopErrors',
  'title.histories': 'Histories',
  'title.serverConfiguration': 'ServerConfiguration',
  'title.devicesEld': 'DevicesEld',
  'title.system': 'System',
  'title.subscription': 'Subscription',
  'title.deviceVersion': 'DeviceVersion',
  'title.check-stripe': 'CheckStripe',
  'title.provider': 'Provider',
  'title.builds': 'Builds',
  'title.provider-activity': 'ProviderActivity',
  'title.provider-request': 'ProviderRequest',

  'title.load': 'Load',
  'title.loadEdit': 'LoadEdit',
  'title.addLoad': 'AddLoad',
  'title.broker': 'Broker',
  'title.brokerEdit': 'BrokerEdit',
  'title.addBroker': 'AddBroker',
  'title.city': 'City',
  'title.cityEdit': 'CityEdit',
  'title.addCity': 'AddCity',
  'title.location': 'Location',
  'title.locationEdit': 'LocationEdit',
  'title.addLocation': 'AddLocation',
  'title.tag': 'Tag',
  'title.tagEdit': 'TagEdit',
  'title.addTag': 'AddTag',
  'title.zipCode': 'ZipCode',
  'title.zipCodeEdit': 'ZipCodeEdit',
  'title.addZipCode': 'AddZipCode',
  'title.category': 'Category',
  'title.categoryEdit': 'CategoryEdit',
  'title.addCategory': 'AddCategory',
  'title.contact': 'Contact',
  'title.contactEdit': 'ContactEdit',
  'title.addContact': 'AddContact',
  'title.device': 'Device',
  'title.addDevice': 'AddDevice',
  'title.deviceEdit': 'DeviceEdit',
  'title.report': 'Report',


};
