import instanceDash from "../apiRead";
import instance from "../api";

export const topErrorsCompany ={
    async read(companyId: number | undefined, sort: boolean | undefined, skip: number | undefined) {
        const {data}: any = await instance(`logs/get-top-errors?page=${(JSON.stringify(skip))}`);
        return {
            data: data.data,
            count: data.count
        };
    },

    async getEightDayErrors() {
        const { data } = await instance(`logs/get-errors`);
        return {
            data: data.data,
            count: data.count
        };
    },
}