import {useQuery} from "react-query";
import {deviceController} from "../../../API/Tms/device";

export const useDeviceData = (driverId: string | number | undefined,companyId:undefined| number| string): object => {
    return useQuery([`admin/devices${driverId}`, driverId, companyId],
        () => deviceController.read(driverId,companyId),
        {refetchOnWindowFocus: false}
    );
};

export const useDeviceOneData = (companyId: number | string | undefined): any => {
    return useQuery(
        [`admin/contactsOne/${companyId || "all"}`, companyId],
        () => deviceController.deviceCompanyOne(companyId),
        {refetchOnWindowFocus: false}
    );
};

export const useFindDevices = async (id :any) => {
    return await deviceController.deviceFinderId(id)
}