import instance from "../../api";
import {message} from "antd";

export const system = {
    async read() {
        const {data}: any = await instance(`users/redisconfigs`);
        return {
            data: data?.data,
            count: data.count
        };
    },
    async addSystem(body: any) {
        message.loading({content: 'Loading...', key: body});
        const {data} = await instance(`users/redisconfigset?type=${body.type}&mode=${body.mode}`, {
            method: 'POST',
        }).then(u => {
            setTimeout(() => {
                message.success({content: 'Loaded!', key: body, duration: 2});
            }, 1000);
            return u;
        });
        return data;
    },
    async editSystem(body: any) {
        message.loading({content: 'Loading...', key: body});
        let title: any
        let mode: any
        const date = Object.keys(body).map((item, index) => {
            title = item
            mode = body[item]
        })

        if(title !== undefined){
            const {data} = await instance(`users/redisconfigset?type=${title}&mode=${mode}`, {
                method: 'POST',
            }).then(u => {
                setTimeout(() => {
                    message.success({content: 'Loaded!', key: body, duration: 2});
                }, 1000);
                return u;
            });
            return data;
        }

    },

}