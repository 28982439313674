import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Col, Space, Select, Spin, Tabs, Card, Row, Input} from "antd";
import {useGetRole, useUserData} from "../../../../../Hooks/TT_ELD/UserCompanies";
import {Form, Field} from "react-final-form";
import {userCompany} from "../../../../../API/TT_ELD/userCompany";
import {AppleOutlined} from "@ant-design/icons";
const TabPane = Tabs.TabPane;

type Data = {
    data?: {
        data: Array<any>,
        count: number
    }
    isLoading?: boolean,
    isFetching?: boolean,
    refetch?: any
}
type params = {
    readonly id: any
}

interface Values {
    role?: string;
}


const UserCompaniesEdit = () => {
    let navigate = useNavigate();
    const {id} = useParams<params>();
    const {data, refetch, isLoading, isFetching}: Data = useUserData(id)
    const getRole:any = useGetRole()

    const roleList = getRole?.data?.data ? Object.keys(getRole.data.data).map(key => ({
        label: getRole.data[key],
        value: key
    })) : [];
    const onSubmit = async (values: Values) => {
        navigate(-1)
        await userCompany.userCompanyPatch(values);
        refetch()
    };

    return (
        <Card>
            <Spin size="large" spinning={isLoading || isFetching}>
                {data?.data && <Form onSubmit={onSubmit}
                                      initialValues={{...data?.data,}}
                                      render={({handleSubmit, values}) => (
                                          <form onSubmit={handleSubmit}>
                                              <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                                  <Tabs defaultActiveKey="1">
                                                      <TabPane
                                                          tab={
                                                              <span>

                                          MAIN FIELDS
                                           </span>
                                                          }
                                                          icon={<AppleOutlined rev={undefined}/>}
                                                          key="1"
                                                      >
                                                          <Row>
                                                              <Col span={6}>
                                                                  <div>
                                                                      <label>Role</label>
                                                                      <Field
                                                                          name="role"
                                                                          render={({input}: { input: any }) => {
                                                                              return <Select defaultValue={input.value}
                                                                                             onChange={(value, option) => {input.onChange(value)}} style={{width: '100%'}}>
                                                                                  {roleList.map((u: any, index: any) => <Select.Option key={index} value={u.value}>{u.label}</Select.Option>)}
                                                                              </Select>
                                                                          }}
                                                                      />
                                                                  </div>
                                                              </Col>
                                                          </Row>
                                                      </TabPane>
                                                  </Tabs>
                                                  <div className="buttons">
                                                      <Button type="primary" htmlType="submit">
                                                          Submit
                                                      </Button>
                                                  </div>
                                              </Space>
                                          </form>
                                      )}
                />
                }

            </Spin>
        </Card>
    );
};

export default UserCompaniesEdit;