import React, {useState} from 'react';
import {useBrokerData} from "../../../../Hooks/cleverTms/BrokerController";
import BrokerControllerTable from "./BrokerControllerTable/BrokerControllerTable";
import {SearchResultForBroker} from "../UtilsTMS/SearchResults";
import SearchOptions from "../../../../Utils/SearchOptions";
import {SearchResultForCompany} from "../../../../Utils/SearchResults";
import {Link} from "react-router-dom";
import {Button} from "antd";


type Data = {
    data?: {
        data: Array<any>,
        count: number
    },
    isLoading?:boolean
}
const BrokerController = () => {


    const [name, setName] = useState<string | number | undefined>('');
    const [companyId, setCompanyId] = useState<undefined | number>();

    const {data,isLoading}: Data = useBrokerData(name, companyId);
    // const {data}: Data = useBrokerFindCompany(companyId);
    const onChange = (query: any) => {
        // setSkip(10 * (parseInt(query.current) - 1));
    }
    return (
        <div className='mainPage'>
            <SearchOptions SearchResult={SearchResultForCompany}
                    onSelect={(value: any, {valId}: { valId: number | undefined }) => {
                        setCompanyId(valId);
                    }}
                    placeholder={'Search by company'}  value={''}/>
            <SearchOptions SearchResult={SearchResultForBroker}
                    onSelect={(value: any, {valId}: { valId: string | number | undefined }) => {
                        setName(valId === undefined ? '' : valId);
                    }} placeholder="Search by name"  value={''}/>
            <Link to={'add-broker'}>
                <Button type="primary" style={{float: "right"}} size={'large'}>
                    Add Broker
                </Button>
            </Link>
            <BrokerControllerTable controller={data?.data} total={data?.count} onChange={onChange} isLoading={isLoading}/>
        </div>
    );
};

export default BrokerController;