import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Col, Form as FormAnt, Input, Row, Select, Space, Spin, Tabs} from "antd";
import Notfound from "../../../../../Utils/Notfound";
import {useLoadFindOne} from "../../../../../Hooks/cleverTms/Driver";
import {AppleOutlined} from "@ant-design/icons";

const TabPane = Tabs.TabPane;

interface Params {
    id: string | undefined;
    [key: string]: string | undefined;
}
type Data = {
    data?: {
        data: Array<any>;
        count: number;
    };
    isLoading?: boolean,
    refetch?: any,
    isFetching?: any,
    status?: boolean | string

};

const LoadHistoryEdit = () => {

    const {id} = useParams<Params>();
    const {data, refetch, isFetching, isLoading, status}: Data = useLoadFindOne(id)
    const [loadings, setLoadings] = useState<boolean[]>([]);
    let navigate = useNavigate();

    const onSubmit = async (value: any) => {

        navigate(-1)
        refetch()
    };

    const enterLoading = (index: number) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });

        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 6000);
    };
    return (
        <div>
            {status === 'loading' ? <Spin size="large" spinning={!data}/> : data ? (
                <Spin size="large" spinning={!data}>
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{display: "flex"}}
                    >
                        <Tabs defaultActiveKey="1">
                            <TabPane
                                tab={
                                    <span>
                          <AppleOutlined rev={undefined}/>
                          MAIN FIELDS
                        </span>
                                }
                                key="1"
                            >
                                <Space
                                    direction="vertical"
                                    size="middle"
                                    style={{display: "flex"}}
                                >
                                    <FormAnt
                                        name="basic"
                                        layout="vertical"
                                        wrapperCol={{span: 16}}
                                        initialValues={{...data}}
                                        onFinish={onSubmit}
                                        autoComplete="off"
                                    >

                                        <Row gutter={[16, 10]}>
                                            <Col span={6}>
                                                <FormAnt.Item
                                                    wrapperCol={{span: '100%'}}
                                                    label="driverId"
                                                    name="driverId"

                                                >
                                                    <Input/>
                                                </FormAnt.Item>
                                            </Col>
                                            <Col span={6}>
                                                <FormAnt.Item
                                                    wrapperCol={{span: '100%'}}
                                                    label="companyId"
                                                    name="companyId"

                                                >
                                                    <Input/>
                                                </FormAnt.Item>
                                            </Col>


                                        </Row>

                                        <FormAnt.Item>
                                            <Button type="primary" htmlType="submit" >
                                                Submit
                                            </Button>
                                        </FormAnt.Item>
                                    </FormAnt>
                                </Space>
                            </TabPane>
                        </Tabs>
                    </Space>
                </Spin>

            ) : <Notfound/>}
        </div>
    );
};

export default LoadHistoryEdit;