import React, { useContext, useEffect, useRef, useState } from 'react';
import {Button, Form, Input, InputRef, Modal, Space, Spin, Switch, Table, Typography} from "antd";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined, LockFilled } from "@ant-design/icons";
import { getDuration } from "../../../../../Utils/data";
import moment from "moment/moment";
import { useQueryClient } from "react-query";
import BulkEditModal from "../../User/Users/Logs/BulkEditModal";
import { useUserData } from "../../../../../Hooks/TT_ELD/Users";
import type { FormInstance } from 'antd/es/form';
import { logs } from "../../../../../API/TT_ELD/logs";
import { useLogsData } from "../../../../../Hooks/TT_ELD/Logs";

type numStr = number | string;
type strUnd = string | undefined | null;
const {Paragraph} = Typography

interface logDataSource {
    no: numStr;
    id: numStr;
    status: string | null;
    start_date: { start_date: strUnd, id: numStr };
    endDate: strUnd;
    duration: undefined | string;
    address: {address:string ,lat: number |string , lng:number |string};
    odometer: numStr | undefined;
    engineHours: numStr | undefined;
    note: strUnd;
    document: numStr;
    createdAt: strUnd;
    updatedAt: strUnd;
    // trailer: numStr;
    actions: { id: numStr,companyId:string, queryClient: any, userId: string | undefined, record_status: number | undefined | null, inspection: boolean };
    key: React.Key;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
    key: string;
    name: string;
    age: string;
    address: string;
}

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
                                                       title,
                                                       editable,
                                                       children,
                                                       dataIndex,
                                                       record,
                                                       handleSave,
                                                       ...restProps
                                                   }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const queryClient: any = useQueryClient();

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
            const getKey: any = [record.key]
            // await logs.updateMany(values, getKey, 'log');
            queryClient.refetchQueries(useLogsData);

        } catch (errInfo) {

        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ?
            (
            <Form.Item
                style={{margin: 0}}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const LogsTable = ({
                       logs_data,
                       isLoading,
                       userId,
                       recordStatus,
                       isFetching,
                       refetch,
                       open,
                       setOpen,
                       setIsModalVisible,
                       todayDate
                   }: { logs_data: any, isLoading: boolean | undefined, userId: string | undefined, recordStatus: number, isFetching: boolean | undefined, refetch: any, open:any,setOpen(open:any): void ,setIsModalVisible:any, todayDate:string}) => {


    const column: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'no',
            dataIndex: 'no',
            key: 'no',
            width: 50,
        },
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            width: 120,
        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
            // editable: true,
            width: 120,
        },
        {
            title: 'start_date',
            dataIndex: 'start_date',
            key: 'start_date',
            // editable: true,
            width: 220,
        },
        {
            title: 'endDate',
            dataIndex: 'endDate',
            key: 'endDate',
            // editable: true,
            width: 200
        },
        {
            title: 'duration',
            dataIndex: 'duration',
            key: 'duration',
            width: 100
        },
        {
            title: 'location',
            dataIndex: 'address',
            key: 'address',
            width: 220,
            render: ({address , lat, lng}:{address: string , lat:number |string |undefined |any, lng:number |string |undefined}) => {
                return (
                    <>
                        {
                            address ? <Paragraph style={{marginBottom:0}} copyable={{ text:`${lat}, ${lng}`}}>{address}</Paragraph> : ''

                        }
                    </>

                )
            }
        },
        {
            title: 'Odometer',
            dataIndex: 'odometer',
            key: 'odometer',
            width: 100
        },
        {
            title: 'engine_hours',
            dataIndex: 'engineHours',
            key: 'engineHours',
            width: 120

        },
        {
            title: 'note',
            dataIndex: 'note',
            key: 'note',
            width: 100
        },
        {
            title: 'document',
            dataIndex: 'document',
            key: 'document',
            width: 110
        },

        {
            title: 'created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 200

        },
        {
            title: 'updatedAt',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: 200
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right',
            width: 120,
            render: ({
                         id,
                         companyId,
                         record_status,
                         inspection
                     }: { id: number | string,companyId:string, queryClient: any, userId: string |undefined, record_status: number | undefined | null, inspection: boolean }) => {
                return <Space>
                    <Link to={record_status !== 0 ? `log/${id}/${companyId}` : `logzeros/${id}/${companyId}`}><Button>Edit</Button></Link>
                    <Button  onClick={() => {
                        Modal.confirm({
                                title: 'Confirm',
                                icon: <ExclamationCircleOutlined rev={undefined}/>,
                                content: 'Do you want to delete this log?',
                                okText: 'Ok',
                                cancelText: 'cancel',
                                onOk: async () => {
                                    await logs.updateLogs({id, record_status: 2}, companyId ,record_status !== 0 ? 'log' : 'logzeros');
                                    refetch()
                                }
                            }
                        );
                    }
                    }
                    >Delete
                    </Button>
                    {inspection && <LockFilled style={{color: 'green', fontSize: '16px'}} rev={undefined}/>}

                </Space>
            },
        },
    ];
    const {data} = useUserData(userId);
    const queryClient: any = useQueryClient();
    const [getKey, setGetKey] = useState<React.Key[]>([])
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: logDataSource[]) => {
            if (selectedRows.length > 0) {
                setIsModalVisible(true)
            } else {
                setIsModalVisible(false)
            }
            setGetKey(selectedRowKeys)
        },
    };

    const handleSave = async (row: any) => {
        // const newData = [...dataSource];
        // const index = newData.findIndex((item) => row.key === item.key);
        // const item = newData[index];
        // newData.splice(index, 1, {
        //     ...item,
        //     ...row,
        // });
        // setDataSource(newData);
        try {
            // const values = await form.validateFields();
            // toggleEdit();
            // handleSave({...record, ...values});
            // console.log(value)
        } catch (errInfo) {

        }

    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = column.map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });


    return (
        <div>
            <Spin size="large" spinning={isLoading || isFetching}>
                {
                    data && <BulkEditModal getKey={getKey} recordStatus={recordStatus} data2={data} refetch={refetch} open={open}
                                           setOpen={setOpen}
                                           companyId={logs_data?.find((el: any) => el.companyId)?.companyId} todayDate={todayDate}/>
                }
                <Table size='small'
                    dataSource={logs_data?.map((u: any, i: number): logDataSource => {
                        let create = u.createdAt
                        let updated = u.updatedAt
                        const obj: logDataSource = {
                            no: i + 1,
                            id: u.id,
                            status: u.status,
                            start_date: u.start_date,
                            endDate: u.end_date,
                            duration: getDuration(u.start_date, u.end_date),
                            address: {address: u.address, lat:u?.coordinates?.lat , lng:u?.coordinates?.lng},
                            odometer: u.odometr,
                            engineHours: u.engine_hours,
                            note: u.note,
                            document: u.document,
                            createdAt: create ? moment(create).format("YYYY-MM-DD, h:mm:ss a") : "",
                            updatedAt: updated ? moment(updated).format("YYYY-MM-DD, h:mm:ss a") : "",
                            actions: {
                                id: u.id,
                                companyId: u?.companyId,
                                queryClient,
                                userId,
                                record_status: u.record_status,
                                inspection: u.inspection
                            },
                            key: u.id
                        }
                        return obj;
                    })} columns={columns}
                    components={components}
                    rowClassName={() => 'editable-row'}
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    scroll={{x: 1500}}
                    sticky
                />
            </Spin>
        </div>
    );
};

export default LogsTable;