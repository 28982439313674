import React, {useState} from 'react'
import {Button, Col, Input, Modal, Select, Space, Switch} from "antd";
import {Field, Form} from "react-final-form";
import {categoryController} from "../../../../../API/Tms/category";


type numStr = number | string;

interface Fields {
    no: numStr,
    name: numStr,
    type: numStr,
    updatedAt: numStr,
    createdAt: numStr,
    isActive: boolean;
    companyId: numStr
    action: string;
}

const {Option} = Select;

const AddCategory = ({
                         isModalVisible,
                         setIsModalVisible,
                         companyId,
                         refetch
                     }: { isModalVisible: boolean, setIsModalVisible(val: boolean): void, companyId: any, refetch(): void }) => {
    // const [companyId, setCompanyId] = useState<undefined | number | string>('');

    const onSubmit = async (data: Fields) => {
        data.companyId = companyId
        await categoryController.addCategoryController(data)
        handleCancel()
    };
    const handleCancel = () => {
        setIsModalVisible(false);
        refetch()
    };

    const type = [
        {id: "driver", name: "driver"},
        {id: "broker", name: "broker"},
    ]
    const required = (value: any) => (value ? undefined : 'Required')

    return (
        <Modal title="Basic Modal" open={isModalVisible} onCancel={handleCancel} footer=''>

            {<Form onSubmit={onSubmit}
                   render={({handleSubmit, values}) => (
                       <form onSubmit={handleSubmit}>
                           <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                               <Space direction="vertical" size="middle"
                                      style={{display: 'flex',}}>
                                   <Col className="gutter-row" span={16}>
                                       <label>Name: </label>
                                       <Field validate={required}
                                              name='name'
                                              render={({input, meta}: { input: any, meta: any }) => (
                                                  <div>
                                                      <Input {...input} type='text' placeholder='Name'/>
                                                      {meta.touched && meta.error &&
                                                          <div style={{color: 'red'}}>{meta.error}</div>}
                                                  </div>
                                              )}
                                       ></Field>
                                   </Col>
                                   <Col className="gutter-row" span={16}>
                                       <label>Type:</label>
                                       <Field validate={required}
                                              name="type"
                                              render={({input, meta}: { input: any, meta: any }) => (
                                                  <>

                                                      <Select defaultValue={input.value}
                                                              onChange={(value, option) => {
                                                                  input.onChange(value)
                                                              }} style={{width: '100%'}}>
                                                          {type.map(data => <Option
                                                              key={data.id}>{data.name}</Option>
                                                          )}
                                                      </Select>
                                                      {meta.touched && meta.error &&
                                                          <div style={{color: 'red'}}>{meta.error}</div>}

                                                  </>
                                              )}
                                       />
                                   </Col>
                                   <Col span={16} style={{marginTop: '15px'}}>
                                       <div className="buttons ">
                                           <Button type="primary" htmlType="submit">
                                               Submit
                                           </Button>
                                       </div>
                                   </Col>
                               </Space>
                           </Space>
                       </form>
                   )
                   }
            />
            }</Modal>
    )
}

export default AddCategory