import {useQuery} from "react-query";
import {tagController} from "../../../API/Tms/tagController";


export const useTagData = (
    text: string | number | undefined,
    companyId: undefined | number | string
): object => {
    return useQuery(
        [
            `admin/tag-controller/${text}`, text, companyId],
        () => tagController.read(text, companyId),
        {refetchOnWindowFocus: false}
    );
};

export const useTagOneData = (companyId: number | string | undefined): any => {
    return useQuery(
        [`admin/tag-controllerOne/${companyId || "all"}`, companyId],
        () => tagController.tagCompanyOne(companyId),
        {refetchOnWindowFocus: false}
    );
};
export const useFindTag = async (text: any) => {
    return await tagController.tagFinderId(text)
}