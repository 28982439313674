import {useQuery} from "react-query";
import {eldModels} from "../../../API/TT_ELD/eldModels";
import {system} from "../../../API/TT_ELD/Team/system";

export const useELDModels = (companyId: string | number | undefined, skip: string | number): object => {
    return useQuery([
            `eld_models/${companyId}`, companyId, skip],
        () => eldModels.read(companyId, skip),
        {refetchOnWindowFocus: false})
}
export const useEldModelsOne = (id:string| undefined) => {
    return useQuery([`eld_get/${id}`,id], () => eldModels.eldModelsOne(id), { refetchOnWindowFocus: false})
}