import React, { useEffect, useState } from 'react'
import {
    AndroidOutlined,
    AppleOutlined,
    SettingOutlined,
    ControlOutlined,
    ScheduleOutlined,
    InfoCircleOutlined,
    SlidersOutlined,
    MonitorOutlined,
    CloudDownloadOutlined,
    DiffOutlined
} from "@ant-design/icons";
import VehicleEdit from './VehicleEdit';
import TabDrivers from './TabDrivers';
import { useLocation, useNavigate, useParams,Location } from 'react-router';
import { Tabs } from 'antd';
import { useVehicleCompanyData } from '../../../../../../../Hooks/TT_ELD/Vehicles';

type MyObjectType = {
    [key: string | number]: any;
};
interface Params {
    id: string | undefined;

    [key: string]: string | undefined;
}

const VehicleTabs = ({vehicleId} :{vehicleId:string|number}) => {
    const { id } = useParams<Params>();

    const location: Location = useLocation()
    let vehicles = location.pathname === `/user/vehicles/${id}`

    const { data, refetch ,isLoading, isFetching }: MyObjectType = useVehicleCompanyData(vehicles ? id : vehicleId )
    
    const items = [
        {
            label: <span>MAIN FIELDS</span>,
            key: '1',
            children:<VehicleEdit vehicleId={ vehicles ? id : vehicleId}/>,
            icon:<AppleOutlined rev={undefined}/>
        },
        {
            label: <span>Vehicle Driver</span>,
            key: '2',
            children: <TabDrivers driver={data?.driver} codriver={data?.codriver} isLoading={isLoading} isFetching={isFetching} />,
            icon:<AndroidOutlined rev={undefined}/>

        },
]
    const [storedTabKey, setStoredTabKey] = useState(localStorage.getItem("vehKey") as string);
    const [getTabKey, setTabKey] = useState('')
    const [getTabKeyLocal, setTabKeyLocal] = useState('');
    useEffect(() => {
        setTabKeyLocal(storedTabKey ? localStorage.getItem("vehKey") as string : '1');
    }, [getTabKey])


    return (
        <>
            <div style={{padding: "12px 8px"}}>
                {getTabKeyLocal && <Tabs
                    onTabClick={(e) => {
                        // navigate(`/user/vehicles/${id}?vehKey=${e}`);
                        // localStorage.setItem("tabKey", `${e}`);
                        // setTabKey(e);
                    }}
                    defaultActiveKey={location.search.split('').splice(8, 9)[0] || '1'}
                    style={{height: '100%'}}
                    items={items.map((tab) => {
                        return {
                            label: tab.label,
                            key: tab.key,
                            children: tab.children,
                            icon: tab.icon
                        };
                    })}
                />}
            </div>
        </>

  )
}

export default VehicleTabs