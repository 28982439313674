import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Button, Modal, Space, Spin, Table, Tag} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {zipCodeController} from "../../../../../API/Tms/zipCodes";
import { useQueryClient} from "react-query";
import {useZipCodeData} from "../../../../../Hooks/cleverTms/ZipCodeController";

type numStr = number |string
interface zipCodeSource {
    no: numStr;
    zipCode: numStr,
    city: numStr,
    state: numStr,
    geopoint: numStr,
    isActive: numStr,
    timezone: numStr,
    updatedAt: numStr,
    createdAt: numStr
    action: { id: numStr, queryClient: any,};
    key: React.Key;

}

const columns: object[] = [
    {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        sorter: true,
        sortDirections: ["ASC", "DESC"],
    },
    {
        title: 'Zip codes',
        dataIndex: 'zipCode',
        key: 'zipCode',
        sorter: {
            compare: (a:any, b:any) => a.zipCode - b.zipCode,
            multiple: 1,
        },

    }, {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        sorter: (a:any, b:any) => a.city.length - b.city.length,
    },{
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        filters: [
            {text: 'KY', value: 'KY',}, {text: 'OH', value: 'OH',}, {text: 'OR', value: 'OR',},{text: 'WV', value: 'WV',},{text: 'MO', value: 'MO',},{text: 'MA', value: 'MA',},{text: 'AR', value: 'AR',},{text: 'PA',value: 'PA',},{text: 'AL', value: 'AL',},{text: 'FL', value: 'FL',},{text: 'MA',value: 'MA',},{text: 'NY', value: 'NY'},{text: 'MN', value: 'MN',},{text: 'CA', value: 'CA',},{text: 'IL', value: 'IL',},{text: 'AL', value: 'AL',},{text: 'IN', value: 'IN',},{text: 'AZ', value: 'AZ',},{text: 'VA', value: 'VA',},{text: 'IA',value: 'IA',},{text: 'DE', value: 'DE',},{text: 'KS', value: 'KS',},{text: 'AK', value: 'AK',},{text: 'VT', value: 'VT',},{text: 'GA', value: 'GA',},{text: 'TX', value: 'TX',},{text: 'CO', value: 'CO',},{text: 'LA', value: 'LA',},{text: 'ID', value: 'ID',},
        ],
        onFilter: (value:any, record:any) => record.state.indexOf(value) === 0,
        sorter: (a:any, b:any) => a.state.length - b.state.length,
    },{
        title: 'Geopoint',
        dataIndex: 'geopoint',
        key: 'geopoint',
    },
    {
        title: 'Is Active',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (tag: boolean) => (
            <Tag color={tag ? 'geekblue' : 'red'}>
                {tag ? 'True' : 'False'}
            </Tag>
        ),
        filters: [
            {
                text: 'True',
                value: true,
            },
            {
                text: 'False',
                value: false,
            },

        ],
        onFilter: (value: any, record:any) => {
            return record.isActive === value

        },
    },
    {
        title: 'Timezone',
        dataIndex: 'timezone',
        key: 'timezone'
    },
    {
        title: 'Updated at',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter:(record1:any ,record2:any)=>{
            return record1.createdAt > record2.createdAt
        }

    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter:(record1:any ,record2:any)=>{
            return record1.createdAt > record2.createdAt
        }
    },
    {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        render: ({id, queryClient} : {id: string | number,queryClient:any }) =>
            <Space>
                <Link to={`${id}`}><Button>Edit</Button></Link>
                <Button type='primary' danger onClick={() => {
                    Modal.confirm({
                            title: 'LoadController',
                            icon: <ExclamationCircleOutlined rev={undefined}/>,
                            content: 'Do you want to delete this load ?',
                            okText: 'Ok',
                            cancelText: 'cancel',
                            onOk: async () => {
                             await zipCodeController.deleteZipCodeController(id)
                                queryClient.refetchQueries(useZipCodeData);
                                // queryClient.refetchQueries(useZipCodeData, {active: true})
                            }
                        }
                    );
                }
                }
                >Delete
                </Button>
            </Space>

    },
]
const ZipCodeTable = ({zipCode = [], total = 0, onChange, refetch} : {zipCode: Array<any>| undefined, total: number | undefined, onChange(current: any): void, refetch(): void}) => {
    const queryClient = useQueryClient();
    const [info, setInfo] = useState<Array<any>>([]);



    useEffect(()=>{
        if (zipCode) {
            setInfo(zipCode);
            refetch()
        }

    },[zipCode])

    return (
        <div>
            <Spin size='large' spinning={!zipCode?.length}>
                <Table style={{margin:'15px 0 0 0'}} onChange={onChange} dataSource={info.map((u: any, i: number): zipCodeSource => {
                    const obj: zipCodeSource = {
                        no: i + 1,
                        zipCode: u.zipCode,
                        city: u.city,
                        state :u.state,
                        geopoint: u.geopoint,
                        isActive: u.isActive,
                        timezone:u.timezone,
                        updatedAt:u.updatedAt,
                        createdAt:u.createdAt,
                        action: {id: u.id, queryClient},
                        key: u.id
                    }
                    return obj
                })} columns={columns} pagination={{total: total, pageSizeOptions: [10, 50,100]}}

                />
            </Spin>
        </div>
    );
};

export default ZipCodeTable;