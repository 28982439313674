import instance from "../apiClevertms";
import {message} from "antd";

interface Field {
    id: boolean,
    name: boolean,
    city: boolean,
    state: boolean,
    updatedAt: boolean,
    createdAt: boolean,
    isActive: boolean,
    phone: boolean,
    address: boolean,
    email: boolean,
    notes: boolean,
    attachment: boolean,
    zipCodes: boolean,
    companyId: boolean,
}

export const brokerController = {
    async read(name: string | number | undefined,companyId: undefined | number) {
        let query: any = {
            // "limit": 100,
            "where": {
                "and":[
                    {"name":{"ilike":`%${name}%`}},
                    companyId?{
                        companyId
                    }:{}
                ]
            }
        }
        const {data}: { data: object } = await instance(`admin/broker-companies?filter=${encodeURIComponent(JSON.stringify({...query}))}`);
        const getCount = async () => {
            return 0;
        }
        const count = await getCount();

        return {data, count: count};
    },



    async brokerCompanyOne(companyId: string | number | undefined) {
        const {data}: { data: any } = await instance(`admin/broker-companies/${companyId}`);
        return {data};
    },

    async brokerPatch(companyData: Field) {
        const {id, ...rest} = companyData
        const key = 'updatable';
        message.loading({content: 'Loading...', key});
        const {data}: { data: any } = await instance(`admin/broker-companies/${id}`, {
            method: 'PATCH',
            data: rest
        }).then(u => {
            setTimeout(() => {
                message.success({content: 'Loaded!', key, duration: 2});
            }, 1000);
            return u;
        });
        return data;
    },

    async addBrokerController(companyId: any ) {
        message.loading({content: 'Loading...', key:companyId});
        const { data } = await instance("/admin/broker-companies/", {
            method: 'POST',
            data: {
                ...companyId,
            },
        }).then(u => {
            setTimeout(() => {
                message.success({content: 'Loaded!', key:companyId, duration: 2});
            }, 1000);
            return u;
        });
        return data;
    },

    async deleteBrokerController(id: number | string) {
        message.loading({ content: 'Loading...', key: id });
        let res;
        let error = "";
        try {
            const { data } = await instance(`/admin/broker-companies/${id}`, {
                method: 'DELETE',
            }).then(u => {
                setTimeout(() => {
                    message.success({ content: 'Deleted!', key: id, duration: 2 });
                }, 1000);
                return u;
            });
            res = data;
        }
        catch (err) {
            error = "Oops something went wrong!";
        }
        return { data: res, error };
    },

//     async companyFinderId({name, id, }: {name: string, id: string | number | undefined }) {
//
//         let query: any = {
//             "limit": 100,
//             "where": {
//                 "and":[
//                     {"city":{"ilike":`%${name}%`}},
//                     // {"notes":{"ilike":"%e%"}}
//                 ]
//             }
//         }
//
//         const { data }: { data: Array<any> } = await instance(`/admin/broker-companies=${encodeURIComponent(JSON.stringify({...query}))}`);
//
//         return data;
//     },
//
// }

async companyFinderId(city :any) {

    let query: any = {
        "limit": 100,
        "where": {
            "and":[
                {"name":{"ilike":`%${city}%`}},
                // {"notes":{"ilike":"%e%"}}
            ]
        }
    }
    const {data}: { data: any } = await instance(`admin/broker-companies?filter=${encodeURIComponent(JSON.stringify({...query}))}`);
    return data;
},

}