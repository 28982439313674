import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Card, Col, Input, Row, Space, Spin, Switch, Tabs} from "antd";
import {Field, Form} from "react-final-form";
import {AppleOutlined} from "@ant-design/icons";
import {useZipCodeOneData} from "../../../../../Hooks/cleverTms/ZipCodeController";
import {zipCodeController} from "../../../../../API/Tms/zipCodes";

const TabPane = Tabs.TabPane;

type Data = {
    data?: {
        data: Array<any>,
        count: number
    }
}
type params = {
    readonly id: any
}
type numStr = number | string;

interface Fields {
    zipCode: numStr,
    city: numStr,
    state: string,
    geopoint: numStr,
    lat: number,
    lng: number,
    isActive: boolean,
    timezone: numStr,
    updatedAt: numStr,
    createdAt: numStr
    action: string;

}

const ZipCodeEdit = () => {


    const customerInputs = [
        {label: "ZIP code", name: 'zipCode'},
        {label: "City", name: 'city'},
        {label: "State", name: 'state'},
        {label: "Geopoint", name: 'geopoint'},
    ]

    const customerNumber = [
        {label: "Timezone", name: 'timezone'},
        {label: "Latitude", name: 'lat'},
        {label: "Longitude", name: 'lng'},
    ]
    const [companyData, setCompanyData] = useState<{ data: Array<Fields>, count: number }>();
    const {id} = useParams<params>();
    const {data}: Data = useZipCodeOneData(id)
    let navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setCompanyData(data)
        }
    }, [data])

    const onSubmit = async (value: any) => {
        await zipCodeController.zipCodePatch(value)
        navigate(-1)
    };
    return (
        <div className='mainPage'>
            <Card>
                <Spin size="large" spinning={!data}>
                    {companyData && <Form onSubmit={onSubmit}
                                          initialValues={{...companyData,}}
                                          render={({handleSubmit, values}) => (
                                              <form onSubmit={handleSubmit}>
                                                  <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                                      <Tabs defaultActiveKey="1">
                                                          <TabPane
                                                              tab={<span>MAIN FIELDS</span>}
                                                              icon={<AppleOutlined rev={undefined}/>}
                                                              key="1"
                                                          >
                                                              <Space direction="vertical" size="middle"
                                                                     style={{display: 'flex',}}>
                                                                  <Row gutter={[16, 20]}>

                                                                      {customerInputs.map(u => {
                                                                          return <Col className="gutter-row" span={6}>
                                                                              <label>{u.label}: </label>
                                                                              <Field
                                                                                  name={u.name}
                                                                                  render={({input}: { input: any }) => (
                                                                                      <Input placeholder={u.label}
                                                                                             type="text" {...input} />
                                                                                  )}
                                                                              />
                                                                          </Col>
                                                                      })
                                                                      } {customerNumber.map(u => {
                                                                      return <Col className="gutter-row" span={6}>
                                                                          <label>{u.label}: </label>
                                                                          <Field
                                                                              name={u.name}
                                                                              render={({input}: { input: any }) => (
                                                                                  <Input placeholder={u.label}
                                                                                         type="number" {...input} />
                                                                              )}
                                                                          />
                                                                      </Col>
                                                                  })
                                                                  }

                                                                  </Row>
                                                                  <div>
                                                                      <label>Is active: </label>
                                                                      <Field
                                                                          name="isActive"
                                                                          render={({input}: { input: any }) => (
                                                                              <Switch defaultChecked={input.value}
                                                                                      onChange={input.onChange}/>
                                                                          )}
                                                                      />
                                                                  </div>
                                                                  <div className="buttons">
                                                                      <Button type="primary" htmlType="submit">
                                                                          Submit
                                                                      </Button>
                                                                  </div>
                                                              </Space>
                                                          </TabPane>
                                                      </Tabs>
                                                  </Space>
                                              </form>
                                          )
                                          }
                    />
                    }
                </Spin>
            </Card>
        </div>
    );
};

export default ZipCodeEdit;