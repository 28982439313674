import instance from "../apiClevertms";
import {message} from "antd";
import apiClevertms from "../apiClevertms";


interface Field {
    id: boolean,
    lat: number,
    lng: number,
    address: boolean,
    name: boolean,
    city: boolean,
    state: boolean,
    coordinates: boolean,
    updatedAt: boolean,
    createAt: boolean,
    notes : boolean,
    placeId: boolean,
    zipCodes: boolean,
    phone:boolean,
    isActive: boolean,
    companyId: boolean
}

export const customerController ={
    async read(id: string | number | undefined,companyId: undefined | number|string) {
        let query: any = {
            "limit": 100,
            "where": {
                "and":[
                    {"city":{"ilike":`%${id}%`}},
                    companyId?{
                        companyId
                    }:{}
                ]
            }
        }
        const {data}: { data: object } = await instance(`admin/customers?filter=${encodeURIComponent(JSON.stringify({...query}))}`);
        const getCount = async () => {
            return 0;
        }
        const count = await getCount();
        return {data, count: count};
    },

    async customerCompanyOne(companyId: string | number | undefined) {
        const {data}: { data: any } = await instance(`admin/customers/${companyId}`);
        const {
            coordinates,
            ...rest
        } = data
        return {...rest};
    },

    async customerPatch(companyData: Field) {
        const {id, ...rest} = companyData
        const key = 'updatable';
        message.loading({content: 'Loading...', key});
        const {data}: { data: any } = await instance(`admin/customers/${id}`, {
            method: 'PATCH',
            data: rest
        }).then(u => {
            setTimeout(() => {
                message.success({content: 'Loaded!', key, duration: 2});
            }, 1000);
            return u;
        });
        return data;
    },

    async addCustomerController(companyId: any ) {
        message.loading({content: 'Loading...', key:companyId});
        const { data } = await instance("/admin/customers/", {
            method: 'POST',
            data: {
                ...companyId,
            },
        }).then(u => {
            setTimeout(() => {
                message.success({content: 'Loaded!', key:companyId, duration: 2});
            }, 1000);
            return u;
        });
        return data;
    },

    async getAddress(address: string | number | undefined) {
        const {data}: { data: any } = await instance(`location/search?search=${address}`);
        const getCount = async () => {
            return 0;
        }
        const count = await getCount();
        return {
            data: data,
            count: count
        };
    },

    async deleteCustomerController(id: number | string) {
        message.loading({ content: 'Loading...', key: id });
        let res;
        let error = "";
        try {
            const { data } = await instance(`/admin/customers/${id}`, {
                method: 'DELETE',
            }).then(u => {
                setTimeout(() => {
                    message.success({ content: 'Deleted!', key: id, duration: 2 });
                }, 1000);
                return u;
            });
            res = data;
        }
        catch (err) {
            error = "Oops something went wrong!";
        }
        return { data: res, error };
    },
    async customerFinderId(city :any) {
        let query: any = {
            "limit": 100,
            "where": {
                "and":[
                    {"city":{"ilike":`%${city}%`}},
                ]
            }
        }
        const {data}: { data: Array<any>  } = await instance(`admin/cities?filter=${encodeURIComponent(JSON.stringify({...query}))}`);
        return data;
    },
    async findAddress(address :undefined | number | string) {

        const {data}: { data: Array<any>  } = await apiClevertms(`location/search?search=${address}`);
        return data;
    },
    async findAddress2(place_id :undefined | number | string) {
        if(place_id) {
            const {data}: { data: Array<any>  } = await instance(`location/${place_id}`);
            return data;
        }
    },
}