import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Card, Col, Input, Row, Select, Space, Spin, Switch, Tabs} from "antd";
import {Field, Form} from "react-final-form";
import {AppleOutlined} from "@ant-design/icons";
import {useControllerOneData} from "../../../../../Hooks/cleverTms/LoadController";
import {loadController} from "../../../../../API/Tms/loadController";
import {
    billingStatus,
    confirmedByDriver,
    LoadControllerInputs,
    LoadControllerNumber,
    LoadControllerSwitch,
    status
} from './LoadsAllInfo'

const TabPane = Tabs.TabPane;
const {Option} = Select;

type Data = {
    data?: {
        data: Array<any>,
        count: number
    }
}
type params = {
    readonly id: any
}

interface Fields {
    "status": string,
    "applied": string,
    "confirmedByDriver": string,
    "billingStatus": string,
    "orderNumber": string,
    "brokerEmails": Array<string>,
    "notes": string | '',
    "brokerCompanyId": string,
    "brokerEmail": string,
    "reason": string,
    "deliveryAddress": string,
    "stopStatus": string,
    "origin": string,
    "completed": string,
    "puDateTo": string,
    "puDateFrom": string,
    "delDateFrom": string,
    "delDateTo": string,
    "currentStop": number,
    "unitId": number,
    "statusCode": number,
    "dispatcherId": number,
    "driverId": number,
    "coDriverId": number,
    "rate": number,
    "mile": number,
    "cancelPrice": number,
    "price": number,
    "emptyMile": number,
    "calculatedPrice": number,
    "driverLoadCompleted": boolean,
    "paid": boolean,
    "roundTrip": boolean,
    "isPaidSalary": boolean,
    "isDispatched": boolean,
    "isActive": boolean,
    "calculatedData": object
}

const LoadControllerEdit = () => {
    const [companyData, setCompanyData] = useState<{ data: Array<Fields>, count: number }>();
    const {id} = useParams<params>();
    const {data}: Data = useControllerOneData(id)
    let navigate = useNavigate();


    useEffect(() => {
        if (data) {

            setCompanyData(data)
        }
    }, [data])

    const onSubmit = async (value: any) => {
        await loadController.loadControllerPatch(value)
        navigate(-1)
    };
    return (
        <div className='mainPage'>
            <Card>
                <Spin size="large" spinning={!data}>
                    {companyData && <Form onSubmit={onSubmit}
                                          initialValues={{...companyData,}}
                                          render={({handleSubmit, values}) => (
                                              <form onSubmit={handleSubmit}>
                                                  <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                                      <Tabs defaultActiveKey="1">
                                                          <TabPane
                                                              tab={
                                                                  <span>
                                          MAIN FIELDS
                                           </span>
                                                              }
                                                              icon={ <AppleOutlined rev={undefined}/>}
                                                              key="1"
                                                          >
                                                              <Space direction="vertical" size="middle"
                                                                     style={{display: 'flex',}}>
                                                                  <Row gutter={[16, 20]}>
                                                                      {LoadControllerInputs.map(u => {
                                                                          return <Col className="gutter-row" span={6}>
                                                                              <label>{u.label}: </label>
                                                                              <Field
                                                                                  name={u.name}
                                                                                  render={({input}: { input: any }) => (
                                                                                      <Input placeholder={u.label}
                                                                                             type="text" {...input} />
                                                                                  )}
                                                                              />
                                                                          </Col>
                                                                      })
                                                                      }
                                                                  </Row>
                                                                  <Row gutter={[16, 20]}>
                                                                      {LoadControllerNumber.map(u => {
                                                                          return <Col className="gutter-row" span={6}>
                                                                              <label>{u.label}: </label>
                                                                              <Field
                                                                                  name={u.name}
                                                                                  render={({input}: { input: any }) => (
                                                                                      <Input placeholder={u.label}
                                                                                             type="number" {...input} />
                                                                                  )}
                                                                              />
                                                                          </Col>
                                                                      })
                                                                      }
                                                                  </Row>
                                                                  <Row gutter={16}>
                                                                      <Col className="gutter-row" span={6}>
                                                                          <label>Confirmed by driver:</label>
                                                                          <Field
                                                                              name="confirmedByDriver"
                                                                              render={({input}: { input: any }) => (
                                                                                  <Select defaultValue={input.value}
                                                                                          onChange={(value, option) => {
                                                                                              input.onChange(value)
                                                                                          }} style={{width: '100%'}}>
                                                                                      {confirmedByDriver.map(data => <Option
                                                                                          key={data.id}>{data.name}</Option>)}
                                                                                  </Select>
                                                                              )}
                                                                          /></Col>

                                                                      <Col className="gutter-row" span={6}>
                                                                          <label>Billing status:</label>
                                                                          <Field
                                                                              name="billingStatus"
                                                                              render={({input}: { input: any }) => (
                                                                                  <Select defaultValue={input.value}
                                                                                          onChange={(value, option) => {
                                                                                              input.onChange(value)
                                                                                          }} style={{width: '100%'}}>
                                                                                      {billingStatus.map(data => <Option
                                                                                          key={data.name}>{data.name}</Option>)}
                                                                                  </Select>
                                                                              )}
                                                                          /></Col>
                                                                      <Col className="gutter-row" span={6}>
                                                                          <label>Status:</label>
                                                                          <Field
                                                                              name="status"
                                                                              render={({input}: { input: any }) => (
                                                                                  <Select defaultValue={input.value}
                                                                                          onChange={(value, option) => {
                                                                                              input.onChange(value)
                                                                                          }} style={{width: '100%'}}>
                                                                                      {status.map(data => <Option
                                                                                          key={data.id}>{data.name}</Option>)}
                                                                                  </Select>
                                                                              )}
                                                                          /></Col>
                                                                  </Row>
                                                                  {LoadControllerSwitch.map(u => {
                                                                      return <div>
                                                                          <Field
                                                                              name={u.label}
                                                                              render={({input}: { input: any }) => (
                                                                                  <Switch defaultChecked={input.value}
                                                                                          onChange={input.onChange}/>
                                                                              )}
                                                                          />
                                                                          <label
                                                                              style={{marginLeft: '15px'}}>{u.label}</label>
                                                                      </div>
                                                                  })}
                                                                  <div className="buttons">
                                                                      <Button type="primary" htmlType="submit">
                                                                          Submit
                                                                      </Button>
                                                                  </div>
                                                              </Space>
                                                          </TabPane>
                                                      </Tabs>
                                                  </Space>
                                              </form>
                                          )
                                          }
                    />
                    }
                </Spin>
            </Card>
        </div>

    );
};

export default LoadControllerEdit;