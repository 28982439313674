import {useQuery} from "react-query";
import { customerController } from "../../../API/Tms/customerController";

export const useCustomerData = (city: string | number | undefined,companyId: undefined | number |string): object => {
    return useQuery([
            `admin/customer-controller/${city}`, city,companyId],
        () => customerController.read(city,companyId),
        { refetchOnWindowFocus: false })
}
export const useCustomerOneData = (companyId: number | string | undefined): any => {
    return useQuery([
            `admin/customerOne/${companyId || 'all'}`, companyId],
        () => customerController.customerCompanyOne(companyId),
        { refetchOnWindowFocus: false})
}
export const useFindCustomer = async (query :any) => {
    return await customerController.customerFinderId(query)


}

export const useFindAddress = async (query :undefined | number | string) => {
    return await customerController.findAddress(query)
}
export const useFindAddress2 = async (query :undefined | number | string) => {
    return await customerController.findAddress2(query)
}


export const useGetAddressData = (address: any ): any => {
    return useQuery([`admin/getAddress/${address}`, address],
        () => customerController.getAddress(address,),
        { refetchOnWindowFocus: false})
}