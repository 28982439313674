import React, {useEffect, useState} from 'react';
import { Descriptions, Modal, Tag} from "antd";

const TrackingDebugInfo = ({
                           data,
                           isModalVisible,
                           setIsModalVisible
                       }: { data: any, isModalVisible: boolean | undefined, setIsModalVisible(isModalVisible: boolean): void }) => {
    const handleCancel = () => {
        setIsModalVisible(!isModalVisible)
    }
    const [parsedData, setParsedData] = useState<any>();

    useEffect(() => {
        try {
            const companyIdData = data;

            if (companyIdData && companyIdData.debug_info) {
                const parsedData = companyIdData.debug_info.split(',').reduce((acc: any, item: string) => {
                    const [key, value] = item.split(':').map(s => s.trim());
                    acc[key] = value;
                    return acc;
                }, {});
                setParsedData(parsedData);
            } else {
                console.error('No data to parse');
            }
        } catch (error: any) {
            console.error('Error parsing debug info:', error.message);
        }
    }, [data]);
    return (
        <Modal width='' title='All Info tracking' open={isModalVisible} footer='' onCancel={handleCancel}>
            <Descriptions bordered>
                {parsedData &&
                    Object.keys(parsedData)?.map((key) => (
                        <Descriptions.Item key={key} label={key}>
                            <Tag>{parsedData[key]}</Tag>
                        </Descriptions.Item>
                    ))}
            </Descriptions>
        </Modal>
    );
};

export default TrackingDebugInfo;