import {useQuery} from "react-query";
import {unitsController} from "../../../API/Tms/units";


export const useUnitsData = ( skip : string|number, date: string| number | any): object => {
    return useQuery(
        [
            `admin/units${ skip || 'all'}`,skip , date,
        ],
        () => unitsController.read(skip, date),
        {refetchOnWindowFocus: false}
    );
};
export const useGetCompany = (companyId:number |undefined , skip : string|number,unit :string, date: string| number | any): object => {
    return useQuery(
        [
            `admin/companyId${ companyId || 'all'}`, companyId ,skip, unit , date,
        ],
        () => unitsController.getCompany(companyId ,skip,unit, date),
        {refetchOnWindowFocus: false}
    );
};
export const useUnitSearch = async (unitNum: any , company:any) => {
    return await unitsController.unitsFinder(unitNum, company)
}