import React, {useEffect, useState} from 'react';
import {useDevicesEld} from "../../../../../Hooks/TT_ELD/Team/Devices";
import DevicesEldTable from "./DevicesEldTable/DevicesEldTable";
import SearchInput from "../../../../../Utils/SearchInput";
import {Button, Select} from "antd";
import SearchOptions from "../../../../../Utils/SearchOptions";
import { SearchResultForDevices} from "../../../../../Utils/SearchResults";
import { useParams ,useNavigate} from 'react-router-dom';
import useRouteQuery from "../../../../../Utils/useRouterQuery";


interface Params {
    id: any;

    [key: string]: string | undefined;
}
type Data = {
    data?: {
        data: Array<any>
        count: number
    },
    isLoading?: boolean,
    refetch?: any,
    isFetching?: any
}

const roleList = [
    {
        label: "IOS",
        value: "IOS"
    },

    {
        label: "Android ",
        value: "Android",
    },

]

const DevicesEld = () => {
    const {id} = useParams<Params>();
    const [skip, setSkip] = useState<number>(1);
    const [sortedInfo, setSortedInfo] = useState<any>({});
    const [userId, setUserId] = useState<string | number>('');
    const [model, setModel] = useRouteQuery("", 'model', (v) => String(v));
    const [appVersion, setAppVersion] = useRouteQuery("", 'appVersion', (v) => String(v));
    const [versionCode, setVersionCode] = useRouteQuery("", 'versionCode', (v) => String(v));
    const [device, setDevice] = useRouteQuery("", 'device', (v) => String(v));
    const [sort, setSort] = useRouteQuery("", 'sort', (v) => String(v));


    const {Option} = Select;
    let navigate = useNavigate();
    const {data, isLoading, refetch}: Data = useDevicesEld(userId, model,appVersion,versionCode, device, sort, id ? id :1)
    const onChange = (query: any) => {
        setSkip(1 * (parseInt(query.current)))
        navigate(`/team/devices-eld/${1 * (parseInt(query.current))}`);
    }

    const onSelectDriver = (value: any, {valId}: { valId: string | number }) => {
        setUserId(valId ? valId : '')
        if (valId) {
            setSkip(1)
        }
    }

    const handleChange = (event:string, inputName:string) => {
        const updateFunctions: Record<string, (value: string) => void> = {
            model: setModel,
            appVersion: setAppVersion,
            versionCode: setVersionCode,
        };

        if (updateFunctions[inputName]) {
            updateFunctions[inputName](event.trim());
        }
    };

    return (
        <div>
            <span style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <SearchOptions SearchResult={(query: string) => SearchResultForDevices(query)} onSelect={onSelectDriver} placeholder="Search by driver" value={''}/>
                <SearchInput value={model} placeholder="Search by model"  onDebounceChange={(event:string) => handleChange(event, 'model')}/>
                <SearchInput value={appVersion} placeholder="Search by app version" onDebounceChange={(event:string) => handleChange(event, 'appVersion')}/>
                <SearchInput value={versionCode} placeholder="Search by version code" onDebounceChange={(event:string) => handleChange(event, 'versionCode')}/>
                <Select value={device ? device : 'all'} defaultValue="all" onChange={(value, option) => {
                       setDevice(value === 'all' ? '' : value)
                   }} style={{width: 200}}>
                    <Option key={'all'}>All Device</Option>
                       {roleList.map((u: any, index: any) => <Option key={index} value={u.value}>{u.label}</Option>)}
                </Select>
                <Select value={sort ? sort : 'Is Active'} defaultValue="Is Active" onChange={(value, option) => {
                       setSort(value === 'Is Active' ? '' : value)
                   }} style={{width: 200}}>
                    <Option key={''} value=''>Is Active</Option>
                     <Option value='true'>True</Option>
                     <Option value='false'>False</Option>
                </Select>
                                <Button style={{marginLeft: 'auto'}} size={'large'} onClick={refetch}>Refresh</Button>

            </span>
            <DevicesEldTable data={data?.data} onChange={onChange} total={data?.count} isLoading={isLoading}
                             setSortedInfo={setSortedInfo} sortedInfo={sortedInfo} setSort={setSort} sort={sort}
                             setSkip={setSkip} pageId={id} skip={skip}/>
        </div>
    );
};

export default DevicesEld;