import React from 'react';
import {Button} from 'antd';

const DownloadButton = ({ link, fileName }: { link: string; fileName: string }) => {
    const handleDownload = async () => {
        const fileUrl = `https://uat.tteld.com/uploads/file/${link}`;
        const anchor = document.createElement('a');
        anchor.href = fileUrl;
        anchor.download = fileName;
        anchor.click();
    };

    return (
        <Button type='primary' style={{ margin: '6px 0 6px 0' }} onClick={handleDownload}>
            {fileName}
        </Button>
    );
};

export default DownloadButton;