import {useNavigate, useParams} from "react-router-dom";
import {Button, Card, Col, Input, Row, Space, Switch} from "antd";
import {Field, Form} from "react-final-form";
import {deviceController} from "../../../../../API/Tms/device";
import React from "react";

type params = {
    readonly id: any
}
type numStr = number | string;

interface Fields {
    no: numStr
    updatedAt: numStr,
    createdAt: numStr,
    isActive: boolean,
    model: numStr,
    androidVersion: numStr,
    ios: numStr,
    deviceToken: numStr,
    fcmToken: numStr,
    oneSignalUserId: numStr,
    appVersion: numStr,
    versionCode: numStr,
    deviceInfo: numStr,
    driverId: numStr,
    companyId: numStr,
    config: numStr,
    mediaDeviceId: numStr,
    dispatcherId: numStr

}
const deviceInputs = [
    {label :"App version", name:'appVersion'},
    {label :"Device token", name:'deviceToken'},
    {label :"Model", name:'model'},
    {label :"Android version", name:'androidVersion'},
    {label :"IOS", name:'ios'},
    {label :"FCM token", name:'fcmToken'},
    {label :"One signal user ID", name:'oneSignalUserId'},
]
const deviceNumber = [
    {label :"Version code", name:'versionCode'},
    {label :"Driver ID", name:'driverId'},
    {label :"Company ID", name:'companyId'},
    {label :"Dispatcher ID", name:'dispatcherId'},
]
const AddContact = () => {

    const {id} = useParams<params>();

    let navigate = useNavigate();

    const onSubmit = async (value: Fields) => {
        await deviceController.addDeviceController(value)
        navigate(-1)
    };



    const required = (value:any) => (value ? undefined : 'Required')

    return (
        <div className='mainPage'>{
            <Card>
                <Form onSubmit={onSubmit}
                      render={({handleSubmit, values}) => (
                          <form onSubmit={handleSubmit}>
                              <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                                  <h1>Add Device</h1>
                                  <Space direction="vertical" size="middle"
                                         style={{display: 'flex',}}>
                                      <Row gutter={[16, 20]}>

                                          {deviceInputs.map(u => {
                                              return <Col className="gutter-row" span={6}>
                                                  <label>{u.label}: </label>
                                                  <Field  validate={required}
                                                          name={u.name}
                                                          render={({input, meta}: { input: any , meta:any}) => (
                                                              <div>
                                                                  <Input {...input} type='text' placeholder={u.label}/>
                                                                  {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                              </div>
                                                          )}
                                                  />
                                              </Col>
                                          })
                                          }
                                          {deviceNumber.map(u => {
                                              return <Col className="gutter-row" span={6}>
                                                  <label>{u.label}: </label>
                                                  <Field  validate={required}
                                                          name={u.name}
                                                          render={({input , meta}: { input: any, meta:any }) => (
                                                              <div>
                                                                  <Input {...input} type='number' placeholder={u.label}/>
                                                                  {meta.touched && meta.error && <div style={{color:'red'}}>{meta.error}</div>}
                                                              </div>
                                                          )}
                                                  />
                                              </Col>
                                          })
                                          }
                                      </Row>
                                      <div>
                                          <label>Is Active: </label>
                                          <Field
                                              name="isActive"
                                              render={({input}: { input: any }) => (
                                                  <Switch defaultChecked={input.value} onChange={input.onChange}/>
                                              )}
                                          />
                                      </div>
                                      <div className="buttons">
                                          <Button type="primary" htmlType="submit">
                                              Submit
                                          </Button>
                                      </div>
                                  </Space>
                              </Space>
                          </form>
                      )
                      }/>
            </Card>

        }</div>
    )
}

export default AddContact