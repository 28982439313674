import React, {useState} from 'react';
import {Button, Modal, Space, Spin, Table} from "antd";

import {userCompany} from "../../../../API/TT_ELD/userCompany";
import {useQueryClient} from "react-query";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {sendEvent} from "../../../../Utils/analytics";
import {Link, useLocation} from "react-router-dom";
import UserCompaniesEdit from "./UserCompaniesEdit/UserCompaniesEdit";
import UserCompaniesEditModal from "./UserCompaniesEdit/UserCompaniesEditModal";
import  {calcOrder} from "../../../components/core/calcOrder";

const {confirm} = Modal;

type numStr = number | string;

interface userDataSource {
    no: number | string;
    role: string;
    company?: { name: string, companyId: string };
    userName?: { name: string | undefined, id: String };
    userEmail?: string | undefined;
    id?: number | string;
    userId?: number | string | undefined;
    delete?: { id: numStr, queryClient: any, };
    key: React.Key;

    // actions: number | string;
}


const UserCompanies = ({
                           userCompanies = [],
                           total = 0,
                           onChange,
                           isLoading,
                           isFetching,
                           open,
                           setOpen,
                           setIsModalBulk,
                           refetch,
                           skip,
                           setGetKey,
                       }: { userCompanies: Array<any> | undefined, total: number | undefined, onChange(current: any): void, isLoading: boolean | undefined, isFetching: any, open: boolean, setOpen(open: boolean): void, setIsModalBulk(isModalBulk: boolean): void, refetch(): void, skip: number | string | undefined | any, setGetKey(getKey: any): void }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const columns: object[] = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: 60
        },
        {
            title: 'Username',
            dataIndex: 'userName',
            key: 'userName',
            width: 220,
            render: ({name, id}: { name: String, id: string }) => (
                <Link to={`/user/users/${id}`}>{name}</Link>
            )
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            width: 220,
            render: ({name, companyId}: { name: string, companyId: string }) => (
                <Link to={`/user/companies/${companyId}`}>{name}</Link>
            )
        },
        {
            title: 'Email',
            dataIndex: 'userEmail',
            key: 'userEmail',
            width: 250
        },

        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: 150
        },


        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 100
        },
        {
            title: 'User ID',
            dataIndex: 'userId',
            key: 'userId',
            width: 120
        },
        {
            title: 'Actions',
            dataIndex: 'delete',
            key: 'delete',
            width: 130,
            fixed: 'right',
            render: ({id}: { id: string | number }) => {
                const showConfirm = () => {
                    confirm({
                        title: 'User',
                        icon: <ExclamationCircleFilled rev={undefined}/>,
                        content: 'Do you want to delete this user ?',
                        onOk: async () => {
                            return new Promise(async (resolve, reject) => {
                                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                                await userCompany.deleteUserCompany(id)
                                sendEvent('UserCompany table', 'UserCompany ,  Removed User', `UserCompany table,  Removed User `, '1');
                                refetch()
                            }).catch(() => refetch());
                        },
                        onCancel() {
                        },
                    });
                };
                const showModal = () => {
                    setIsModalOpen(true);
                };
                return (
                    <Space>
                        <Button onClick={showModal}>
                            Edit
                        </Button>
                        <Button type='primary' danger onClick={showConfirm}>
                            Delete
                        </Button>
                    </Space>
                )

            }

        },

    ];
    const queryClient = useQueryClient();
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: userDataSource[]) => {
            if (selectedRows.length > 0) {
                setIsModalBulk(true)
            } else {
                setIsModalBulk(false)
            }
            setGetKey(selectedRowKeys)
        },
    };

    const driversLogs: Array<any> = [];
    userCompanies?.forEach((log: any) => {
        if (log?.user) {
            driversLogs.push(log)
        }
    })
    const [getOneData, setGetOneData] = useState<userDataSource>();
    const handleCancel = (record: any) => {
        setGetOneData(record)
    }

    return (
        <div>
            <Spin size="large" spinning={isLoading || isFetching}>
                {isModalOpen && <UserCompaniesEditModal isModalVisible={isModalOpen} setIsModalVisible={setIsModalOpen}
                                                        refetchData={refetch} getOneData={getOneData}/>}
                {/*<BulkEditUser getKey={getKey} open={open} setOpen={setOpen} refetch={refetch}/>*/}
                <Table style={{margin: '15px 0 0 0 '}} onRow={(record: any) => {
                    return {
                        onClick: () => {
                            handleCancel(record)
                        },
                    };
                }} onChange={onChange} dataSource={userCompanies?.map((u: any, i: number): userDataSource => {
                    const obj: userDataSource = {
                        no: calcOrder(skip, 10, i),
                        role: u.role,
                        company: {name: u?.company?.name, companyId: u.companyId},
                        userName: {name: u?.user?.first_name + ' ' + u?.user?.second_name, id: u.userId},
                        userEmail: u?.user?.email,
                        id: u.id,
                        userId: u?.userId,
                        delete: {id: u.id, queryClient},
                        key: u.id
                    }
                    return obj;
                })} columns={columns} pagination={{total: total, showSizeChanger: false, current: skip}}
                       rowSelection={{
                           type: 'checkbox',
                           ...rowSelection,
                       }}
                       scroll={{x: 1000}} sticky
                />
            </Spin>
        </div>
    )
};

export default UserCompanies;
