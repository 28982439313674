

export const confirmedByDriver = [
    { id: "pending", name: "pending" },
    { id: "send", name: "send" },
    { id: "accept", name: "accept" },
    { id: "reject", name: "reject" },
]
export const status = [
    { id: "upcoming", name: "upcoming" },
    { id: "cancelled", name: "cancelled" },
    { id: "completed", name: "completed" },
    { id: "in-progress", name: "in-progress" },
    { id: "sold", name: "sold" },
]
export const billingStatus = [
    { id: "pending", name: "pending" },
    { id: "cancelled", name: "cancelled" },
    { id: "bolReceived", name: "bolReceived" },
    { id: "invoiced", name: "invoiced" },
    { id: "funded", name: "funded" },
    { id: "sentToFactoring", name: "sentToFactoring" },
    { id: "paid", name: "paid" },
]
export const LoadControllerInputs = [
    // "applied",
    {label:"Order number", name:'orderNumber'},
    // "brokerEmails",
    {label:"Notes", name:'notes'},
    {label:"Broker company ID", name:'brokerCompanyId'},
    {label:"Reason", name:'reason'},
    {label:"Delivery  address", name:'deliveryAddress'},
    {label:"Stop status", name:'stopStatus'},
    {label:"Origin", name:'origin'},
    // "completed",
    // "puDateTo",
    // "puDateFrom",
    // "delDateFrom",
    // "delDateTo",
]
// export const LoadControllerNumber = [
//     "currentStop",
//     "unitId",
//     "statusCode",
//     "dispatcherId",
//     "driverId",
//     "coDriverId",
//     // "rate",
//     "mile",
//     "cancelPrice",
//     "price",
//     "emptyMile",
//     "calculatedPrice",
// ]
export const LoadControllerNumber = [
    {label:"Current stop", name:'currentStop'},
    {label:"Unit ID", name:'unitId'},
    {label:"Status code", name:'statusCode'},
    {label:"Dispatcher ID", name:'dispatcherId'},
    {label:"Driver ID", name:'driverId'},
    {label:"Co-Driver ID", name:'coDriverId'},
    {label:"Mile", name:'mile'},
    {label:"Cancel price", name:'cancelPrice'},
    {label:"Price", name:'price'},
    {label:"Empty mile", name:'emptyMile'},
    {label:"Calculated price", name:'calculatedPrice'},
]

export const LoadControllerSwitch:{label: string,name:string ,case: string}[] = [
    {label: 'Driver load completed',name: 'driverLoadCompleted', case: 'dispatcher'},
    {label: 'Paid',name: 'driverLoadCompleted', case: 'dispatcher'},
    {label: 'Round trip',name: 'driverLoadCompleted', case: 'dispatcher'},
    {label: 'Is paid salary',name: 'driverLoadCompleted', case: 'dispatcher'},
    {label: 'Is dispatched',name: 'driverLoadCompleted', case: 'dispatcher'},
    {label: 'Is active',name: 'driverLoadCompleted', case: 'dispatcher'},
]
export const AddLoadControllerSwitch:{label: string, case: string}[] = [
    {label: 'driverLoadCompleted', case: 'dispatcher'},
    {label: 'paid', case: 'dispatcher'},
    {label: 'roundTrip', case: 'dispatcher'},
    {label: 'isPaidSalary', case: 'dispatcher'},
    {label: 'isDispatched', case: 'dispatcher'},
    {label: 'isActive', case: 'dispatcher'},
    {label: 'calculatedData', case: 'dispatcher'},
    {label: 'isDemo', case: 'isDemo'},
    {label: 'isPaid', case: 'isPaid'},
]



// <Row gutter={16}>
//     <Col span={8}>
//         <div>
//             <label>Status:</label>
//             <Field
//                 name="status"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder="status"
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>Applied:</label>
//             <Field
//                 name="applied"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder="applied"
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>Confirmed ByDriver:</label>
//             <Field
//                 name="confirmedByDriver"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder="confirmedByDriver"
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
// </Row>
// <Row gutter={16}>
//     <Col span={8}>
//         <div>
//             <label>Billing Status: </label>
//             <Field
//                 name="billingStatus"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='billingStatus'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>Order Number: </label>
//             <Field
//                 name="orderNumber"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='orderNumber'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>Broker Emails: </label>
//             <Field
//                 name="brokerEmails"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='brokerEmails'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
// </Row>
// <Row gutter={16}>
//     <Col span={8}>
//         <div>
//             <label>Notes: </label>
//             <Field
//                 name="notes"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='notes'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>Broker Company Id: </label>
//             <Field
//                 name="brokerCompanyId"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='brokerCompanyId'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>Broker Email: </label>
//             <Field
//                 name="brokerEmail"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='brokerEmail'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
// </Row>
// <Row gutter={16}>
//     <Col span={8}>
//         <div>
//             <label>Reason: </label>
//             <Field
//                 name="reason"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='reason'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>Delivery Address: </label>
//             <Field
//                 name="deliveryAddress"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='deliveryAddress'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>Stop Status: </label>
//             <Field
//                 name="stopStatus"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='stopStatus'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
// </Row>
// <Row gutter={16}>
//     <Col span={8}>
//         <div>
//             <label>Origin: </label>
//             <Field
//                 name="origin"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='origin'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>Completed: </label>
//             <Field
//                 name="completed"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='completed'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>Pu Date To: </label>
//             <Field
//                 name="puDateTo"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='puDateTo'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
// </Row>
// <Row gutter={16}>
//     <Col span={8}>
//         <div>
//             <label>Pu Date From: </label>
//             <Field
//                 name="puDateFrom"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='puDateFrom'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>Del Date From: </label>
//             <Field
//                 name="delDateFrom"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='delDateFrom'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>Del Date To: </label>
//             <Field
//                 name="delDateTo"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='delDateTo'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
// </Row>
// <Row gutter={16}>
//     <Col span={8}>
//         <div>
//             <label>Current Stop: </label>
//             <Field
//                 name="currentStop"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='currentStop'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>Unit Id: </label>
//             <Field
//                 name="unitId"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='deliveryAddress'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>StatusCode: </label>
//             <Field
//                 name="statusCode"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='statusCode'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
// </Row>
// <Row gutter={16}>
//     <Col span={8}>
//         <div>
//             <label>dispatcherId: </label>
//             <Field
//                 name="dispatcherId"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='dispatcherId'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>Driver Id: </label>
//             <Field
//                 name="driverId"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='driverId'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>coDriverId: </label>
//             <Field
//                 name="coDriverId"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='coDriverId'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
// </Row>
// <Row gutter={16}>
//     <Col span={8}>
//         <div>
//             <label>rate: </label>
//             <Field
//                 name="rate"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='rate'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>mile: </label>
//             <Field
//                 name="mile"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='mile'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>cancelPrice: </label>
//             <Field
//                 name="cancelPrice"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='cancelPrice'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
// </Row>
// <Row gutter={16}>
//     <Col span={8}>
//         <div>
//             <label>price: </label>
//             <Field
//                 name="price"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='price'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={8}>
//         <div>
//             <label>emptyMile: </label>
//             <Field
//                 name="emptyMile"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='emptyMile'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//     </Col>
//
// </Row>
// <Row gutter={16}>
//     <Col span={8}>
//         <div>
//             <label>calculatedPrice: </label>
//             <Field
//                 name="calculatedPrice"
//                 render={({input}: { input: any }) => (
//                     <Input placeholder='calculatedPrice'
//                            type="text" {...input} />
//                 )}
//             />
//         </div>
//         <Col span={8}>
//             <div>
//                 <label>calculatedData: </label>
//                 <Field
//                     name="calculatedData"
//                     render={({input}: { input: any }) => (
//                         <Input
//                             placeholder='calculatedData'
//                             type="text" {...input} />
//                     )}
//                 />
//             </div>
//         </Col>
//     </Col>
//
//
// </Row>
// <Row gutter={16}>
//     <Col span={16}>
//         <div>
//             <label>Driver Load Completed: </label>
//             <Field
//                 name="driverLoadCompleted"
//                 render={({input}: { input: any }) => (
//                     <Switch defaultChecked={input.value}
//                             onChange={input.onChange}/>
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={16}>
//         <div>
//             <label>paid: </label>
//             <Field
//                 name="paid"
//                 render={({input}: { input: any }) => (
//                     <Switch defaultChecked={input.value}
//                             onChange={input.onChange}/>
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={16}>
//         <div>
//             <label>roundTrip: </label>
//             <Field
//                 name="roundTrip"
//                 render={({input}: { input: any }) => (
//                     <Switch defaultChecked={input.value}
//                             onChange={input.onChange}/>
//                 )}
//             />
//         </div>
//     </Col>
// </Row>
// <Row gutter={16}>
//     <Col span={16}>
//         <div>
//             <label>isPaidSalary: </label>
//             <Field
//                 name="isPaidSalary"
//                 render={({input}: { input: any }) => (
//                     <Switch defaultChecked={input.value}
//                             onChange={input.onChange}/>
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={16}>
//         <div>
//             <label>isDispatched: </label>
//             <Field
//                 name="isDispatched"
//                 render={({input}: { input: any }) => (
//                     <Switch defaultChecked={input.value}
//                             onChange={input.onChange}/>
//                 )}
//             />
//         </div>
//     </Col>
//     <Col span={16}>
//         <div>
//             <label>isActive: </label>
//             <Field
//                 name="isActive"
//                 render={({input}: { input: any }) => (
//                     <Switch defaultChecked={input.value}
//                             onChange={input.onChange}/>
//                 )}
//             />
//         </div>
//     </Col>
// </Row>
