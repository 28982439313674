import {Col, Input, InputNumber, Row, Select, Space, Spin, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {Field} from "react-final-form";
import {getStatus, statusList} from "../../../../../../../../Utils/data";
import {useVehicleSearch} from "../../../../../../../../Hooks/TT_ELD/Logs";

const {Option} = Select;
const MainFields = ({data, values, form}: { data: any, values: any, form: any }) => {
    const [check, setCheck] = useState(false);
    const [getEvent, setGetEvent] = useState<number | any>(values?.event_type);
    const [getCode, setGetCode] = useState<number | any>(values?.event_code);
    const [statusGet, setStatusGet] = useState<any>();
    // const myComp = useCompanyData(data.companyId);
    // const myVehicle = useVehicleData(data.vehicleId);
    const vehicleData: any = useVehicleSearch('', data?.companyId);


    useEffect(() => {
        setStatusGet(getStatus(getCode, getEvent))
    }, [getCode, getEvent])

    if (statusGet) {
        form.change('status', statusGet)
    }
    return !vehicleData.isLoading ? (
        <div>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                {/*<Row>*/}
                {/*    <Col span={8}>*/}

                {/*    </Col>*/}
                {/*    <Col span={8}>*/}

                {/*    </Col>*/}
                {/*    <Col span={8}>*/}

                {/*  //21674  </Col>*/}

                {/*</Row>*/}
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <div>
                            <label>ID: </label>
                            <Field
                                name="id"
                                render={({input}: { input: any }) => (
                                    <Input disabled type="text" {...input} />
                                )}
                            />
                        </div>
                    </Col>
                    {/*<Col span={8}>*/}
                    {/*    <div>*/}
                    {/*        <label>Record Status: </label>*/}
                    {/*        <Field*/}
                    {/*            name="record_status"*/}
                    {/*            render={({input}: { input: any }) => (*/}
                    {/*                <Input type="text" {...input} />*/}
                    {/*            )}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    <Col span={8}>
                        <div>
                            <label>Status: </label>
                            <Field
                                name="status"
                                render={({input}: { input: any }) => {
                                    return <Select defaultValue={input.value} value={input?.value}
                                                   onChange={(value, option) => {
                                                       input.onChange(value)
                                                       setStatusGet(value)
                                                   }} style={{width: '100%'}}>
                                        {statusList.map(u => <Option key={u.id}>{u.name}</Option>)}
                                    </Select>
                                }}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <label>Address: </label>
                            <Field
                                name="address"
                                render={({input}: { input: any }) => (
                                    <Input type="text" {...input} />
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <label>Start date: </label>
                            <Field
                                name="start_date"
                                render={({input}: { input: any }) => (
                                    <Input
                                        type="text"
                                        {...input}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => input.onChange(e.target.value.trim())}
                                    />
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <label>End date: </label>
                            <Field
                                name="end_date"
                                render={({input}: { input: any }) => (
                                    <Input type="text" {...input}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => input.onChange(e.target.value.trim())}
                                    />
                                )}
                            />
                        </div>
                    </Col>

                    <Col span={8}>
                        <div>
                            <label>Odometer: </label>
                            <Field
                                name="odometr"
                                render={({input}: { input: any }) => (
                                    <Input type="text" {...input} />
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <label>Engine hours : </label>
                            <Field
                                name="engine_hours"
                                render={({input}: { input: any }) => (
                                    <Input type="text" {...input} />
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <label>Note : </label>
                            <Field
                                name="note"
                                render={({input}: { input: any }) => (
                                    <Input type="text" {...input} />
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <label>Vehicle: </label>
                            <Field
                                name="vehicleId"
                                render={({input}: { input: any }) => (
                                    <Select
                                        defaultValue={data?.vehicleId || ''}
                                        showSearch
                                        placeholder="Select a Vehicle"
                                        optionFilterProp="children"
                                        onChange={(value, option) => {
                                            input.onChange(value)
                                        }}
                                        filterOption={(input, option: any) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={vehicleData?.data?.data?.map((option: any) => ({
                                            value: option.id,
                                            label: option.truck_number,
                                        }))}
                                    />
                                )}
                            />
                        </div>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <div>
                            <label>Document: </label>
                            <Field
                                name="document"
                                render={({input}: { input: any }) => (
                                    <Input placeholder="Document" type="text" {...input} />
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <label>Trailer : </label>
                            <Field
                                name="trailer"
                                render={({input}: { input: any }) => (
                                    <Input placeholder="Trailer" type="text" {...input} />
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <label>Sequence ID: </label>
                            <Field
                                name="sequenceId"
                                render={({input}: { input: any }) => (
                                    <Input type="text" {...input} />
                                )}
                            />
                        </div>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <div>
                            <label>Record Status: </label>
                            <Field
                                name="record_status"
                                render={({input}: { input: any }) => (
                                    <InputNumber placeholder='Record Status' {...input} style={{width: '100%'}}/>
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <label>Record сode: </label>
                            <Field
                                name="origin_code"
                                render={({input}: { input: any }) => (
                                    <InputNumber {...input} placeholder='Record Code'
                                                 style={{width: '100%'}}/>
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <label>VIN number: </label>
                            <Field
                                name="vin_number"
                                render={({input}: { input: any }) => (
                                    <Select
                                        defaultValue={data?.vin_number || ''}
                                        showSearch
                                        placeholder="Select a VIN"
                                        optionFilterProp="children"
                                        onChange={(value, option: string | any) => {
                                            input.onChange(option.label);
                                        }}
                                        filterOption={(input, option: any) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={vehicleData?.data?.data?.filter((option: any) => option.vin).map((option: any) => ({
                                            value: option.id,
                                            label: option.vin,
                                            key: option.id
                                        }))}
                                    />
                                )}
                            />
                        </div>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <div>
                            <label>Event code: </label>
                            <Field
                                name="event_code"
                                render={({input}: { input: any }) => (
                                    <InputNumber {...input} placeholder="Event code"
                                                 onChange={(value: any) => {
                                                     input.onChange(value);
                                                     setGetCode(value)
                                                 }}
                                                 style={{width: "100%"}}
                                    />
                                )}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <label>Event type: </label>
                            <Field
                                name="event_type"
                                render={({input}: { input: any }) => (
                                    <InputNumber {...input} placeholder='Event type' onChange={(value: any) => {

                                        input.onChange(value);
                                        setGetEvent(value)
                                    }} style={{width: '100%'}}/>
                                )}
                            />
                        </div>
                    </Col>
                </Row>
                {/*<div>*/}
                {/*    <label>Driver: </label>*/}
                {/*    <Field*/}
                {/*        name="driverId"*/}
                {/*        render={({input}: { input: any }) => (*/}
                {/*            <Input placeholder="Second Name" type="text" {...input} />*/}
                {/*        )}*/}
                {/*    />*/}
                {/*</div>*/}


                <div>
                    <label>Inspection: </label>
                    <Field
                        name="inspection"
                        render={({input}: { input: any }) => (
                            <Switch defaultChecked={input.value} onChange={input.onChange}/>
                        )}
                    />
                </div>

            </Space>
        </div>

    ) : <Spin size="large" spinning={vehicleData?.isLoading}></Spin>
};

export default MainFields;