import {useQuery} from "react-query";
import {contactController} from "../../../API/Tms/contact";



export const useContactData = (id: number |string, companyId: undefined|number|string): any => {
    return useQuery([`admin/contacts${id}`, companyId],
        () => contactController.read(id,companyId),
        {refetchOnWindowFocus: false}
    );
};

export const useContactOneData = (companyId: number | string | undefined): any => {
    return useQuery(
        [`admin/contactsOne/${companyId || "all"}`, companyId],
        () => contactController.contactCompanyOne(companyId),
        {refetchOnWindowFocus: false}
    );
};
export const useFindContact = async (query :any) => {
    return await contactController.companyFinderId(query)
}